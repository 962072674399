<template>
  <v-progress-circular v-if="loading && !items.length" class="ma-4" color="primary" indeterminate />
  <v-card v-else :class="{ 'loading-overlay': loading }" flat>
    <div
      v-for="item in items"
      :key="item.id"
      class="issue-row d-flex justify-space-between align-center clickable text-body-2"
      @click="$emit('click:row', item)"
    >
      <div>
        <div :class="{ completed: item.status === 'closed' }" class="mr-1 text-break">
          {{ item.title }}
          <template v-if="item.external_issues_count > 1">
            ({{ item.external_issues_count }})
          </template>
        </div>
        <div class="d-flex align-center">
          <span class="mr-1 grey--text">
            {{ $timeFromDate(item.created_at) }}
          </span>
          <v-icon
            v-if="(!$isClient() && item.priority === 'important') || item.priority === 'critical'"
            :color="item.priority === 'important' ? 'warning' : 'error'"
            class="mr-1"
            small
            >offline_bolt</v-icon
          >
          <v-tooltip
            v-if="
              item.planned_implementation_at &&
              item.planned_implementation_at < today &&
              !['closed', 'rejected', 'deployed_to_production'].includes(item.status)
            "
            bottom
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="error" class="mr-1" small>error</v-icon>
            </template>
            <span>
              {{ $t('company_management.general.late') }}
              {{ $t('projects.labels.planned_implementation') }}:
              {{ item.planned_implementation_at }}
            </span>
          </v-tooltip>
          <v-icon v-if="item.description" class="mr-1" small>description</v-icon>
          <v-icon v-if="item.attachments && item.attachments.length" class="mr-1" small
            >attach_file</v-icon
          >
          <CommentCount :count="item.comments_count" class="mr-1" />
        </div>
      </div>

      <div class="d-flex">
        <v-tooltip v-if="item.is_resolved" bottom>
          <template #activator="{ on }">
            <v-icon v-on="on" color="success" class="mr-1">check_circle</v-icon>
          </template>
          <div>
            {{ $t('projects.labels.is_resolved') }}
          </div>
        </v-tooltip>

        <div class="mr-1">
          <BaseChipSelect
            :value="item.status"
            :disabled="$isClient() || !canChangeStatus"
            :items="projectIssueStatuses"
            max-height="400"
            @change="$emit('update:status', $event, item)"
          />
        </div>

        <UserAvatar
          v-if="!$isClient()"
          :full-name="getIssueAuthorFullName(item)"
          :tooltip-prefix="`${$t('general.created_by')}: `"
          class="mr-1"
        />

        <UserAvatar
          v-if="!$isClient()"
          :full-name="getIssueAssigneeFullName(item)"
          :tooltip="
            getIssueAssigneeFullName(item)
              ? `${$t('projects.labels.assigned_employee')}: ${getIssueAssigneeFullName(item)}`
              : $t('projects.general.no_assigned_employee')
          "
        />
      </div>
    </div>

    <div v-if="!items.length" class="text-center pa-3">
      {{ $t('general.no_data') }}
    </div>

    <template v-if="pagination">
      <v-pagination
        v-if="
          pagination.current_page < Math.ceil(pagination.total / pagination.per_page) ||
          pagination.current_page > 1
        "
        :value="pagination.current_page"
        :length="Math.ceil(pagination.total / pagination.per_page)"
        class="my-2"
        circle
        @input="$emit('update:page', $event)"
      />
    </template>
  </v-card>
</template>

<script>
import CommentCount from './CommentCount';
import BaseChipSelect from '@/components/base/BaseChipSelect';
import { mapGetters } from 'vuex';
import UserAvatar from '@/components/UserAvatar';
import { format } from 'date-fns';

export default {
  name: 'ProjectIssueList',

  components: {
    UserAvatar,
    BaseChipSelect,
    CommentCount,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canChangeStatus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      today: format(new Date(), 'yyyy-MM-dd'),
    };
  },

  computed: {
    ...mapGetters('projectIssues', ['projectIssueStatuses']),
  },

  methods: {
    getIssueAuthorFullName(issue) {
      return issue.author?.personal_data?.full_name || this.$t('general.system');
    },

    getIssueAssigneeFullName(issue) {
      return issue.assignee?.personal_data?.full_name;
    },
  },
};
</script>

<style scoped>
pre {
  white-space: pre-line;
}

.issue-row {
  padding: 4px 12px;
  min-height: 40px;
}

.theme--light .issue-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.theme--light .issue-row:hover {
  background: #eee;
}

.theme--dark .issue-row:not(:last-child) {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
}
.theme--dark .issue-row:hover {
  background: rgba(255, 255, 255, 0.08);
}
</style>
