<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    :color="$vuetify.theme.isDark ? 'grey darken-4' : 'white'"
    class="border-b"
    app
    flat
  >
    <v-app-bar-nav-icon @click.stop="toggleMenu" />
    <img
      v-if="logoUrl"
      :src="logoUrl"
      alt="logo"
      height="30"
      class="clickable ml-5 mr-3"
      @click="onLogoClick"
    />

    <template v-if="$can(['app.global_search.*'])">
      <GlobalSearchDialog v-if="$vuetify.breakpoint.mdAndDown" @searchToggle="handleSearchToggle" />

      <GlobalSearchMenu v-else @searchToggle="handleSearchToggle" />
    </template>

    <!-- Shortcuts -->
    <template v-if="$can(['app.shortcuts.*'])">
      <template v-if="$vuetify.breakpoint.mdAndUp && !isSearchFieldDisplayed">
        <v-toolbar-items v-if="!iconShortcuts">
          <v-btn
            v-for="shortcut of shortcuts"
            :key="shortcut.id"
            :to="goToShortcut(shortcut.link)"
            text
          >
            <v-icon class="mr-2">
              {{ shortcut.icon }}
            </v-icon>
            {{ shortcut.title }}
          </v-btn>
        </v-toolbar-items>

        <v-toolbar-items v-else>
          <v-tooltip v-for="shortcut of shortcuts" :key="shortcut.id" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :to="goToShortcut(shortcut.link)" text class="mx-0 min-width-unset">
                <v-icon>
                  {{ shortcut.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ shortcut.title }}
            </span>
          </v-tooltip>
        </v-toolbar-items>
      </template>

      <v-toolbar-items v-else-if="!isSearchFieldDisplayed">
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon>
              <v-icon>location_on</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="shortcut of shortcuts"
              :key="shortcut.id"
              :to="goToShortcut(shortcut.link)"
            >
              <v-list-item-action class="list-action-icon">
                <v-icon>
                  {{ shortcut.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>
                {{ shortcut.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </template>

    <v-spacer />

    <v-tooltip
      v-if="$can(['projects.view.*']) && totalProjectIssuesCount > 0 && $vuetify.breakpoint.smAndUp"
      bottom
    >
      <template #activator="{ on }">
        <v-btn v-on="on" icon @click="viewNewIssues">
          <v-badge
            :value="totalProjectIssuesCount > 0"
            :content="issueCountLabel"
            :color="newProjectIssuesCount > 0 ? 'error' : 'grey darken-1'"
            overlap
          >
            <v-icon>bug_report</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <table>
        <tr>
          <td>{{ $t('home_page.issues.new_issues') }}:</td>
          <td class="text-right pl-1">{{ newProjectIssuesCount }}</td>
        </tr>
        <tr>
          <td>{{ $t('projects.labels.open_issues') }}:</td>
          <td class="text-right pl-1">{{ totalProjectIssuesCount }}</td>
        </tr>
      </table>
    </v-tooltip>

    <Notifications />

    <v-toolbar-items>
      <v-menu v-if="currentUser" bottom offset-y offset-x>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" v-if="$vuetify.breakpoint.mdAndUp" text>
            {{ currentUser.username }}
          </v-btn>
          <v-btn v-else v-on="on" icon>
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list :class="{ 'pt-2': $vuetify.breakpoint.mdAndUp }" subheader>
          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-subheader>
              {{ currentUser ? currentUser.username : '' }}
            </v-subheader>
            <v-divider />
          </template>

          <template v-if="$store.state.projects.totalIssues > 0 && $vuetify.breakpoint.xsOnly">
            <v-list-item @click="viewNewIssues">
              <v-list-item-icon>
                <v-icon>bug_report</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ $t('profile.labels.new_issues') }} ({{ issueCountLabel }})
              </v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item @click="viewProfile">
            <v-list-item-icon>
              <v-icon>settings</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('general.my_account') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="impersonatingAs" @click="stopImpersonating">
            <v-list-item-icon>
              <v-icon>stop</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('general.controls.stop_impersonating') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>exit_to_app</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('general.controls.logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import GlobalSearchDialog from '../GlobalSearchDialog';
import GlobalSearchMenu from '../GlobalSearchMenu';
import Notifications from '../Notifications';
import clone from 'just-clone';
import { getAppLogoUrl } from '@/util/company-attributes';

let intervalId;

export default {
  name: 'Toolbar',

  components: {
    Notifications,
    GlobalSearchMenu,
    GlobalSearchDialog,
  },

  data() {
    return {
      isSearchFieldDisplayed: false,
      logoUrl: getAppLogoUrl(),
    };
  },

  created() {
    if (!this.$isClient()) {
      this.fetchTotalProjectIssuesCount();
      intervalId = setInterval(() => {
        this.fetchTotalProjectIssuesCount();
      }, 30000);
    }
  },

  beforeDestroy() {
    clearInterval(intervalId);
  },

  computed: {
    ...mapState('settings', ['iconShortcuts', 'darkTheme', 'sidenavOpen']),
    ...mapState('projectIssues', [
      'newProjectIssuesCount',
      'totalProjectIssuesCount',
      'projectIssueFilterParams',
    ]),
    ...mapState('shortcuts', ['shortcuts']),
    ...mapGetters('auth', ['currentUser', 'impersonatingAs']),

    issueCountLabel() {
      const count =
        this.newProjectIssuesCount > 0 ? this.newProjectIssuesCount : this.totalProjectIssuesCount;
      if (count > 9) {
        return '9+';
      }
      return count.toString();
    },
  },

  methods: {
    ...mapActions('settings', ['updateSetting']),
    ...mapActions('projectIssues', ['fetchTotalProjectIssuesCount']),

    handleSearchToggle(isOpen) {
      this.isSearchFieldDisplayed = isOpen;
    },

    logout() {
      this.$store.dispatch('auth/logout');
    },

    viewProfile() {
      this.$router.push({ name: 'profile' });
    },

    stopImpersonating() {
      localStorage.removeItem('evo_impersonator_id');
      window.location.reload(false);
    },

    goToShortcut(shortcutLink) {
      let link = shortcutLink;

      if (link.indexOf(document.location.origin) > -1) {
        link = link.replace(document.location.origin, '');
      }
      const index = link.indexOf('#');
      if (index > -1) {
        link = link.slice(index + 1, link.length);
      }
      return link;
    },

    toggleMenu() {
      this.updateSetting({
        key: 'sidenavOpen',
        value: !this.sidenavOpen,
      });
    },

    viewNewIssues() {
      const query = clone(this.projectIssueFilterParams);
      if (this.$route.params.projectId) {
        // when going to all issues list from project page, project filter should be reset
        delete query.project_id;
      }
      this.$router.push({
        name: 'projectIssues',
        query,
      });
    },

    onLogoClick() {
      if (this.$route.name === 'dashboard') {
        return;
      }
      this.$router.push('/');
    },
  },
};
</script>
