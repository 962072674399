import { roleAndPermissionsGuard } from '@/router/guards';

export const orderStatisticsRoutes = [
  {
    path: 'order-statistics',
    name: 'orderStatisticsTabs',
    component: () =>
      import(
        /* webpackChunkName: "orderStatisticsTabs" */ '@/views/order-statistics/OrderStatisticsTabs'
      ),
    beforeEnter: roleAndPermissionsGuard,
    meta: {
      menuGroup: 'statistics',
      requiredPermissions: ['projects.view.*', 'projects.create.*'],
      allowedRoles: ['admin', 'employee'],
    },
    children: [
      {
        path: 'projects',
        name: 'projectOrderStatistics',
        component: () =>
          import(
            /* webpackChunkName: "projectOrderStatistics" */ '@/views/order-statistics/ProjectOrderStatistics'
          ),
        beforeEnter: roleAndPermissionsGuard,
        meta: {
          menuGroup: 'statistics',
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          allowedRoles: ['admin', 'employee'],
        },
      },
      {
        path: 'contacts',
        name: 'contactOrderStatistics',
        component: () =>
          import(
            /* webpackChunkName: "contactOrderStatistics" */ '@/views/order-statistics/ContactOrderStatistics'
          ),
        beforeEnter: roleAndPermissionsGuard,
        meta: {
          menuGroup: 'statistics',
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          allowedRoles: ['admin', 'employee'],
        },
      },
      {
        path: 'by-date',
        name: 'orderStatisticsByDate',
        component: () =>
          import(
            /* webpackChunkName: "contactOrderStatistics" */ '@/views/order-statistics/OrderStatisticsByDate'
          ),
        beforeEnter: roleAndPermissionsGuard,
        meta: {
          menuGroup: 'statistics',
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          allowedRoles: ['admin', 'employee'],
        },
      },
    ],
  },
];
