import httpClient from '@/api/http-client';
import clone from 'just-clone';
import { endOfYear, format, startOfYear } from 'date-fns';

const endpoint = 'api/project-user-stories/order-statistics';

const orderStatisticsService = {
  get: (params) => httpClient.get(`${endpoint}`, { params }),

  getStatisticsByDate: (params) => {
    const p = clone(params);
    p.type = 'date';
    if (p.year) {
      p.date_from = format(startOfYear(new Date().setFullYear(p.year)), 'yyyy-MM-dd');
      p.Date_to = format(endOfYear(new Date().setFullYear(p.year)), 'yyyy-MM-dd');
      delete p.year;
    }
    return httpClient.get(`${endpoint}`, { params: p });
  },
};

export default orderStatisticsService;
