import ProjectSearch from '../components/ProjectSearch';
import i18n from '../i18n/i18n-config';

const globalSearchMixin = {
  components: {
    ProjectSearch,
  },

  props: {
    debounceTime: {
      type: Number,
      default: 500,
    },
  },

  data() {
    return {
      query: '',
      debounceTimer: null,
      isLoading: false,
      selectedModel: '',
      models: [{ label: i18n.t('general.projects'), value: 'projects' }],
    };
  },

  created() {
    this.selectedModel = this.models[0].value;
  },

  methods: {
    handleSearchInput(query) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        this.setLoadingStatus(true);
        this.query = query;
      }, this.debounceTime);
    },

    setLoadingStatus(status) {
      this.isLoading = status;
    },
  },
};

export default globalSearchMixin;
