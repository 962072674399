<template>
  <div>
    <v-dialog
      :value="$route.query['dropped-document']"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="400px"
      scrollable
      @click:outside="$router.go(-1)"
    >
      <v-card>
        <v-card-title>
          {{ $t('documents.general.what_document_was_dropped') }}
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item
              v-for="type in documentTypes"
              :key="type.value"
              class="text-center"
              @click="onTypeClick(type.value)"
            >
              <v-list-item-title>
                {{ type.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="$router.go(-1)">
            {{ $t('general.controls.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CreateReceivedInvoice v-if="$route.query['uploading-document'] === 'received_invoice'" />
    <CreateSentInvoice v-else-if="$route.query['uploading-document'] === 'sent_invoice'" />
    <CreateSupplierAgreement
      v-else-if="$route.query['uploading-document'] === 'supplier_agreement'"
    />

    <div v-if="fileDragging" id="drop_zone" @drop="dropHandler" @dragover.prevent />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { getDefaultReceivedInvoiceFormItem } from '@/store/modules/received-invoices-module';
import { getDefaultSentInvoiceFormItem } from '@/store/modules/sent-invoices-module';
import { getDefaultSupplierAgreementFormItem } from '@/store/modules/supplier-agreements-module';
import CreateReceivedInvoice from '@/views/received-invoices/CreateReceivedInvoice';
import CreateSentInvoice from '@/views/sent-invoices/CreateSentInvoice';
import CreateSupplierAgreement from '@/views/supplier-agreements/CreateSupplierAgreement';

export default {
  name: 'DocumentDropzone',

  components: {
    CreateSupplierAgreement,
    CreateSentInvoice,
    CreateReceivedInvoice,
  },

  data() {
    return {
      selectedFile: null,
      allowedFileFormats: '.pdf, .doc, .docx, .png, .jpg, .jpeg, .csv',

      fileDragging: false,
      dragTimer: null, // controls removal of the file drag and drop overlay
    };
  },

  computed: {
    documentTypes() {
      return [
        { text: this.$t('documents.types_accusative.received_invoice'), value: 'received_invoice' },
        { text: this.$t('documents.types_accusative.sent_invoice'), value: 'sent_invoice' },
        {
          text: this.$t('documents.types_accusative.supplier_agreement'),
          value: 'supplier_agreement',
        },
      ];
    },
  },

  created() {
    window.addEventListener(
      'dragover',
      (evt) => {
        if (evt.dataTransfer?.items[0]?.kind !== 'file') {
          this.fileDragging = false;
          return;
        }

        this.fileDragging = true;
        clearTimeout(this.dragTimer);
        this.dragTimer = setTimeout(() => {
          // fixes the issue of app freezing because sometimes the dragover gets randomly emitted when it shouldn't be
          this.fileDragging = false;
        }, 1000);
      },
      false,
    );
  },

  methods: {
    ...mapMutations('receivedInvoices', ['SET_NEW_RECEIVED_INVOICE']),
    ...mapMutations('sentInvoices', ['SET_NEW_SENT_INVOICE']),
    ...mapMutations('supplierAgreements', ['SET_NEW_SUPPLIER_AGREEMENT']),

    onTypeClick(type) {
      if (type === 'received_invoice') {
        this.SET_NEW_RECEIVED_INVOICE({
          ...getDefaultReceivedInvoiceFormItem(),
          file: this.selectedFile,
        });
      } else if (type === 'sent_invoice') {
        this.SET_NEW_SENT_INVOICE({
          ...getDefaultSentInvoiceFormItem(),
          file: this.selectedFile,
        });
      } else if (type === 'supplier_agreement') {
        this.SET_NEW_SUPPLIER_AGREEMENT({
          ...getDefaultSupplierAgreementFormItem(),
          file: this.selectedFile,
        });
      }

      this.openDocumentForm(type);
    },

    openDocumentForm(type) {
      const query = { ...this.$route.query };
      delete query['dropped-document'];
      query['uploading-document'] = type;

      this.$router.push({
        ...this.$route,
        query,
      });
    },

    setFile(event) {
      let file;
      if (event && event.length > 0) {
        [file] = event;
      } else {
        file = event;
      }
      if (file && file.name) {
        const extension =
          file.name.substring(file.name.lastIndexOf('.'), file.name.length) || file.name;
        const index = this.allowedFileFormats.indexOf(extension);
        if (extension && index > -1) {
          setTimeout(() => {
            this.selectedFile = file;
          });
        }
      }
    },

    dropHandler(ev) {
      ev.preventDefault();
      if (ev.dataTransfer.items) {
        if (ev.dataTransfer.items[0].kind === 'file') {
          const file = ev.dataTransfer.items[0].getAsFile();
          this.setFile(file);
        }
      } else {
        this.setFile(ev.dataTransfer.files[0]);
      }
      this.removeDragData(ev);
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          'dropped-document': true,
        },
      });
    },

    removeDragData(ev) {
      if (ev.dataTransfer.items) {
        ev.dataTransfer.items.clear();
      } else {
        ev.dataTransfer.clearData();
      }
      this.fileDragging = false;
    },
  },
};
</script>

<style>
#drop_zone {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
}
</style>
