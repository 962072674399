import Vue from 'vue';
import projectUserStoryService from '@/api/project-user-story-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import clone from 'just-clone';
import projectAttachmentService from '@/api/project-attachment-service';

export const getDefaultProjectUserStoryFormItem = () => ({
  status: 'new',
  sprint_id: null,
  supplier_status: null,
  estimated_pm_points: 1,
});

const state = {
  projectUserStoriesBySprint: {}, // { {sprint_id}: [] }
  selectedProjectUserStory: {},
  newProjectUserStory: getDefaultProjectUserStoryFormItem(),
  projectUserStoryValidationErrors: {},

  enableProjectUserStoryCheckboxes: false,
  selectedProjectUserStoriesMap: {},
  highlightedUserStoryId: null,
};

const getters = {
  projectUserStoryPointTypes() {
    return [
      {
        type: 'pm',
        prefix: 'M',
        estimatedPointsFieldKey: 'estimated_pm_points',
        completedPointsFieldKey: 'completed_pm_points',
        statusFieldKey: 'pm_point_status',
        label: i18n.t('projects.general.project_management'),
        darkThemeColor: '#ffb81a',
        lightThemeColor: '#9b70b0',
      },
    ];
  },

  projectUserStoryStatuses() {
    return ['new', 'client_approved'].map((s) => ({
      value: s,
      text: i18n.t(`projects.user_story_status.${s}`),
    }));
  },

  supplierStatuses() {
    return [
      {
        text: i18n.t('general.supplier_statuses.ordered'),
        value: 'ordered',
      },
      {
        text: i18n.t('general.supplier_statuses.sent'),
        value: 'sent',
      },
      {
        text: i18n.t('general.supplier_statuses.received'),
        value: 'received',
      },
      {
        text: i18n.t('general.supplier_statuses.not_ordered'),
        value: null,
      },
    ];
  },
};

const mutations = {
  SET_PROJECT_USER_STORIES_BY_SPRINT(state, value) {
    state.projectUserStoriesBySprint = value;
  },

  SET_PROJECT_SPRINT_USER_STORIES(state, { sprintId, userStories }) {
    Vue.set(state.projectUserStoriesBySprint, sprintId, userStories);
  },

  SET_EDITED_PROJECT_USER_STORY(state, projectUserStory) {
    state.projectUserStoryValidationErrors = {};
    state.selectedProjectUserStory = clone(projectUserStory);
    state.selectedProjectUserStory.sprint_id = +state.selectedProjectUserStory.sprint_id || null;
  },

  SET_NEW_PROJECT_USER_STORY(state, projectUserStory) {
    state.newProjectUserStory = projectUserStory;
  },

  SET_HIGHLIGHTED_USER_STORY_ID(state, userStoryId) {
    state.highlightedUserStoryId = userStoryId;
  },

  STORE_PROJECT_USER_STORY(state, projectUserStory) {
    if (projectUserStory.sprint_id) {
      state.projectUserStoriesBySprint[projectUserStory.sprint_id].push(projectUserStory);
    } else {
      state.projectUserStoriesBySprint[projectUserStory.sprint_id].unshift(projectUserStory);
    }
    state.projectUserStoryValidationErrors = {};
    state.newProjectUserStory = getDefaultProjectUserStoryFormItem();
  },

  STORE_PROJECT_USER_STORIES(state, projectUserStories) {
    for (let i = 0; i < projectUserStories.length; i++) {
      const story = projectUserStories[i];
      if (story.sprint_id) {
        // different sorting in BE for inserting into sprint vs inserting as unassigned to sprint
        state.projectUserStoriesBySprint[story.sprint_id].push(story);
      } else {
        state.projectUserStoriesBySprint[story.sprint_id].unshift(story);
      }
    }
    state.projectUserStoryValidationErrors = {};
  },

  UPDATE_PROJECT_USER_STORY(state, updatedUserStory) {
    const sprintIds = Object.keys(state.projectUserStoriesBySprint);

    for (let i = 0; i < sprintIds.length; i++) {
      const sprintId = sprintIds[i];
      for (let j = 0; j < state.projectUserStoriesBySprint[sprintId].length; j++) {
        const userStory = state.projectUserStoriesBySprint[sprintId][j];
        if (userStory.id === updatedUserStory.id) {
          if (+userStory.sprint_id !== +updatedUserStory.sprint_id) {
            state.projectUserStoriesBySprint[sprintId].splice(j, 1);
            state.projectUserStoriesBySprint[updatedUserStory.sprint_id].push(updatedUserStory);
          } else {
            state.projectUserStoriesBySprint[sprintId].splice(j, 1, updatedUserStory);
          }
          return;
        }
      }
    }

    if (updatedUserStory.id === state.selectedProjectUserStory.id) {
      state.selectedProjectUserStory = updatedUserStory;
    }
  },

  UPDATE_PROJECT_USER_STORY_TASKS_COUNT(state, { sprintId, userStoryId }) {
    for (let i = 0; i < state.projectUserStoriesBySprint[sprintId].length; i++) {
      const userStory = state.projectUserStoriesBySprint[sprintId][i];
      if (userStory.id === userStoryId) {
        userStory.tasks_count += 1;
      }
    }
  },

  UPDATE_PROJECT_USER_STORY_COMPLETED_TASKS(state, { sprintId, userStoryId, newStatus }) {
    for (let i = 0; i < state.projectUserStoriesBySprint[sprintId].length; i++) {
      const userStory = state.projectUserStoriesBySprint[sprintId][i];
      if (userStory.id === userStoryId) {
        if (newStatus === 'ready_for_test') {
          userStory.completed_tasks_count += 1;
        }
        if (newStatus === 'new') {
          userStory.completed_tasks_count -= 1;
        }
      }
    }
  },

  DELETE_PROJECT_USER_STORY(state, projectUserStory) {
    state.projectUserStoriesBySprint[projectUserStory.sprint_id] = removeArrayItem(
      state.projectUserStoriesBySprint[projectUserStory.sprint_id],
      projectUserStory,
    );
  },

  SET_PROJECT_USER_STORY_VALIDATION_ERRORS(state, projectUserStoryValidationErrors) {
    state.projectUserStoryValidationErrors = projectUserStoryValidationErrors;
  },

  CLEAR_PROJECT_USER_STORY_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.projectUserStoryValidationErrors, field);
  },

  SET_ENABLE_PROJECT_USER_STORY_CHECKBOXES(state, value) {
    state.enableProjectUserStoryCheckboxes = value;
    state.selectedProjectUserStoriesMap = {};
  },

  TOGGLE_SELECTED_PROJECT_USER_STORY(state, userStoryId) {
    if (state.selectedProjectUserStoriesMap[userStoryId]) {
      Vue.delete(state.selectedProjectUserStoriesMap, userStoryId);
    } else {
      Vue.set(state.selectedProjectUserStoriesMap, userStoryId, true);
    }
  },
};

const actions = {
  storeProjectUserStory({ commit }, projectUserStory) {
    return projectUserStoryService
      .create(projectUserStory)
      .then((res) => {
        commit('STORE_PROJECT_USER_STORY', res.data);
        openSnackbar(i18n.t('projects.user_story_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  storeProjectUserStoriesBulk({ commit }, payload) {
    return projectUserStoryService
      .createBulk(payload)
      .then((res) => {
        commit('STORE_PROJECT_USER_STORIES', res.data);
        openSnackbar(i18n.t('projects.user_stories_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editProjectUserStory({ state, commit }, projectUserStoryId) {
    const findUserStory = () => {
      const sprintIds = Object.keys(state.projectUserStoriesBySprint);
      for (let i = 0; i < sprintIds.length; i++) {
        const userStories = state.projectUserStoriesBySprint[sprintIds[i]];
        for (let j = 0; j < userStories.length; j++) {
          if (userStories[j].id === projectUserStoryId) {
            return userStories[j];
          }
        }
      }
      return null;
    };
    const projectUserStory = findUserStory();

    if (projectUserStory) {
      commit('SET_EDITED_PROJECT_USER_STORY', projectUserStory);
      return Promise.resolve(projectUserStory);
    }
    return projectUserStoryService.getById(projectUserStoryId).then((res) => {
      commit('SET_EDITED_PROJECT_USER_STORY', res.data);
      return res.data;
    });
  },

  updateProjectUserStory({ commit }, projectUserStory) {
    return projectUserStoryService
      .update(projectUserStory)
      .then((res) => {
        commit('UPDATE_PROJECT_USER_STORY', {
          ...projectUserStory,
          ...res.data,
        });
        openSnackbar(i18n.t('projects.user_story_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  updateProjectUserStoryDescription({ commit }, { projectUserStory, description }) {
    return projectUserStoryService
      .updateDescription(projectUserStory.id, description)
      .then((res) => {
        commit('UPDATE_PROJECT_USER_STORY', {
          ...projectUserStory,
          ...res.data,
        });
        openSnackbar(i18n.t('projects.user_story_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async moveProjectUserStory({ state, commit, dispatch }, event) {
    const projectUserStoriesBySprintBackup = clone(state.projectUserStoriesBySprint);

    try {
      const projectUserStoriesBySprint = clone(state.projectUserStoriesBySprint);
      const movedUserStoryId = +event.item.attributes['data-user-story-id'].value;
      const oldSprintId = +event.item.attributes['data-sprint-id']?.value || null;
      const newSprintId = +event.to.attributes['data-sprint-id']?.value || null;
      const { oldIndex, newIndex } = event;

      if (oldSprintId === newSprintId && oldIndex === newIndex) {
        return;
      }

      const movedUserStory = projectUserStoriesBySprint[oldSprintId].find(
        (s) => s.id === movedUserStoryId,
      );
      if (!movedUserStory) {
        throw new Error('User story not found');
      }

      movedUserStory.sprint_id = newSprintId;
      const insertAfter = projectUserStoriesBySprint[newSprintId][newIndex - 1]?.id || null;
      if (!newSprintId && oldSprintId) {
        projectUserStoryService.removeFromSprint(movedUserStory, insertAfter);
      } else if (newSprintId && !oldSprintId) {
        projectUserStoryService.addToSprint(movedUserStory, insertAfter, newSprintId);
      } else if (newSprintId !== oldSprintId) {
        projectUserStoryService.addToSprint(movedUserStory, insertAfter, newSprintId);
      }

      projectUserStoriesBySprint[oldSprintId].splice(oldIndex, 1);
      projectUserStoriesBySprint[newSprintId].splice(newIndex, 0, movedUserStory);
      commit('SET_PROJECT_USER_STORIES_BY_SPRINT', projectUserStoriesBySprint);

      await dispatch('saveUserStoriesOrder', newSprintId);
    } catch (e) {
      commit('SET_PROJECT_USER_STORIES_BY_SPRINT', projectUserStoriesBySprintBackup);
      openSnackbar(i18n.t('projects.failed_to_move_user_story'));
      throw e;
    }
  },

  async saveUserStoriesOrder({ state }, sprintId) {
    await projectUserStoryService.reorder(state.projectUserStoriesBySprint[sprintId]);
  },

  async moveSelectedProjectUserStories({ state, commit, dispatch }, { projectId, sprintId }) {
    let response;
    try {
      response = await projectUserStoryService.move(
        Object.keys(state.selectedProjectUserStoriesMap),
        projectId,
        sprintId,
      );
    } catch (e) {
      commit('SET_PROJECT_USER_STORY_VALIDATION_ERRORS', mapErrorsToInputs(e));
      throw e;
    }

    const movedUserStories = response.data;
    const movedUserStoriesIdsSet = new Set(movedUserStories.map((s) => s.id));
    const projectUserStoriesBySprint = clone(state.projectUserStoriesBySprint);
    const sprintIds = Object.keys(projectUserStoriesBySprint);

    for (let i = 0; i < sprintIds.length; i++) {
      const userStories = projectUserStoriesBySprint[sprintIds[i]];
      for (let j = 0; j < userStories.length; j++) {
        if (movedUserStoriesIdsSet.has(userStories[j].id)) {
          userStories.splice(j, 1);
          j -= 1;
        }
      }
    }

    projectUserStoriesBySprint[sprintId] = [
      ...(projectUserStoriesBySprint[sprintId] || []),
      ...movedUserStories,
    ];

    commit('SET_PROJECT_USER_STORIES_BY_SPRINT', projectUserStoriesBySprint);
    commit('SET_ENABLE_PROJECT_USER_STORY_CHECKBOXES', false);
    openSnackbar(i18n.t('projects.general.stories_moved'));
    dispatch('saveUserStoriesOrder', sprintId);

    return response.data;
  },

  async splitProjectSprintUserStories({ commit }, { oldSprintId, newSprintId }) {
    const { data } = await projectUserStoryService.split(oldSprintId, newSprintId);
    commit('SET_PROJECT_SPRINT_USER_STORIES', {
      sprintId: oldSprintId,
      userStories: data.old_user_stories,
    });
    commit('SET_PROJECT_SPRINT_USER_STORIES', {
      sprintId: newSprintId,
      userStories: data.new_user_stories,
    });
  },

  async updateProjectUserStoryPointsStatus({ commit }, { projectUserStory, pointsType }) {
    const statusMap = {
      in_progress: 'ready_for_test',
      ready_for_test: 'closed',
      closed: null,
    };

    const currentStatus = projectUserStory[pointsType];
    // if current status is null, user story is new
    const newStatus = currentStatus ? statusMap[currentStatus] : 'in_progress';

    const { data } = await projectUserStoryService.updatePointsStatus(
      projectUserStory,
      pointsType,
      newStatus,
    );
    commit('UPDATE_PROJECT_USER_STORY', data);
  },

  async uploadProjectUserStoryAttachments({ commit }, { projectUserStory, attachments }) {
    try {
      const { data } = await projectAttachmentService.uploadProjectUserStoryAttachments(
        attachments,
        projectUserStory,
      );

      const updatedUserStory = clone(projectUserStory);
      updatedUserStory.attachments = [...updatedUserStory.attachments, ...data];
      commit('UPDATE_PROJECT_USER_STORY', updatedUserStory);
    } catch (e) {
      console.log(e);
      openSnackbar(i18n.t('file_upload_failed'));
    }
  },

  async deleteProjectUserStory({ commit }, projectUserStory) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    });
    if (!confirmed) {
      return false;
    }

    await projectUserStoryService.delete(projectUserStory);
    commit('DELETE_PROJECT_USER_STORY', projectUserStory);
    openSnackbar(i18n.t('projects.user_story_deleted'));
    return true;
  },

  async deleteProjectUserStoryAttachment({ commit }, { projectUserStory, attachment }) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    });
    if (!confirmed) {
      return;
    }

    await projectAttachmentService.delete(attachment);
    const updatedUserStory = clone(projectUserStory);
    updatedUserStory.attachments = updatedUserStory.attachments.filter(
      (a) => a.id !== attachment.id,
    );
    commit('UPDATE_PROJECT_USER_STORY', updatedUserStory);
    openSnackbar(i18n.t('general.file_deleted'));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
