<template>
  <v-form @submit.prevent>
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col v-if="!item.id" cols="12">
            <v-file-input
              v-model="item.file"
              :error-messages="errors['file']"
              :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
              :accept="receivedInvoiceFileFormats"
              @change="formMixin_clearErrors('file')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="item.document_date"
              :error-messages="errors['document_date']"
              :label="$t('documents.labels.document_date')"
              @input="formMixin_clearErrors('document_date')"
            />
          </v-col>

          <v-col v-if="$isClient()" cols="12" sm="6">
            <v-select
              v-model="item.supplier_id"
              :error-messages="errors['supplier_id']"
              :label="formMixin_getRequiredFieldLabel($t('general.contact'))"
              :items="currentUserClients"
              item-text="name"
              item-value="id"
              @input="formMixin_clearErrors('supplier_id')"
            >
              <template v-slot:item="{ item }">
                <span v-if="item.name">
                  {{ item.name }}
                </span>
                <span v-else>
                  {{ item.full_name }}
                </span>
              </template>
              <template v-slot:selection="{ item }">
                <span v-if="item.name">
                  {{ item.name }}
                </span>
                <span v-else>
                  {{ item.full_name }}
                </span>
              </template>
            </v-select>
          </v-col>

          <v-col v-else cols="12" sm="6">
            <ContactAutocomplete
              v-model="item.supplier_id"
              :error-messages="errors['supplier_id']"
              :initial-item="item.supplier"
              :label="formMixin_getRequiredFieldLabel($t('general.contact'))"
              suppliers-only
              show-create-button
              @input="formMixin_clearErrors('supplier_id')"
              @update:initial-item="item.supplier = $event"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="item.sub_total"
              :error-messages="errors.sub_total"
              :label="formMixin_getRequiredFieldLabel($t('general.sum'))"
              @input="formMixin_clearErrors('sub_total')"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="item.vat"
              :error-messages="errors.vat"
              :label="formMixin_getRequiredFieldLabel($t('general.vat'))"
              @input="formMixin_clearErrors('vat')"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-checkbox
              v-model="item.is_paid"
              :error-messages="errors.is_paid"
              :label="$t('general.is_paid')"
              @change="formMixin_clearErrors('is_paid')"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="item.description"
              :error-messages="errors.description"
              :label="$t('general.description')"
              rows="2"
              auto-grow
              @input="formMixin_clearErrors('description')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!item.id" class="text-subtitle-2 ml-3">
          * {{ $t('general.must_be_filled') }}
        </span>

        <v-spacer />

        <v-btn text @click="$emit('cancel')">
          {{ $t('general.controls.cancel') }}
        </v-btn>

        <v-btn
          :disabled="disabled"
          :loading="disabled"
          color="primary"
          text
          @click="$emit('save', item)"
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import { mapGetters } from 'vuex';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete';

export default {
  name: 'ReceivedInvoiceForm',

  components: {
    ContactAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    receivedInvoice: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    ...mapGetters('receivedInvoices', ['receivedInvoiceFileFormats']),
    ...mapGetters('auth', ['currentUserClients']),

    formTitle() {
      return this.$t(
        `documents.modal_titles.${this.receivedInvoice.id ? 'edit' : 'new'}_received_invoice`,
      );
    },
  },

  created() {
    const item = clone(this.receivedInvoice);
    if (item.id) {
      item.description = item.model.description;
      item.supplier_id = item.model.supplier_id;
      item.supplier = item.model.supplier;
      item.vat = item.model.vat;
      item.sub_total = item.model.sub_total;
      item.date = item.model.date;
      item.is_paid = item.model.is_paid;
    }
    this.item = item;
  },
};
</script>
