<template>
  <v-form @submit.prevent>
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col v-if="!item.id" cols="12">
            <v-file-input
              v-model="item.file"
              :error-messages="errors['file']"
              :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
              :accept="supplierAgreementFileFormats"
              @change="formMixin_clearErrors('file')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="item.document_date"
              :error-messages="errors['document_date']"
              :label="$t('documents.labels.document_date')"
              @input="formMixin_clearErrors('document_date')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <ContactAutocomplete
              v-model="item.supplier_id"
              :error-messages="errors['supplier_id']"
              :initial-item="item.supplier"
              :label="formMixin_getRequiredFieldLabel($t('general.contact'))"
              suppliers-only
              show-create-button
              @input="formMixin_clearErrors('supplier_id')"
              @update:initial-item="item.supplier = $event"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="item.valid_from"
              :error-messages="errors.valid_from"
              :label="$t('documents.labels.valid_from')"
              @input="formMixin_clearErrors('valid_from')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="item.valid_until"
              :error-messages="errors.valid_until"
              :label="$t('general.date_and_time.valid_until')"
              @input="formMixin_clearErrors('valid_until')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="item.document_description"
              :error-messages="errors.document_description"
              :label="$t('documents.labels.document_type')"
              clearable
              @input="formMixin_clearErrors('document_description')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="item.status"
              :error-messages="errors.status"
              :label="$t('general.status')"
              :items="statuses"
              clearable
              @input="formMixin_clearErrors('status')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <UserAutocomplete
              v-model="item.pending_action_user_id"
              :error-messages="errors.pending_action_user_id"
              :label="$t('general.pending_action_user')"
              @input="formMixin_clearErrors('pending_action_user_id')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="item.is_signatures_needed"
              :error-messages="errors.is_signatures_needed"
              :label="$t('documents.labels.signature_needed')"
              @change="formMixin_clearErrors('is_signatures_needed')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-checkbox
              v-if="item.is_signatures_needed"
              v-model="item.is_signed_by_all_signees"
              :error-messages="errors.is_signed_by_all_signees"
              :label="$t('documents.labels.signed')"
              @change="formMixin_clearErrors('is_signed_by_all_signees')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!item.id" class="text-subtitle-2 ml-3">
          * {{ $t('general.must_be_filled') }}
        </span>

        <v-spacer />

        <v-btn text @click="$emit('cancel')">
          {{ $t('general.controls.cancel') }}
        </v-btn>

        <v-btn
          v-if="!$isClient()"
          :disabled="disabled"
          :loading="disabled"
          color="primary"
          text
          @click="$emit('save', item)"
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import clone from 'just-clone';
import { mapGetters } from 'vuex';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete';
import UserAutocomplete from '@/components/autocompletes/UserAutocomplete';

export default {
  name: 'SupplierAgreementForm',

  components: {
    UserAutocomplete,
    ContactAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    supplierAgreement: {
      type: Object,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      item: {},
    };
  },

  computed: {
    ...mapGetters('supplierAgreements', ['supplierAgreementFileFormats']),

    formTitle() {
      return this.$t(
        `documents.modal_titles.${this.supplierAgreement.id ? 'edit' : 'new'}_document`,
      );
    },
  },

  created() {
    const item = clone(this.supplierAgreement);
    if (item.id) {
      item.supplier_id = item.model.supplier_id;
      item.supplier = item.model.supplier;
      item.valid_from = item.model.valid_from;
      item.valid_until = item.model.valid_until;
      item.document_description = item.model.document_description;
      item.status = item.model.status;
      item.pending_action_user_id = item.model.pending_action_user_id;
      item.pending_action_user = item.model.pending_action_user;
    }
    this.item = item;
  },
};
</script>
