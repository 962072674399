import { permissionsGuard } from '@/router/guards';

export const userRoutes = [
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../../views/users/Users'),
    beforeEnter: permissionsGuard,
    meta: { requiredPermissions: ['users.*'] },
    children: [
      {
        path: 'create',
        name: 'createUser',
        component: () =>
          import(/* webpackChunkName: "createUser" */ '../../views/users/CreateUser'),
        beforeEnter: permissionsGuard,
        meta: { requiredPermissions: ['users.*'] },
      },
      {
        path: ':id/edit',
        name: 'editUser',
        component: () => import(/* webpackChunkName: "editUser" */ '../../views/users/EditUser'),
        beforeEnter: permissionsGuard,
        meta: { requiredPermissions: ['users.*'] },
      },
      {
        path: ':id/edit-permissions',
        name: 'editUserPermissions',
        component: () =>
          import(
            /* webpackChunkName: "editUserPermissions" */ '../../views/users/EditUserPermissions'
          ),
        beforeEnter: permissionsGuard,
        meta: { requiredPermissions: ['users.*'] },
      },
    ],
  },
];
