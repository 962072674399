import i18n from '@/i18n/i18n-config';
import httpClient from '@/api/http-client';
import clone from 'just-clone';
import { openSnackbar } from '@/util/event-bus';

const endpoint = 'api/project-issues';

const projectIssueService = {
  getPage: (params) => {
    const p = clone(params);
    if (!p.status?.length) {
      p.status = [
        'new',
        'seen',
        'in_progress',
        'ready_for_test',
        'deployed_to_staging',
        'deployed_to_production',
        'postponed',
      ];
    }
    return httpClient.get(endpoint, { params: p });
  },

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  getTotalIssuesCount: () => httpClient.get('api/total-issues'),

  create: (projectIssue) => httpClient.post(endpoint, projectIssue),

  conclude: (projectIssue) =>
    httpClient.put(`api/issue-conclusion/${projectIssue.id}`, projectIssue),

  update: (projectIssue) => httpClient.put(`${endpoint}/${projectIssue.id}`, projectIssue),

  updateStatus: (projectIssue) =>
    httpClient.put(`${endpoint}/status/${projectIssue.id}`, { status: projectIssue.status }),

  convertToUserStory: (projectIssue, payload) =>
    httpClient.put(`api/convert-issue-to-user-story/${projectIssue.id}`, payload),

  uploadAttachments: (projectIssue, files) => {
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append('file[]', files[i]);
    }
    return httpClient
      .post(`api/project-issues-image/${projectIssue.id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .catch(() => {
        openSnackbar(i18n.t('general.file_upload_failed'));
      });
  },

  deleteAttachment: (projectIssue, attachment) =>
    httpClient.delete(`api/project-issues-image/${projectIssue.id}`, {
      data: { attachment_ids: [attachment.id] },
    }),

  delete: (projectIssue) => httpClient.delete(`${endpoint}/${projectIssue.id}`, projectIssue),
};

export default projectIssueService;
