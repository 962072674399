<template>
  <span v-if="total > 0" class="text-no-wrap">
    <v-icon class="task-count-icon" small>check_box</v-icon>
    <span class="text--secondary text-body-2">{{ completed }}/{{ total }}</span>
  </span>
</template>

<script>
export default {
  name: 'TaskCount',

  props: {
    total: {
      type: Number,
      default: 0,
    },

    completed: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.task-count-icon {
  padding-right: 2px;
}
</style>
