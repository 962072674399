import projectUserStoryService from '@/api/project-user-story-service';

export const getDefaultProjectPointStatisticsFilterParams = () => ({});

const state = {
  projectPointStatistics: [],
  projectPointStatisticsFilterParams: getDefaultProjectPointStatisticsFilterParams(),
};

const getters = {};

const mutations = {
  SET_PROJECT_POINT_STATISTICS(state, data) {
    state.projectPointStatistics = data;
  },

  SET_PROJECT_POINT_STATISTICS_FILTER_PARAMS(state, params) {
    state.projectPointStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchProjectPointStatistics({ commit, rootState }, params) {
    commit('SET_PROJECT_POINT_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectUserStoryService.getPointStatistics({
      ...params,
      type: 'completed_by_project',
      project_id: rootState.projects.selectedProject.id,
    });
    commit('SET_PROJECT_POINT_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
