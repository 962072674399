<template>
  <div>
    <BaseAutocomplete
      :value="value"
      :search-function="searchInvoices"
      :get-by-id-function="getInvoiceById"
      :label="label"
      :initial-item="initialItem"
      :error-messages="errorMessages"
      :append-icon="showCreateButton && $can(['invoices.create']) ? 'add' : ''"
      item-text="number"
      clearable
      @input="$emit('input', $event)"
      @update:initial-item="$emit('update:initial-item', $event)"
      @click:append="createNewInvoice"
    />

    <BaseNestableDialog
      v-if="showCreateButton && $route.query[queryParam]"
      max-width="800"
      scrollable
      persistent
    >
      <InvoiceForm
        :invoice="newInvoice"
        :errors="invoiceValidationErrors"
        :disabled="$store.getters.loading['post:api/finances/invoices']"
        @clear:errors="CLEAR_INVOICE_VALIDATION_ERRORS"
        @cancel="closeInvoiceDialog"
        @save="saveNewInvoice"
      />
    </BaseNestableDialog>
  </div>
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import invoiceService from '@/api/invoice-service';
import i18n from '@/i18n/i18n-config';
import { getDefaultInvoiceFormItem } from '@/store/modules/invoices-module';
import { mapActions, mapMutations, mapState } from 'vuex';
import BaseNestableDialog from '@/components/base/BaseNestableDialog';
import InvoiceForm from '@/components/forms/InvoiceForm';

export default {
  name: 'InvoiceAutocomplete',

  components: { InvoiceForm, BaseNestableDialog, BaseAutocomplete },

  props: {
    value: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    initialItem: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: () => i18n.t('general.invoice'),
    },
    showCreateButton: {
      // don't forget to update initialItem by listening to @update:initial-item
      // if this prop is set to true
      // otherwise item displayed inside autocomplete field won't update
      type: Boolean,
      default: false,
    },
    queryParam: {
      // if multiple instances of this component are used in the same page,
      // with showCreateButton set to true,
      // specify different value for this prop,
      // otherwise multiple forms will be opened
      type: String,
      default: 'create-invoice',
    },
  },

  computed: {
    ...mapState('invoices', ['newInvoice', 'invoiceValidationErrors']),
  },

  methods: {
    ...mapActions('invoices', ['storeInvoice']),
    ...mapMutations('invoices', [
      'SET_NEW_INVOICE',
      'SET_INVOICE_VALIDATION_ERRORS',
      'CLEAR_INVOICE_VALIDATION_ERRORS',
    ]),

    getInvoiceById: invoiceService.getById,
    searchInvoices: invoiceService.search,

    closeInvoiceDialog() {
      const newQuery = {
        ...this.$route.query,
      };
      delete newQuery[this.queryParam];

      this.$router.push({
        ...this.$route,
        query: newQuery,
      });
    },

    createNewInvoice() {
      this.SET_INVOICE_VALIDATION_ERRORS({});
      this.SET_NEW_INVOICE(getDefaultInvoiceFormItem());
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          [this.queryParam]: true,
        },
      });
    },

    saveNewInvoice(invoice) {
      this.storeInvoice(invoice).then((createdInvoice) => {
        this.$emit('update:initial-item', createdInvoice);
        this.$emit('input', createdInvoice.id);
        this.closeInvoiceDialog();
      });
    },
  },
};
</script>

<style scoped></style>
