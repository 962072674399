import clone from 'just-clone';

export function removeEmptyFilters(params) {
  const updatedParams = {};
  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'boolean' || params[key]) {
      updatedParams[key] = params[key];
    }
  });
  return updatedParams;
}

export function getSanitizedFilterParams(params) {
  const p = clone(params);

  // id fields that don't end with '_id'
  const nonStandardIdFields = ['assigned_to'];

  Object.keys(params).forEach((key) => {
    if (key.includes('_id') || nonStandardIdFields.includes(key)) {
      p[key] = +p[key];
    }

    if (p[key] === 'false') {
      p[key] = false;
    }

    if (p[key] === 'true') {
      p[key] = true;
    }

    if (
      key.includes('filter-open') ||
      key.includes('dropped-document') ||
      key.includes('uploading-document') ||
      key.includes('create-') // for in modal modals, like creating an invoice contact while creating a received invoice
    ) {
      delete p[key];
    }
  });

  if (+p.page === 1) {
    delete p.page;
  }

  return p;
}
