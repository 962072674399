/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue';
import 'simplebar/dist/simplebar.min.css';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n/i18n-config';
import round from './util/numbers';
import { formatDistanceToNow, subSeconds } from 'date-fns';
import { lt } from 'date-fns/locale';

Vue.config.productionTip = false;

Vue.prototype.$isAdmin = () => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].role === 'admin';
  }
  return false;
};

Vue.prototype.$isEmployee = () => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].role === 'employee';
  }
  return false;
};

Vue.prototype.$isClient = () => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].role === 'client';
  }
  return false;
};

Vue.prototype.$isCurrentUser = (id) => {
  if (store.getters['auth/currentUser']) {
    return store.getters['auth/currentUser'].id === id;
  }
  return false;
};

Vue.prototype.$frontendUrl = (path) => {
  return `${process.env.VUE_APP_PUBLIC_PATH}${path}`;
};

Vue.prototype.$can = (permissions) => {
  if (!Vue.prototype.$isAdmin()) {
    let isAllowed = false;
    if (permissions && store.getters['auth/permissions']) {
      for (let i = 0; i < permissions.length; i++) {
        if (store.getters['auth/permissions'][permissions[i]]) {
          isAllowed = true;
          break;
        }
      }
    }
    return isAllowed;
  }
  return true;
};

Vue.prototype.$timeFromDate = (date) => {
  if (!date) {
    return '';
  }
  return formatDistanceToNow(subSeconds(new Date(date), 10), { addSuffix: true, locale: lt });
};

Vue.filter('currency', (value) => {
  let str = '-';
  if (+value >= 0) {
    str = `€${round(+value, 2).toFixed(2)}`;
  }
  return str;
});

Vue.filter('percentage', (value) => (+value >= 0 ? `${round(+value, 2).toFixed(2)}%` : '-'));

Vue.filter('truncate', (value, length) =>
  value && value.length > length ? `${value.slice(0, length)}...` : value,
);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
