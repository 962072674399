import httpClient from '@/api/http-client';

const endpoint = 'api/comments';

const commentService = {
  fetch: (params) => httpClient.get(endpoint, { params }),

  create: (comment) => httpClient.post(endpoint, comment),

  update: (comment) => httpClient.put(`${endpoint}/${comment.id}`, comment),

  uploadAttachments: (comment, files) => {
    const requestBody = new FormData();
    requestBody.append('comment_id', comment.id);
    for (let i = 0; i < files.length; i++) {
      requestBody.append('files[]', files[i]);
    }
    return httpClient.post('api/comment-attachments', requestBody);
  },

  delete: (comment) => httpClient.delete(`${endpoint}/${comment.id}`),

  deleteAttachment: (attachment) => httpClient.delete(`api/comment-attachments/${attachment.id}`),
};

export default commentService;
