import httpClient from '@/api/http-client';

const endpoint = 'api/project-attachment';

const projectAttachmentService = {
  uploadProjectUserStoryAttachments: (attachments, projectUserStory) => {
    const payload = new FormData();
    payload.append('project_user_story_id', projectUserStory.id);
    payload.append('project_id', projectUserStory.project_id);
    for (let i = 0; i < attachments.length; i++) {
      payload.append('file[]', attachments[i], attachments[i].name);
    }
    return httpClient.post(endpoint, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  delete: (attachment) => httpClient.delete(`${endpoint}/${attachment.id}`),
};

export default projectAttachmentService;
