/* eslint-disable import/prefer-default-export */
export function findById(id, array) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].id === id) {
      return array[i];
    }
  }
  return null;
}

export function updateArrayItem(array, updatedItem, key = 'id') {
  return array.map((item) => (item[key] === updatedItem[key] ? { ...item, ...updatedItem } : item));
}

export function removeArrayItem(array, deletedItem, key = 'id') {
  return array.filter((item) => item[key] !== deletedItem[key]);
}

export function isArray(item) {
  return typeof item === 'object' && item.length >= 0;
}
