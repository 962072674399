<template>
  <div>
    <draggable
      :value="userStories"
      :disabled="!canReorder"
      :delay="500"
      :delay-on-touch-only="true"
      :data-sprint-id="sprintId"
      :animation="150"
      :scroll-sensitivity="100"
      :scroll-speed="20"
      group="userStories"
      style="height: 100%; z-index: 30"
      @end="moveProjectUserStory($event)"
    >
      <div
        v-for="(item, index) in userStories"
        :key="item.id"
        :data-user-story-id="item.id"
        :data-sprint-id="sprintId"
        :class="{
          'highlight-background': highlightedUserStoryId === item.id,
        }"
        class="story-row clickable flex-wrap flex-sm-nowrap"
        @click="onUserStoryClick(item)"
      >
        <v-icon
          v-if="enableProjectUserStoryCheckboxes"
          :color="selectedProjectUserStoriesMap[item.id] ? 'primary' : ''"
          class="mr-2"
          @click.stop="TOGGLE_SELECTED_PROJECT_USER_STORY(item.id)"
          >{{
            selectedProjectUserStoriesMap[item.id] ? 'check_box' : 'check_box_outline_blank'
          }}</v-icon
        >
        <span :class="{ completed: item.status === 'done' }" class="text-body-2 mr-1">
          <template v-if="!enableProjectUserStoryCheckboxes">{{ index + 1 }}. </template
          >{{ item.title }}
        </span>

        <div v-if="$vuetify.breakpoint.xsOnly" class="break" />

        <v-tooltip
          v-if="item.is_urgent && currentUserPermissionInSelectedProject !== 'client'"
          bottom
        >
          <template #activator="{ on }">
            <v-icon
              v-on="on"
              :color="isUserStoryOverdue(item) ? 'error' : 'warning'"
              class="mr-1"
              small
              >offline_bolt</v-icon
            >
          </template>
          <div>
            {{ $t(`projects.labels.is_${isUserStoryOverdue(item) ? 'overdue' : 'urgent'}`) }}!
            <template v-if="item.due_date">
              {{ $t('projects.labels.deadline') }}: {{ item.due_date }}
            </template>
          </div>
        </v-tooltip>

        <v-icon v-if="item.info" class="mt-1 mt-sm-0 mr-1" small> description </v-icon>

        <v-icon v-if="item.attachments && item.attachments.length" class="mt-1 mt-sm-0 mr-1" small>
          attach_file
        </v-icon>

        <CommentCount :count="item.comments_count" class="mr-1" />

        <TaskCount
          v-if="currentUserPermissionInSelectedProject !== 'client'"
          :total="item.tasks_count"
          :completed="item.completed_tasks_count"
          class="mr-1"
        />

        <!-- Client approval indicator -->
        <v-tooltip v-if="!item.sprint_id && item.status === 'client_approved'" bottom>
          <template #activator="{ on }">
            <v-icon v-on="on" color="success" class="mr-1 mt-1 mt-sm-0" small @click.stop
              >check_circle</v-icon
            >
          </template>
          <div>
            {{ $t('projects.user_story_status.client_approved') }}
          </div>
        </v-tooltip>

        <v-spacer />

        <div
          v-if="
            currentUserPermissionInSelectedProject === 'client' ||
            ($isClient() && currentUserPermissionInSelectedProject !== 'supplier')
          "
          class="mt-1 mt-sm-0"
        >
          <v-chip
            :color="userStoryStatusColors[item.status]"
            class="font-weight-medium black--text full-opacity"
            disabled
            small
          >
            {{
              $t(
                `projects.user_story_status.${
                  item.status === 'client_approved' ? 'new' : item.status
                }`,
              )
            }}
          </v-chip>
        </div>

        <ProjectUserStoryPoints
          v-else
          :user-story="item"
          :can-change-statuses="canChangePointStatuses"
          :sprint-id="sprintId"
          class="text-right ml-auto mt-1 mt-sm-0"
        />
      </div>
    </draggable>

    <div v-if="!userStories.length" class="text-center pa-3">
      <span v-if="sprintId">{{ $t('projects.general.no_assigned_stories') }}.</span>
      <span v-else>{{ $t('projects.general.no_unassigned_stories') }}.</span>
    </div>
  </div>
</template>

<script>
import CommentCount from '@/components/CommentCount';
import TaskCount from '@/components/TaskCount';
import ProjectUserStoryPoints from '@/components/ProjectUserStoryPoints';
import draggable from 'vuedraggable';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ProjectUserStoriesList',

  components: { ProjectUserStoryPoints, TaskCount, CommentCount, draggable },

  props: {
    userStories: {
      type: Array,
      default: () => [],
    },
    sprintId: {
      type: Number,
      default: null,
    },
    canReorder: {
      type: Boolean,
      default: false,
    },
    canChangePointStatuses: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userStoryStatusColors: {
        new: 'grey lighten-4',
        client_approved: 'grey lighten-4',
        in_progress: 'blue lighten-4',
        done: 'green lighten-4',
      },
    };
  },

  computed: {
    ...mapState('projectUserStories', [
      'enableProjectUserStoryCheckboxes',
      'selectedProjectUserStoriesMap',
      'highlightedUserStoryId',
    ]),
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
  },

  methods: {
    ...mapMutations('projectUserStories', ['TOGGLE_SELECTED_PROJECT_USER_STORY']),
    ...mapActions('projectUserStories', ['moveProjectUserStory']),

    isUserStoryOverdue(item) {
      return item.is_urgent && item.due_date && new Date() > new Date(item.due_date);
    },

    onUserStoryClick(item) {
      this.$emit('click:row', item);
      this.$router.push({
        name: 'editProjectUserStory',
        params: {
          projectId: this.$route.params.projectId || item.project_id,
          projectUserStoryId: item.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.story-row {
  display: flex;
  align-items: center;
  padding: 4px 12px;
  min-height: 40px;
}

.story-row.clickable {
  cursor: pointer;
}

.theme--light .story-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.theme--light .story-row:hover {
  background: #eee;
}

.theme--dark .story-row:not(:last-child) {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.12);
}
.theme--dark .story-row:hover {
  background: rgba(255, 255, 255, 0.08);
}
</style>
