import { removeArrayItem, updateArrayItem } from '@/util/array';
import Vue from 'vue';
import productService from '@/api/product-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { mapErrorsToInputs } from '@/util/forms';

export const getDefaultProductFormItem = () => ({});

export const getDefaultProductFilterParams = () => ({});

const state = {
  products: [],
  productPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedProduct: {},
  newProduct: getDefaultProductFormItem(),
  productValidationErrors: {},
  productFilterParams: getDefaultProductFilterParams(),
};

const mutations = {
  SET_PRODUCTS(state, { data, current_page, per_page, total }) {
    state.products = data;
    state.productPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_PRODUCT_FILTER_PARAMS(state, params) {
    state.productFilterParams = params;
  },

  SET_EDITED_PRODUCT(state, product) {
    state.productValidationErrors = {};
    state.editedProduct = JSON.parse(JSON.stringify(product));
  },

  STORE_PRODUCT(state, product) {
    state.products.push(product);
    state.productPagination.total += 1;
    state.productValidationErrors = {};
    state.newProduct = getDefaultProductFormItem();
  },

  UPDATE_PRODUCT(state, product) {
    state.products = updateArrayItem(state.products, product);
  },

  DELETE_PRODUCT(state, product) {
    state.products = removeArrayItem(state.products, product);
    state.productPagination.total -= 1;
  },

  SET_PRODUCT_VALIDATION_ERRORS(state, productValidationErrors) {
    state.productValidationErrors = productValidationErrors;
  },

  CLEAR_PRODUCT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.productValidationErrors, field);
  },
};

const actions = {
  fetchProducts({ commit }, params) {
    commit('SET_PRODUCT_FILTER_PARAMS', params);
    return productService.getPage(params).then((res) => {
      commit('SET_PRODUCTS', res.data);
      return res.data;
    });
  },

  storeProduct({ commit }, product) {
    return productService
      .create(product)
      .then((res) => {
        commit('STORE_PRODUCT', res.data);
        openSnackbar(i18n.t('general.products.product_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PRODUCT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editProduct({ state, commit }, productId) {
    const product = state.products?.find((s) => s.id === productId);
    if (product) {
      commit('SET_EDITED_PRODUCT', product);
      return Promise.resolve(product);
    }
    return productService.getById(productId).then((res) => {
      commit('SET_EDITED_PRODUCT', res.data);
      return res.data;
    });
  },

  updateProduct({ commit }, product) {
    return productService
      .update(product)
      .then((res) => {
        commit('UPDATE_PRODUCT', res.data);
        openSnackbar(i18n.t('general.products.product_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PRODUCT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  deleteProduct({ commit }, product) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      productService.delete(product).then(() => {
        commit('DELETE_PRODUCT', product);
        openSnackbar(i18n.t('general.products.product_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
