import projectIssueStatisticsService from '@/api/project-issue-statistics-service';

export const getDefaultEmployeeIssueStatisticsFilterParams = () => ({});

const state = {
  employeeIssueStatistics: [],
  employeeIssueStatisticsFilterParams: getDefaultEmployeeIssueStatisticsFilterParams(),
};

const getters = {};

const mutations = {
  SET_EMPLOYEE_ISSUE_STATISTICS(state, data) {
    state.employeeIssueStatistics = data;
  },

  SET_EMPLOYEE_ISSUE_STATISTICS_FILTER_PARAMS(state, params) {
    state.employeeIssueStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchEmployeeIssueStatistics({ commit }, params) {
    commit('SET_EMPLOYEE_ISSUE_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectIssueStatisticsService.get({ ...params, type: 'users' });
    commit('SET_EMPLOYEE_ISSUE_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
