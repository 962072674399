<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="1000"
    scrollable
    persistent
  >
    <ReceivedInvoiceForm
      :received-invoice="newReceivedInvoice"
      :errors="receivedInvoiceValidationErrors"
      :disabled="$store.getters.loading['post:api/documents']"
      @clear:errors="CLEAR_RECEIVED_INVOICE_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ReceivedInvoiceForm from '@/components/forms/ReceivedInvoiceForm';
import { returnFromChildView } from '@/util/routing';

export default {
  name: 'CreateReceivedInvoice',

  components: { ReceivedInvoiceForm },

  computed: {
    ...mapState('receivedInvoices', [
      'newReceivedInvoice',
      'receivedInvoiceValidationErrors',
      'receivedInvoiceFilterParams',
    ]),
  },

  created() {
    this.SET_RECEIVED_INVOICE_VALIDATION_ERRORS({});
  },

  methods: {
    ...mapActions('receivedInvoices', ['storeReceivedInvoice']),
    ...mapMutations('receivedInvoices', [
      'SET_RECEIVED_INVOICE_VALIDATION_ERRORS',
      'CLEAR_RECEIVED_INVOICE_VALIDATION_ERRORS',
    ]),

    onSave(receivedInvoice) {
      this.storeReceivedInvoice(receivedInvoice).then(() => {
        this.goBack();
      });
    },

    goBack() {
      returnFromChildView(this.$route, 'uploading-document', {
        name: 'receivedInvoices',
        query: this.receivedInvoiceFilterParams,
      });
    },
  },
};
</script>
