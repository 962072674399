import httpClient from '@/api/http-client';

const endpoint = 'api/projects';

function getTransformedProjectRequestBody(project) {
  const requestBody = JSON.parse(JSON.stringify(project));
  const count = requestBody.assignees.length;
  requestBody.assignees = [];
  for (let i = 0; i < count; i++) {
    if (
      project.assignees[i].user_id &&
      project.assignees[i].permission &&
      project.assignees[i].language
    ) {
      requestBody.assignees.push({
        user_id: project.assignees[i].user_id,
        permission: project.assignees[i].permission,
        language: project.assignees[i].language,
        message_on_issue: project.assignees[i].message_on_issue,
      });
    }
  }
  return requestBody;
}

const projectService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  updateProjectSettings: (assignee) =>
    httpClient.put(`${endpoint}/assignees/${assignee.id}/user-settings`, {
      message_on_issue: assignee.message_on_issue,
      language: assignee.language,
    }),

  create: (project) => httpClient.post(endpoint, getTransformedProjectRequestBody(project)),

  update: (project) =>
    httpClient.put(`${endpoint}/${project.id}`, getTransformedProjectRequestBody(project)),

  updateAssignees: (projectId, assignees) =>
    httpClient.put(`${endpoint}/assignees/${projectId}`, { assignees }),

  deleteAssignee: (projectId, assignee) =>
    httpClient.delete(`${endpoint}/assignees/${projectId}/delete`, {
      data: { user_ids: [assignee.user_id] },
    }),

  delete: (project) => httpClient.delete(`${endpoint}/${project.id}`),
};

export default projectService;
