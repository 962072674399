import Vue from 'vue';
import projectIssueService from '@/api/project-issue-service';
import i18n from '@/i18n/i18n-config';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { getSanitizedFilterParams } from '@/util/filter-params';

function getDefaultProjectIssueFormItem() {
  return {
    status: 'new',
    priority: 'normal',
    category: null,
    is_solved: false,
    is_in_production: false,
  };
}

export function getDefaultProjectIssueFilterParams() {
  return {
    status: [],
  };
}

export function getSanitizedProjectIssueFilterParams(params) {
  const p = getSanitizedFilterParams(params);
  if (typeof p.status === 'string') {
    p.status = [p.status];
  }

  if (!p.status) {
    p.status = [];
  }

  return p;
}

const state = {
  projectIssues: [],
  projectIssuePagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  projectIssueFilterParams: getDefaultProjectIssueFilterParams(),
  totalProjectIssuesCount: 0,
  newProjectIssuesCount: 0,

  newProjectIssue: getDefaultProjectIssueFormItem(),
  editedProjectIssue: {},
  projectIssueValidationErrors: {},
};

const getters = {
  projectIssueStatusesMap() {
    return {
      new: { value: 'new', text: i18n.t('projects.issue_status.new'), color: 'error' },
      seen: { value: 'seen', text: i18n.t('projects.issue_status.seen') },
      in_progress: { value: 'in_progress', text: i18n.t('projects.issue_status.in_progress') },
      // warning: confusing name, translation says finished locally
      ready_for_test: {
        value: 'ready_for_test',
        text: i18n.t('projects.issue_status.ready_for_test'),
      },
      // deployed_to_staging: {
      //   value: 'deployed_to_staging',
      //   text: i18n.t('projects.issue_status.deployed_to_staging'),
      // },
      // deployed_to_production: {
      //   value: 'deployed_to_production',
      //   text: i18n.t('projects.issue_status.deployed_to_production'),
      // },
      postponed: { value: 'postponed', text: i18n.t('projects.issue_status.postponed') },
      closed: { value: 'closed', text: i18n.t('projects.issue_status.closed') },
      rejected: { value: 'rejected', text: i18n.t('projects.issue_status.rejected') },
    };
  },

  projectIssueStatuses(state, getters) {
    return Object.values(getters.projectIssueStatusesMap);
  },

  projectIssuePrioritiesMap() {
    return {
      normal: {
        value: 'normal',
        text: i18n.t('projects.issue_priorities.normal'),
      },
      important: {
        value: 'important',
        text: i18n.t('projects.issue_priorities.important'),
      },
      critical: {
        value: 'critical',
        text: i18n.t('projects.issue_priorities.critical'),
      },
    };
  },

  projectIssuePriorities(state, getters) {
    return Object.values(getters.projectIssuePrioritiesMap);
  },

  projectIssueTimeSpentOptions(){
    return [
      {value: 0.25, text: "15min"},
      {value: 0.5, text: "30min"},
      {value: 1, text: "1h"},
      {value: 2, text: "2h"},
      {value: 4, text: "4h"},
      {value: 8, text: "8h"},
      {value: 16, text: "16h"},
      {value: 24, text: "24h"},
      {value: 32, text: "32h"},
      {value: 40, text: "40h"},
    ];
  },

  projectIssueCategoriesMap() {
    return {
      null: { value: null, text: i18n.t('projects.issue_categories.without_category') },
      log: { value: 'log', text: i18n.t('projects.issue_categories.logic') },
      req: {
        value: 'req',
        text: i18n.t('projects.issue_categories.requirement'),
        description: i18n.t('projects.issue_categories.descriptions.requirement'),
      },
      gui: {
        value: 'gui',
        text: i18n.t('projects.issue_categories.gui'),
        description: i18n.t('projects.issue_categories.descriptions.gui'),
      },
      dsn: {
        value: 'dsn',
        text: i18n.t('projects.issue_categories.design'),
        description: i18n.t('projects.issue_categories.descriptions.design'),
      },
      typ: {
        value: 'typ',
        text: i18n.t('projects.issue_categories.typography'),
        description: i18n.t('projects.issue_categories.descriptions.typography'),
      },
    };
  },

  projectIssueCategories(state, getters) {
    return Object.values(getters.projectIssueCategoriesMap);
  },

  currentUserPermissionForEditedIssue(state, getters, rootState, rootGetters) {
    const { id, role } = rootGetters['auth/currentUser'];

    if (role === 'admin') {
      return 'admin';
    }

    const { project_assignees } = state.editedProjectIssue;
    for (let i = 0; i < project_assignees?.length; i++) {
      if (project_assignees[i].user_id === id) {
        return project_assignees[i].permission;
      }
    }

    return '';
  },
};

const mutations = {
  SET_PROJECT_ISSUES(state, { data, meta }) {
    state.projectIssues = data || [];
    state.projectIssuePagination = {
      current_page: meta?.current_page || 1,
      per_page: meta?.per_page || 50,
      total: meta?.total || -1,
    };
  },

  SET_PROJECT_ISSUE_COUNTS(state, { total_issues, new_issues }) {
    state.totalProjectIssuesCount = total_issues;
    state.newProjectIssuesCount = new_issues;
  },

  SET_PROJECT_ISSUE_FILTER_PARAMS(state, params) {
    state.projectIssueFilterParams = params;
  },

  SET_NEW_PROJECT_ISSUE(state, issue) {
    state.newProjectIssue = issue;
  },

  SET_EDITED_PROJECT_ISSUE(state, issue) {
    state.editedProjectIssue = JSON.parse(JSON.stringify(issue));
    state.projectIssueValidationErrors = {};
  },

  SET_PROJECT_ISSUE_VALIDATION_ERRORS(state, errors) {
    state.projectIssueValidationErrors = errors;
  },

  STORE_PROJECT_ISSUE(state, issue) {
    state.projectIssues.unshift(issue);
    state.newProjectIssue = getDefaultProjectIssueFormItem();
    state.projectIssueValidationErrors = {};
    state.projectIssuePagination.totalItems += 1;
  },

  CLEAR_PROJECT_ISSUE_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.projectIssueValidationErrors, field);
  },

  UPDATE_PROJECT_ISSUE(state, issue) {
    state.projectIssues = updateArrayItem(state.projectIssues, issue);
  },

  DELETE_PROJECT_ISSUE(state, issue) {
    state.projectIssues = removeArrayItem(state.projectIssues, issue);
    state.projectIssuePagination.totalItems -= 1;
  },
};

const actions = {
  async fetchProjectIssues({ state, commit }, params) {
    commit('SET_PROJECT_ISSUE_FILTER_PARAMS', params);
    const { data } = await projectIssueService.getPage(state.projectIssueFilterParams);
    if (
      data?.data.length &&
      state.projectIssueFilterParams.project_id &&
      +state.projectIssueFilterParams.project_id !== data.data[0].project_id
    ) {
      // fixes an issue when navigating to another project before the previous one has finished loading,
      // sometimes issues from the previous project would be displayed
      return;
    }
    commit('SET_PROJECT_ISSUES', data);
  },

  async fetchTotalProjectIssuesCount({ state, commit, rootGetters }) {
    if (rootGetters['auth/currentUser']?.role === 'client') {
      return;
    }
    const { data } = await projectIssueService.getTotalIssuesCount();
    if (
      data.totalProjectIssuesCount !== state.totalProjectIssuesCount ||
      data.newProjectIssuesCount !== state.newProjectIssuesCount
    ) {
      commit('SET_PROJECT_ISSUE_COUNTS', data);
    }
  },

  async markProjectIssueAsSeen({ state, commit }, issue) {
    const { data } = await projectIssueService.getById(issue.id);
    commit('UPDATE_PROJECT_ISSUE', data);
    if (issue.id === state.editedProjectIssue.id) {
      commit('SET_EDITED_PROJECT_ISSUE', data);
    }
  },

  async editProjectIssue({ state, commit }, issueId) {
    const projectIssue = state.projectIssues?.find((c) => c.id === issueId);
    if (projectIssue) {
      commit('SET_EDITED_PROJECT_ISSUE', projectIssue);
      return;
    }
    const { data } = await projectIssueService.getById(issueId);
    commit('SET_EDITED_PROJECT_ISSUE', data);
  },

  async storeProjectIssue({ commit, dispatch }, { issue, files }) {
    try {
      const issueResponse = await projectIssueService.create(issue);

      // to access loading of image uploading for
      // $store.getters.loading[`post:api/project-issues-image/${this.editedProjectIssue.id}`]
      commit('SET_EDITED_PROJECT_ISSUE', issueResponse.data);

      let attachmentsResponse = {};
      if (files.length) {
        attachmentsResponse = await projectIssueService.uploadAttachments(
          issueResponse.data,
          files,
        );
      }
      const createdIssue = {
        ...issueResponse.data,
        attachments: attachmentsResponse.data || [],
      };
      commit('STORE_PROJECT_ISSUE', createdIssue);
      openSnackbar(i18n.t('general.entry_created'));
      dispatch('fetchTotalProjectIssuesCount');
    } catch (err) {
      commit('SET_PROJECT_ISSUE_VALIDATION_ERRORS', mapErrorsToInputs(err));
      throw err;
    }
  },

  async updateProjectIssue({ commit, dispatch }, { issue, files }) {
    try {
      const issueResponse = await projectIssueService.update(issue);
      let attachmentsResponse = {};

      if (files.length) {
        attachmentsResponse = await projectIssueService.uploadAttachments(issue, files);
      }

      const updatedIssue = {
        ...issueResponse.data,
        attachments: [...issueResponse.data.attachments, ...(attachmentsResponse.data || [])],
      };
      commit('UPDATE_PROJECT_ISSUE', updatedIssue);
      openSnackbar(i18n.t('general.entry_updated'));
      dispatch('fetchTotalProjectIssuesCount');
    } catch (err) {
      commit('SET_PROJECT_ISSUE_VALIDATION_ERRORS', mapErrorsToInputs(err));
      throw err;
    }
  },

  async concludeProjectIssue({ commit, dispatch }, issue) {
    try {
      const issueConcludeResponse = await projectIssueService.conclude(issue);
      const issueUpdateResponse = await projectIssueService.update({
        ...issueConcludeResponse.data,
        status: 'closed',
      });
      commit('UPDATE_PROJECT_ISSUE', issueUpdateResponse.data);
      openSnackbar(i18n.t('general.entry_updated'));
      dispatch('fetchTotalProjectIssuesCount');
    } catch (err) {
      commit('SET_PROJECT_ISSUE_VALIDATION_ERRORS', mapErrorsToInputs(err));
      throw err;
    }
  },

  async convertProjectIssueToUserStory(
    { state, commit, dispatch },
    { projectIssue, projectUserStory },
  ) {
    let response;
    try {
      response = await projectIssueService.convertToUserStory(projectIssue, projectUserStory);

      commit('projectUserStories/STORE_PROJECT_USER_STORY', response.data.project_user_story, {
        root: true,
      });
      if (state.projectIssueFilterParams.status?.includes('rejected')) {
        commit('UPDATE_PROJECT_ISSUE', response.data.project_issue);
      } else {
        commit('DELETE_PROJECT_ISSUE', response.data.project_issue);
      }

      openSnackbar(i18n.t('projects.user_story_created'));
      dispatch('fetchTotalProjectIssuesCount');
    } catch (err) {
      commit(
        'projectUserStories/SET_PROJECT_USER_STORY_VALIDATION_ERRORS',
        mapErrorsToInputs(err),
        { root: true },
      );
      throw err;
    }
    return response.data;
  },

  async deleteProjectIssueAttachment({ state, commit }, { issue, attachment }) {
    await projectIssueService.deleteAttachment(issue, attachment);
    openSnackbar(i18n.t('general.file_deleted'));
    const updatedIssue = {
      ...issue,
      attachments: issue.attachments.filter((a) => a.id !== attachment.id),
    };
    commit('UPDATE_PROJECT_ISSUE', updatedIssue);
    if (state.editedProjectIssue.id === updatedIssue.id) {
      commit('SET_EDITED_PROJECT_ISSUE', updatedIssue);
    }
  },

  async updateProjectIssueStatus({ commit, dispatch }, issue) {
    const { data } = await projectIssueService.updateStatus(issue);
    commit('UPDATE_PROJECT_ISSUE', data);
    openSnackbar(i18n.t('general.entry_updated'));
    dispatch('fetchTotalProjectIssuesCount');
  },

  async deleteProjectIssue({ commit, dispatch }, issue) {
    const hasConfirmed = await openConfirmDialog();
    if (!hasConfirmed) {
      return 'cancelled';
    }
    await projectIssueService.delete(issue);
    commit('DELETE_PROJECT_ISSUE', issue);
    openSnackbar(i18n.t('general.entry_deleted'));
    dispatch('fetchTotalProjectIssuesCount');
    return 'deleted';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
