<template>
  <div>
    <BaseAutocomplete
      :value="value"
      :search-function="searchFunction"
      :get-by-id-function="getContactById"
      :label="label"
      :initial-item="initialItem"
      :error-messages="errorMessages"
      :append-icon="showCreateButton && $can(['clients.existing.create']) ? 'add' : ''"
      item-text="name"
      no-filter
      clearable
      use-selection-slot
      use-item-slot
      @input="$emit('input', $event)"
      @update:initial-item="$emit('update:initial-item', $event)"
      @click:append="createNewContact"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="item.name">{{ item.name }}</span>
            <span v-else>{{ item.full_name }}</span>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="!item.is_supplier && !item.is_lead && !item.is_erp_company"
            class="text-body-2"
            style="font-size: 12px !important"
          >
            {{ $t('general.client') }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="item.is_supplier"
            class="text-body-2"
            style="font-size: 12px !important"
          >
            {{ $t('general.supplier') }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="item.is_lead"
            class="text-body-2"
            style="font-size: 12px !important"
          >
            {{ $t('general.lead') }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="item.is_erp_company"
            class="text-body-2"
            style="font-size: 12px !important"
          >
            {{ $t('general.erp_company') }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:selection="{ item }">
        <span v-if="item.name">{{ item.name }}</span>
        <span v-else>{{ item.full_name }}</span>
      </template>
    </BaseAutocomplete>

    <BaseNestableDialog
      v-if="showCreateButton && $route.query[queryParam]"
      max-width="900"
      scrollable
      persistent
    >
      <ContactForm
        :contact="newContact"
        :errors="contactValidationErrors"
        :disabled="$store.getters.loading['post:api/clients']"
        @clear:errors="CLEAR_CONTACT_VALIDATION_ERRORS"
        @cancel="closeContactDialog"
        @save="saveNewContact"
      />
    </BaseNestableDialog>
  </div>
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import contactService from '@/api/contact-service';
import i18n from '@/i18n/i18n-config';
import {
  getDefaultClientContactFormItem,
  getDefaultSupplierContactFormItem,
} from '@/store/modules/contacts-module';
import { mapActions, mapMutations, mapState } from 'vuex';
import ContactForm from '@/components/forms/ContactForm';
import BaseNestableDialog from '@/components/base/BaseNestableDialog';

export default {
  name: 'ContactAutocomplete',

  components: { BaseNestableDialog, ContactForm, BaseAutocomplete },

  props: {
    value: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    initialItem: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: () => i18n.t('general.contact'),
    },
    suppliersOnly: {
      type: Boolean,
      default: false,
    },
    // itemText: {
    //   type: String,
    //   default: 'name' || 'full_name',
    // },
    showCreateButton: {
      // don't forget to update initialItem by listening to @update:initial-item
      // if this prop is set to true
      // otherwise item displayed inside autocomplete field won't update
      type: Boolean,
      default: false,
    },
    queryParam: {
      // if multiple instances of this component are used in the same page,
      // with showCreateButton set to true,
      // specify different value for this prop,
      // otherwise multiple forms will be opened
      type: String,
      default: 'create-contact',
    },
  },

  computed: {
    ...mapState('contacts', ['newContact', 'contactValidationErrors']),

    searchFunction() {
      if (this.suppliersOnly) {
        return this.searchSuppliers;
      }

      return this.searchContacts;
    },
  },

  methods: {
    ...mapActions('contacts', ['storeContact']),
    ...mapMutations('contacts', [
      'SET_NEW_CONTACT',
      'SET_CONTACT_VALIDATION_ERRORS',
      'CLEAR_CONTACT_VALIDATION_ERRORS',
    ]),

    getContactById: contactService.getById,
    searchContacts: contactService.search,
    searchSuppliers: contactService.searchSuppliers,

    closeContactDialog() {
      const newQuery = {
        ...this.$route.query,
      };
      delete newQuery[this.queryParam];

      this.$router.push({
        ...this.$route,
        query: newQuery,
      });
    },

    createNewContact() {
      this.SET_CONTACT_VALIDATION_ERRORS({});
      if (this.suppliersOnly) {
        this.SET_NEW_CONTACT(getDefaultSupplierContactFormItem());
      } else {
        this.SET_NEW_CONTACT(getDefaultClientContactFormItem());
      }
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          [this.queryParam]: true,
        },
      });
    },

    saveNewContact(contact) {
      this.storeContact(contact).then((createdContact) => {
        this.$emit('update:initial-item', createdContact);
        this.$emit('input', createdContact.id);
        this.closeContactDialog();
      });
    },
  },
};
</script>

<style scoped></style>
