<template>
  <v-navigation-drawer
    :value="sidenavOpen"
    :clipped="$vuetify.breakpoint.lgAndUp"
    fixed
    app
    @input="updateSetting({ key: 'sidenavOpen', value: $event })"
  >
    <div v-if="$vuetify.breakpoint.mdAndDown" class="ma-3">
      <img
        v-if="logoUrl"
        :src="logoUrl"
        alt="logo"
        height="30"
        class="clickable"
        @click="$router.push('/')"
      />
    </div>
    <v-list dense nav>
      <template v-for="(item, index) in menuItems">
        <v-divider v-if="item.divider" :key="`divider-${index}`" class="mb-1" />
        <v-list-item
          v-else-if="!item.children && $can(item.permissions) && !item.hidden"
          :prepend-icon="item.icon"
          :key="item.text"
          :to="item.route"
          :disabled="!item.route"
          active-class="primary--text"
          link
        >
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>

        <v-list-group
          v-else-if="$can(item.permissions) && !item.hidden"
          v-model="item.expanded"
          :key="item.text"
          :prepend-icon="item.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </template>

          <template v-for="(child, i) in item.children">
            <v-list-item
              v-if="
                !child.grandchildren &&
                (!child.permissions || $can(child.permissions)) &&
                !child.hidden
              "
              :key="i"
              :to="child.route"
              :disabled="!child.route"
              link
            >
              <v-list-item-icon />
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getDefaultTimeTrackingFilterParams } from '@/store/modules/time-tracking-module';
import clone from 'just-clone';
import { getAppLogoUrl } from '@/util/company-attributes';

export default {
  name: 'NavigationDrawer',

  data() {
    return {
      logoUrl: getAppLogoUrl(),
    };
  },

  computed: {
    ...mapState('settings', ['sidenavOpen', 'darkTheme']),
    ...mapState('contacts', ['contactFilterParams']),
    ...mapState('invoices', ['invoiceFilterParams']),
    ...mapState('projectIssueStatistics', ['projectIssueStatisticsFilterParams']),
    ...mapState('projects', ['projectFilterParams']),
    ...mapState('projectIssues', ['projectIssueFilterParams']),
    ...mapState('receivedInvoices', ['receivedInvoiceFilterParams']),
    ...mapState('sentInvoices', ['sentInvoiceFilterParams']),
    ...mapState('supplierAgreements', ['supplierAgreementFilterParams']),
    ...mapState('users', ['userFilterParams']),
    ...mapState('products', ['productFilterParams']),
    ...mapState('projectOrderStatistics', ['projectOrderStatisticsFilterParams']),

    transformedProjectIssueFilterParams() {
      const params = clone(this.projectIssueFilterParams);
      if (this.$route.params.projectId) {
        // when going to all issues list from project page, project filter should be reset
        delete params.project_id;
      }
      return params;
    },

    menuItems() {
      // if (this.$store.getters['auth/currentUser']?.role === 'client') {
      //   // client menu items
      //   return [
      //     {
      //       icon: 'euro_symbol',
      //       text: this.$t('general.invoices'),
      //       permissions: ['invoices.view.*'],
      //       route: { name: 'invoices', query: this.invoiceFilterParams },
      //     },
      //     {
      //       icon: 'build',
      //       text: this.$t('general.projects'),
      //       route: '/client-projects',
      //       permissions: ['client.projects.view.*'],
      //     },
      //   ];
      // }
      // employee/admin menu items
      let items = [
        {
          icon: 'inventory',
          text: this.$t('general.products.products'),
          route: { name: 'products', query: this.productFilterParams },
          permissions: ['products.*'],
        },
        {
          icon: 'bug_report',
          text: this.$t('projects.tabs.issues'),
          route: { name: 'projectIssues', query: this.transformedProjectIssueFilterParams },
          permissions: ['projects.view.*', 'projects.create.*'],
        },
        {
          icon: 'schedule',
          text: this.$t('general.time_tracking'),
          route: { name: 'timeTracking', query: getDefaultTimeTrackingFilterParams() },
          permissions: ['projects.view.*', 'projects.create.*'],
          hidden: this.$vuetify.breakpoint.mdAndDown || this.$isClient(),
        },
        {
          icon: 'equalizer',
          text: this.$t('general.statistics'),
          permissions: ['projects.view.*'],
          hidden: this.$isClient(),
          expanded: this.$route.meta.menuGroup === 'statistics',
          children: [
            {
              text: this.$t('general.project_statistics'),
              route: {
                name: 'projectIssueStatistics',
                query: this.projectIssueStatisticsFilterParams,
              },
            },
            {
              text: this.$t('general.order_statistics'),
              route: {
                name: 'projectOrderStatistics',
                query: this.projectOrderStatisticsFilterParams,
              },
              hidden:
                this.$isEmployee() &&
                !this.$can(['projects.*']) &&
                !this.$can(['projects.create.*']),
            },
          ],
        },
        {
          icon: 'import_contacts',
          text: this.$t('general.common_contact_info.contacts'),
          route: { name: 'contacts', query: this.contactFilterParams },
          permissions: [
            'clients.view.contact_info',
            'clients.existing.view.finance_info',
            'clients.existing.view.name',
            'clients.existing.view.project_info',
          ],
        },
        {
          icon: 'euro_symbol',
          text: this.$t('general.issued_invoices'),
          route: { name: 'invoices', query: this.invoiceFilterParams },
          permissions: ['invoices.*'],
        },
        {
          icon: 'insert_drive_file',
          text: this.$t('general.received_invoices'),
          route: { name: 'receivedInvoices', query: this.receivedInvoiceFilterParams },
          permissions: ['documents.received_invoice.view.*'],
        },
        // {
        //   icon: 'insert_drive_file',
        //   text: this.$t('general.sent_invoices'),
        //   route: { name: 'sentInvoices', query: this.sentInvoiceFilterParams },
        //   permissions: ['documents.sent_invoice.view.*'],
        // },
        {
          icon: 'folder',
          text: this.$t('general.documents'),
          route: { name: 'supplierAgreements', query: this.supplierAgreementFilterParams },
          permissions: ['documents.supplier_agreement.view.*'],
        },
        {
          icon: 'people',
          text: this.$t('general.users'),
          route: { name: 'users', query: this.userFilterParams },
          permissions: ['users.*'],
        },
      ];

      // if (
      //   this.$isAdmin() ||
      //   (this.$isEmployee() && this.$can(['projects.*'] || this.$can(['projects.create.*'])))
      // ) {
      //   for (let i = 0; i < items.length; i++) {
      //     if (items[i].text === this.$t('general.statistics')) {
      //       items[i].children.push({
      //         text: this.$t('general.order_statistics'),
      //         route: {
      //           name: 'projectOrderStatistics',
      //           query: this.projectOrderStatisticsFilterParams,
      //         },
      //       });
      //     }
      //   }
      // }

      if (
        this.$store.getters['auth/currentUser']?.role === 'client' &&
        !this.$can(['projects.view.*'])
      ) {
        items.unshift({
          icon: 'build',
          text: this.$t('general.projects'),
          route: '/client-projects',
          permissions: ['client.projects.view.*'],
        });
      } else {
        items.unshift({
          icon: 'build',
          text: this.$t('general.projects'),
          route: { name: 'projects', query: this.projectFilterParams },
          permissions: ['projects.view.*'],
        });
      }

      if (this.$store.getters['auth/currentUser']?.role === 'client') {
        // for client role dont show contacts
        items = items.filter(
          (item) => item.route?.name !== 'contacts' && item.route?.name !== 'timeTracking',
        );
      }

      return items;
    },
  },

  methods: {
    ...mapActions('settings', ['updateSetting']),
  },
};
</script>
