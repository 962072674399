import { render, staticRenderFns } from "./ProjectUserStoriesList.vue?vue&type=template&id=eba7dad2&scoped=true&"
import script from "./ProjectUserStoriesList.vue?vue&type=script&lang=js&"
export * from "./ProjectUserStoriesList.vue?vue&type=script&lang=js&"
import style0 from "./ProjectUserStoriesList.vue?vue&type=style&index=0&id=eba7dad2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eba7dad2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VIcon,VSpacer,VTooltip})
