import Vue from 'vue';
import documentService from '@/api/document-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import { downloadFile } from '@/util/files';
import { format } from 'date-fns';
import clone from 'just-clone';
import invoiceService from '@/api/invoice-service';

export const getDefaultReceivedInvoiceFormItem = () => ({
  document_date: format(new Date(), 'yyyy-MM-dd'),
});

export const getDefaultReceivedInvoiceFilterParams = () => ({});

const state = {
  receivedInvoices: [],
  receivedInvoiceStatistics: [],
  receivedInvoicePagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedReceivedInvoice: {},
  newReceivedInvoice: getDefaultReceivedInvoiceFormItem(),
  receivedInvoiceValidationErrors: {},
  receivedInvoiceFilterParams: getDefaultReceivedInvoiceFilterParams(),
  downloadingReceivedInvoices: false, // booleans instead of $store.getter.loading because both loading and downloading use the same route
  loadingReceivedInvoices: false,
};

const getters = {
  receivedInvoiceFileFormats() {
    return '.pdf, .doc, .docx, .png, .jpg, .jpeg';
  },
};

const mutations = {
  SET_RECEIVED_INVOICES(state, { data, current_page, per_page, total }) {
    state.receivedInvoices = data;
    state.receivedInvoicePagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_RECEIVED_INVOICE_STATISTICS(state, data) {
    state.receivedInvoiceStatistics = data;
  },

  SET_RECEIVED_INVOICE_FILTER_PARAMS(state, params) {
    state.receivedInvoiceFilterParams = params;
  },

  SET_NEW_RECEIVED_INVOICE(state, receivedInvoice) {
    state.newReceivedInvoice = receivedInvoice;
  },

  SET_EDITED_RECEIVED_INVOICE(state, receivedInvoice) {
    state.receivedInvoiceValidationErrors = {};
    state.editedReceivedInvoice = JSON.parse(JSON.stringify(receivedInvoice));
  },

  STORE_RECEIVED_INVOICE(state, receivedInvoice) {
    state.receivedInvoices.unshift(receivedInvoice);
    state.receivedInvoicePagination.total += 1;
    state.receivedInvoiceValidationErrors = {};
    state.newReceivedInvoice = getDefaultReceivedInvoiceFormItem();
  },

  UPDATE_RECEIVED_INVOICE(state, receivedInvoice) {
    state.receivedInvoices = updateArrayItem(state.receivedInvoices, receivedInvoice);
  },

  DELETE_RECEIVED_INVOICE(state, receivedInvoice) {
    state.receivedInvoices = removeArrayItem(state.receivedInvoices, receivedInvoice);
    state.receivedInvoicePagination.total -= 1;
  },

  SET_RECEIVED_INVOICE_VALIDATION_ERRORS(state, receivedInvoiceValidationErrors) {
    state.receivedInvoiceValidationErrors = receivedInvoiceValidationErrors;
  },

  CLEAR_RECEIVED_INVOICE_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.receivedInvoiceValidationErrors, field);
  },

  SET_DOWNLOADING_RECEIVED_INVOICES(state, value) {
    state.downloadingReceivedInvoices = value;
  },

  SET_LOADING_RECEIVED_INVOICES(state, value) {
    state.loadingReceivedInvoices = value;
  },
};

const actions = {
  async fetchReceivedInvoices({ commit }, params) {
    commit('SET_RECEIVED_INVOICE_FILTER_PARAMS', params);
    commit('SET_LOADING_RECEIVED_INVOICES', true);

    const statisticsRequestParams = clone(params);
    if (params.document_date_from) {
      statisticsRequestParams.date_from = params.document_date_from;
      delete statisticsRequestParams.document_date_from;
    }
    if (params.document_date_to) {
      statisticsRequestParams.date_to = params.document_date_to;
      delete statisticsRequestParams.document_date_to;
    }
    statisticsRequestParams.type = 'received';

    let invoicesData = [];
    let statisticsData = [];
    const requests = [
      documentService.getPage(params, 'received_invoice').then((res) => {
        invoicesData = res.data;
      }),
    ];
    if (!params.supplier_id) {
      requests.push(
        invoiceService.getStatistics(statisticsRequestParams).then((res) => {
          statisticsData = res.data;
        }),
      );
    }

    await Promise.all(requests);
    commit('SET_RECEIVED_INVOICES', invoicesData);
    commit('SET_RECEIVED_INVOICE_STATISTICS', statisticsData);
    commit('SET_LOADING_RECEIVED_INVOICES', false);

    return invoicesData;
  },

  async fetchReceivedInvoiceStatistics({ commit }, params) {
    const statisticsRequestParams = clone(params);
    if (params.document_date_from) {
      statisticsRequestParams.date_from = params.document_date_from;
      delete statisticsRequestParams.document_date_from;
    }
    if (params.document_date_to) {
      statisticsRequestParams.date_to = params.document_date_to;
      delete statisticsRequestParams.document_date_to;
    }
    if (statisticsRequestParams.supplier_id) {
      delete statisticsRequestParams.supplier_id;
    }
    statisticsRequestParams.type = 'received';

    const { data } = await invoiceService.getStatistics(statisticsRequestParams);
    commit('SET_RECEIVED_INVOICE_STATISTICS', data);
  },

  async downloadReceivedInvoices({ state, commit }) {
    commit('SET_DOWNLOADING_RECEIVED_INVOICES', true);
    const { data } = await documentService.downloadAll(
      state.receivedInvoiceFilterParams,
      'received_invoice',
    );
    commit('SET_DOWNLOADING_RECEIVED_INVOICES', false);
    downloadFile(data, `${i18n.t('general.received_invoices')}.zip`);
  },

  storeReceivedInvoice({ state, commit, dispatch }, receivedInvoice) {
    return documentService
      .create({ ...receivedInvoice, document_type: 'received_invoice' })
      .then((res) => {
        commit('STORE_RECEIVED_INVOICE', res.data);
        dispatch('fetchReceivedInvoiceStatistics', state.receivedInvoiceFilterParams);
        openSnackbar(i18n.t('documents.messages.document_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_RECEIVED_INVOICE_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editReceivedInvoice({ state, commit }, receivedInvoiceId) {
    const receivedInvoice = state.receivedInvoices?.find((c) => c.id === receivedInvoiceId);
    if (receivedInvoice) {
      commit('SET_EDITED_RECEIVED_INVOICE', receivedInvoice);
      return Promise.resolve(receivedInvoice);
    }
    return documentService.getById(receivedInvoiceId).then((res) => {
      commit('SET_EDITED_RECEIVED_INVOICE', res.data);
      return res.data;
    });
  },

  updateReceivedInvoice({ state, commit, dispatch }, receivedInvoice) {
    return documentService
      .update(receivedInvoice)
      .then((res) => {
        commit('UPDATE_RECEIVED_INVOICE', res.data);
        dispatch('fetchReceivedInvoiceStatistics', state.receivedInvoiceFilterParams);
        openSnackbar(i18n.t('documents.messages.document_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_RECEIVED_INVOICE_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async toggleSeenReceivedInvoice({ commit }, receivedInvoice) {
    try {
      commit('UPDATE_RECEIVED_INVOICE', {
        ...receivedInvoice,
        seen: receivedInvoice.seen ? null : {},
      });
      await documentService.toggleSeen(receivedInvoice);
    } catch (e) {
      commit('UPDATE_RECEIVED_INVOICE', receivedInvoice);
    }
  },

  deleteReceivedInvoice({ state, commit, dispatch }, receivedInvoice) {
    openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      documentService.delete(receivedInvoice).then(() => {
        commit('DELETE_RECEIVED_INVOICE', receivedInvoice);
        dispatch('fetchReceivedInvoiceStatistics', state.receivedInvoiceFilterParams);
        openSnackbar(i18n.t('documents.messages.document_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
