/* eslint-disable no-prototype-builtins */
import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth-module';
import contacts from './modules/contacts-module';
import employeeIssueStatistics from './modules/employee-issue-statistics-module';
import invoices from './modules/invoices-module';
import projects from './modules/projects-module';
import projectIssues from './modules/project-issues-module';
import projectPointStatistics from './modules/project-point-statistics-module';
import projectSprints from './modules/project-sprints-module';
import projectTimeSpentStatistics from './modules/project-time-spent-statistics-module';
import projectUserStories from './modules/project-user-stories-module';
import projectIssueCreatedByStatistics from './modules/project-issue-created-by-statistics-module';
import projectIssueStatistics from './modules/project-issue-statistics-module';
import receivedInvoices from './modules/received-invoices-module';
import sentInvoices from './modules/sent-invoices-module';
import supplierAgreements from './modules/supplier-agreements-module';
import shortcuts from './modules/shortcuts-module';
import notifications from './modules/notifications-module';
import progress from './modules/progress-module';
import settings from './modules/settings-module';
import timeTracking from './modules/time-tracking-module';
import users from './modules/users-module';
import products from './modules/products-module';
import projectOrderStatistics from './modules/project-order-statistics-module';
import contactOrderStatistics from './modules/contact-order-statistics-module';
import orderStatisticsByDate from './modules/order-statistics-by-date';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  mutations: {},

  actions: {},

  modules: {
    auth,
    contacts,
    employeeIssueStatistics,
    invoices,
    projects,
    projectIssues,
    projectIssueCreatedByStatistics,
    projectIssueStatistics,
    projectPointStatistics,
    projectSprints,
    projectTimeSpentStatistics,
    projectUserStories,
    receivedInvoices,
    sentInvoices,
    supplierAgreements,
    shortcuts,
    notifications,
    progress,
    settings,
    timeTracking,
    users,
    products,
    projectOrderStatistics,
    contactOrderStatistics,
    orderStatisticsByDate,
  },
});
