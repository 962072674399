import { authGuard } from '@/router/guards';

export const profileRoutes = [
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile'),
    beforeEnter: authGuard,
    children: [
      {
        path: 'shortcuts/create',
        name: 'createShortcut',
        component: () =>
          import(/* webpackChunkName: "createShortcut" */ '@/views/shortcuts/CreateShortcut'),
        beforeEnter: authGuard,
      },
      {
        path: 'shortcuts/:shortcutId/edit',
        name: 'editShortcut',
        component: () =>
          import(/* webpackChunkName: "editShortcut" */ '@/views/shortcuts/EditShortcut'),
        beforeEnter: authGuard,
      },
    ],
  },
];
