import Vue from 'vue';
import projectSprintService from '@/api/project-sprint-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import clone from 'just-clone';
import { addDays, format } from 'date-fns';

export const getDefaultProjectSprintFormItem = () => ({
  status: 'planned',
  contract_status: 'irrelevant',
  advance_payment_status: 'irrelevant',
  final_payment_status: 'irrelevant',
});

const state = {
  projectSprints: [],
  selectedProjectSprint: {},
  newProjectSprint: getDefaultProjectSprintFormItem(),
  projectSprintValidationErrors: {},
};

const getters = {
  projectSprintStatuses() {
    return ['planned', 'started', 'postponed', 'closed', 'supported'].map((s) => ({
      value: s,
      text: i18n.t(`projects.labels.${s}`),
    }));
  },

  projectSprintContractStatusTypes() {
    return [
      { value: 'irrelevant', text: i18n.t('projects.labels.irrelevant') },
      { value: 'sent', text: i18n.t('projects.labels.sent') },
      { value: 'paid', text: i18n.t('general.actions.paid') },
    ];
  },

  expandedSprintPanelIndexes() {
    const indexes = [];
    for (let i = 0; i < state.projectSprints.length; i++) {
      if (state.projectSprints[i].isExpanded) {
        indexes.push(i);
      }
    }
    return indexes;
  },
};

const mutations = {
  SET_PROJECT_SPRINTS(state, data) {
    const sprints = clone(data);
    for (let i = 0; i < sprints.length; i++) {
      if (['planned', 'started', 'postponed'].includes(sprints[i].status)) {
        sprints[i].isExpanded = true;
      }
    }
    state.projectSprints = sprints;
  },

  STORE_PROJECT_SPRINTS(state, newSprints) {
    const sprints = [...state.projectSprints];
    for (let i = 0; i < newSprints.length; i++) {
      sprints.push({
        ...newSprints[i],
        isExpanded: ['planned', 'started', 'postponed'].includes(newSprints[i].status),
      });
    }
    state.projectSprints = sprints;
  },

  SET_EDITED_PROJECT_SPRINT(state, projectSprint) {
    state.projectSprintValidationErrors = {};
    state.selectedProjectSprint = clone(projectSprint);
  },

  SET_NEW_PROJECT_SPRINT(state, projectSprint) {
    let defaultStartDate;
    let defaultDeadline;
    if (state.projectSprints.length > 0) {
      let latestDeadline = state.projectSprints[0].deadline;
      state.projectSprints.forEach((sprint) => {
        if (sprint.deadline > latestDeadline) {
          latestDeadline = sprint.deadline;
        }
      });
      defaultStartDate = format(addDays(new Date(latestDeadline), 1), 'yyyy-MM-dd');
      defaultDeadline = format(addDays(new Date(latestDeadline), 14), 'yyyy-MM-dd');
    } else {
      defaultStartDate = format(new Date(), 'yyyy-MM-dd');
      defaultDeadline = format(addDays(new Date(), 13), 'yyyy-MM-dd');
    }

    state.newProjectSprint = {
      ...getDefaultProjectSprintFormItem(),
      starts_at: defaultStartDate,
      deadline: defaultDeadline,
      ...projectSprint,
    };
  },

  STORE_PROJECT_SPRINT(state, projectSprint) {
    const createdSprint = clone(projectSprint);
    createdSprint.isExpanded = true;

    const statusWeights = {
      started: 1,
      planned: 2,
      postponed: 3,
      supported: 4,
      closed: 5,
    };

    let wasInserted = false;

    for (let i = 0; i < state.projectSprints.length; i++) {
      if (statusWeights[state.projectSprints[i].status] > statusWeights[createdSprint.status]) {
        state.projectSprints.splice(i, 0, createdSprint);
        wasInserted = true;
        break;
      }
    }

    if (!wasInserted) {
      state.projectSprints.push(createdSprint);
    }

    state.projectSprintValidationErrors = {};
    state.newProjectSprint = getDefaultProjectSprintFormItem();
  },

  UPDATE_PROJECT_SPRINT(state, projectSprint) {
    state.projectSprints = updateArrayItem(state.projectSprints, projectSprint);
  },

  DELETE_PROJECT_SPRINT(state, projectSprint) {
    state.projectSprints = removeArrayItem(state.projectSprints, projectSprint);
  },

  DELETE_PROJECT_SPRINTS_BY_STATUS(state, status) {
    state.projectSprints = state.projectSprints.filter((s) => s.status !== status);
  },

  SET_EXPANDED_SPRINT_PANEL_INDEXES(state, indexes) {
    const sprints = clone(state.projectSprints);
    for (let i = 0; i < sprints.length; i++) {
      sprints[i].isExpanded = indexes.includes(i);
    }
    state.projectSprints = sprints;
  },

  SET_PROJECT_SPRINT_VALIDATION_ERRORS(state, projectSprintValidationErrors) {
    state.projectSprintValidationErrors = projectSprintValidationErrors;
  },

  CLEAR_PROJECT_SPRINT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.projectSprintValidationErrors, field);
  },
};

const actions = {
  async fetchProjectSprints({ commit }, params) {
    const { data } = await projectSprintService.getAll(params);
    commit('STORE_PROJECT_SPRINTS', data);

    for (let i = 0; i < data.length; i++) {
      const { id, user_stories } = data[i];
      commit(
        'projectUserStories/SET_PROJECT_SPRINT_USER_STORIES',
        { sprintId: id, userStories: user_stories },
        { root: true },
      );
    }

    return data;
  },

  storeProjectSprint({ commit }, projectSprint) {
    return projectSprintService
      .create(projectSprint)
      .then((res) => {
        commit('STORE_PROJECT_SPRINT', res.data);
        commit(
          'projectUserStories/SET_PROJECT_SPRINT_USER_STORIES',
          { sprintId: res.data.id, userStories: [] },
          { root: true },
        );
        openSnackbar(i18n.t('projects.sprint_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_PROJECT_SPRINT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editProjectSprint({ state, commit }, projectSprintId) {
    const projectSprint = state.projectSprints?.find((s) => s.id === projectSprintId);
    if (projectSprint) {
      commit('SET_EDITED_PROJECT_SPRINT', projectSprint);
      return Promise.resolve(projectSprint);
    }
    return projectSprintService.getById(projectSprintId).then((res) => {
      commit('SET_EDITED_PROJECT_SPRINT', res.data);
      return res.data;
    });
  },

  async updateProjectSprint({ commit, dispatch }, projectSprint) {
    let response;
    try {
      response = await projectSprintService.update(projectSprint);
      commit('UPDATE_PROJECT_SPRINT', {
        ...projectSprint,
        ...response.data,
      });
    } catch (e) {
      commit('SET_PROJECT_SPRINT_VALIDATION_ERRORS', mapErrorsToInputs(e));
      throw e;
    }

    if (projectSprint.sprintIdForLeftoverUserStories && projectSprint.status === 'closed') {
      await dispatch(
        'projectUserStories/splitProjectSprintUserStories',
        {
          oldSprintId: projectSprint.id,
          newSprintId: projectSprint.sprintIdForLeftoverUserStories,
        },
        { root: true },
      );
    }

    openSnackbar(i18n.t('projects.sprint_updated'));
    return response.data;
  },

  async deleteProjectSprint({ commit }, projectSprint) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    });
    if (!confirmed) {
      return false;
    }

    await projectSprintService.delete(projectSprint);
    commit('DELETE_PROJECT_SPRINT', projectSprint);
    openSnackbar(i18n.t('projects.sprint_deleted'));
    return true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
