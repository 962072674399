import orderStatisticsService from '@/api/order-statistics-service';

export const getDefaultContactOrderStatisticsFilterParams = () => ({});

const state = {
  contactOrderStatistics: [],
  contactOrderStatisticsFilterParams: getDefaultContactOrderStatisticsFilterParams(),
};

const mutations = {
  SET_CONTACT_ORDER_STATISTICS(state, data) {
    state.contactOrderStatistics = data;
  },
  SET_CONTACT_ORDER_STATISTICS_FILTER_PARAMS(state, params) {
    state.contactOrderStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchContactOrderStatistics({ commit }, params) {
    commit('SET_CONTACT_ORDER_STATISTICS_FILTER_PARAMS', params);
    const { data } = await orderStatisticsService.get({ ...params, type: 'contacts' });
    commit('SET_CONTACT_ORDER_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
