import httpClient from '@/api/http-client';

const endpoint = 'api/clients';

const contactService = {
  getPage: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  getHistory: (id) => httpClient.get(`${endpoint}/history/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  searchSuppliers: (query) =>
    httpClient.get(`${endpoint}/find/${query}`, { params: { type: 'supplier' } }),

  create: (contact) => httpClient.post(endpoint, contact),

  update: (contact) => httpClient.put(`${endpoint}/${contact.id}`, contact),

  delete: (contact) => httpClient.delete(`${endpoint}/${contact.id}`),
};

export default contactService;
