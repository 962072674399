import projectIssueStatisticsService from '@/api/project-issue-statistics-service';
import { format } from 'date-fns';

export const getDefaultIssueCreatedByStatisticsFilterParams = () => ({
  year: format(new Date(), 'yyyy'),
});

const state = {
  issueCreatedByStatistics: [],
  issueCreatedByStatisticsFilterParams: getDefaultIssueCreatedByStatisticsFilterParams(),
};

const getters = {};

const mutations = {
  SET_ISSUE_CREATED_BY_STATISTICS(state, data) {
    state.issueCreatedByStatistics = data;
  },

  SET_ISSUE_CREATED_BY_STATISTICS_FILTER_PARAMS(state, params) {
    state.issueCreatedByStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchIssueCreatedByStatistics({ commit }, params) {
    commit('SET_ISSUE_CREATED_BY_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectIssueStatisticsService.getCreatedByStatistics(params);
    commit('SET_ISSUE_CREATED_BY_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
