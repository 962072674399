<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <h3 class="text-h6">
        {{ $t('general.comments') }}
      </h3>
      <div>
        <v-switch
          v-if="comments.length > 1"
          v-model="showNewestFirst"
          class="mt-0 ml-2"
          :label="$t('general.newest_first')"
          hide-details
        />
      </div>
    </div>
    <v-form @submit.prevent="createComment">
      <v-textarea
        v-model="newComment"
        :disabled="areCommentsLoading"
        :loading="areCommentsLoading"
        :label="$t('general.write_a_comment')"
        class="mb-2"
        rows="1"
        auto-grow
        hide-details
        single-line
        @keydown.esc.stop="resetNewComment"
        @focus="areNewCommentActionsDisplayed = true"
        @keypress.enter="onEnterPress"
      />
      <div class="text-right">
        <v-btn v-if="areNewCommentActionsDisplayed" class="mr-2" text @click="resetNewComment">
          {{ $t('general.controls.cancel') }}
        </v-btn>
        <v-btn
          v-if="areNewCommentActionsDisplayed"
          :disabled="!newComment || isNewCommentRequestPending"
          :loading="isNewCommentRequestPending"
          color="primary"
          type="submit"
          text
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </div>
    </v-form>
    <v-list class="pa-0">
      <template v-for="comment in comments">
        <v-form
          v-if="editedComment.id === comment.id"
          :key="comment.id"
          @submit.prevent="updateComment"
        >
          <v-textarea
            v-model="editedComment.comment"
            :label="$t('general.new_comment_text')"
            class="mb-2"
            rows="1"
            auto-grow
            hide-details
            single-line
            @keypress.enter="onEnterPress"
          />
          <div class="text-right">
            <v-btn class="mr-2" text @click="editedComment = {}">
              {{ $t('general.controls.cancel') }}
            </v-btn>
            <v-btn
              :disabled="!editedComment.comment || isCommentUpdateRequestPending"
              :loading="isCommentUpdateRequestPending"
              color="primary"
              type="submit"
              text
            >
              {{ $t('general.controls.save') }}
            </v-btn>
          </div>
        </v-form>
        <v-hover v-else #default="{ hover }" :key="comment.id">
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>
                <span class="text-subtitle-2">{{ getCommentAuthor(comment) }}</span>
                <span class="text-body-2 grey--text"
                  >&nbsp;{{ $timeFromDate(comment.created_at) }}</span
                >
              </v-list-item-title>
              <v-list-item-subtitle v-html="comment.html" class="preserve-whitespace style-links" />
              <v-list-item-subtitle class="preserve-whitespace mt-1">
                <v-chip
                  v-for="attachment in comment.attachments"
                  :key="attachment.id"
                  :close="canDeleteAttachment(attachment) && !pendingAttachmentIds[attachment.id]"
                  :disabled="
                    deletingAttachmentId === attachment.id || pendingAttachmentIds[attachment.id]
                  "
                  class="mb-1 mr-1"
                  small
                  @click="onAttachmentClick(attachment)"
                  @click:close="deleteAttachment(comment, attachment)"
                >
                  {{ attachment.name }}
                  <v-progress-circular
                    v-if="pendingAttachmentIds[attachment.id]"
                    class="ml-1"
                    size="16"
                    width="2"
                    indeterminate
                  />
                </v-chip>
                <v-progress-circular
                  v-if="areAttachmentsUploading && comment.id === selectedComment.id"
                  color="primary"
                  size="24"
                  width="3"
                  indeterminate
                />
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="my-0" style="min-width: 36px">
              <v-menu
                v-if="
                  (hover || openActionMenus[comment.id] || $store.state.settings.isTouchDevice) &&
                  canEditComment(comment)
                "
                v-model="openActionMenus[comment.id]"
                offset-y
                left
              >
                <template #activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="action in commentActions"
                    :key="action.text"
                    @click="action.callback(comment)"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        {{ action.icon }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ action.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-hover>
      </template>
    </v-list>
    <input
      ref="fileInput"
      type="file"
      class="d-none"
      accept="image/jpeg, image/png, application/zip, application/pdf, application/tar"
      multiple
      @change="uploadFiles"
    />
  </div>
</template>

<script>
import linkifyStr from 'linkifyjs/string';
import commentService from '../../api/comment-service';
import { openConfirmDialog, openFile } from '../../util/event-bus';

export default {
  name: 'BaseComments',

  props: {
    externalComments: {
      // if not specified, the component will fetch and store a local copy based on specified modelId
      type: Array,
      default: () => null,
    },
    modelType: {
      type: String, // project_user_stories, project_issues, etc.
      required: true,
    },
    modelId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      areAttachmentsUploading: false,
      areCommentsLoading: false,
      areNewCommentActionsDisplayed: false,
      deletingAttachmentId: null,
      editedComment: {},
      internalComments: [],
      isCommentUpdateRequestPending: false,
      isNewCommentRequestPending: false,
      newComment: '',
      openActionMenus: {},
      showNewestFirst: true,
      selectedComment: {}, // for file upload,
      pendingAttachmentIds: {},
    };
  },

  computed: {
    commentActions() {
      return [
        {
          icon: 'edit',
          text: this.$t('general.controls.edit'),
          callback: (comment) => this.editComment(comment),
        },
        {
          icon: 'attach_file',
          text: this.$t('general.controls.attach_files'),
          callback: (comment) => this.selectFiles(comment),
        },
        {
          icon: 'delete',
          text: this.$t('general.controls.delete'),
          callback: (comment) => this.deleteComment(comment),
        },
      ];
    },

    comments() {
      let comments = this.externalComments || this.internalComments;
      comments = comments.map((c) => ({ ...c, html: linkifyStr(c.comment) }));
      if (!this.showNewestFirst) {
        comments = comments.reverse();
      }
      return comments;
    },
  },

  created() {
    if (this.externalComments) {
      return;
    }
    this.areCommentsLoading = true;
    const params = { model_id: this.modelId, model_type: this.modelType };
    commentService
      .fetch(params)
      .then((res) => {
        this.internalComments = res.data;
      })
      .finally(() => {
        this.areCommentsLoading = false;
      });
  },

  methods: {
    canDeleteAttachment(attachment) {
      if (this.$isAdmin()) {
        return true;
      }
      return attachment.created_by === this.$store.getters['auth/currentUser']?.id;
    },

    canEditComment(comment) {
      if (this.$isAdmin()) {
        return true;
      }
      return comment.user?.id === this.$store.getters['auth/currentUser']?.id;
    },

    createComment() {
      const comment = {
        comment: this.newComment,
        model_type: this.modelType,
        model_id: this.modelId,
      };
      this.isNewCommentRequestPending = true;
      commentService
        .create(comment)
        .then((res) => {
          if (this.externalComments) {
            this.$emit('create', res.data);
          } else {
            this.internalComments.unshift(res.data);
          }
          this.newComment = '';
          this.$emit('create', res.data);
        })
        .finally(() => {
          this.isNewCommentRequestPending = false;
        });
    },

    async deleteAttachment(comment, attachment) {
      const confirmed = await openConfirmDialog({
        title: this.$t('general.confirmations.delete_comment_attachment'),
      });
      if (!confirmed) {
        return;
      }
      this.$set(this.pendingAttachmentIds, attachment.id, true);
      await commentService.deleteAttachment(attachment);
      this.$set(
        comment,
        'attachments',
        comment.attachments.filter((a) => a.id !== attachment.id)
      );
      this.$delete(this.pendingAttachmentIds, attachment.id);
    },

    async deleteComment(comment) {
      const confirmed = await openConfirmDialog({
        title: this.$t('general.confirmations.delete_comment'),
      });
      if (!confirmed) {
        return;
      }
      commentService.delete(comment).then(() => {
        this.$emit('delete', comment);
        if (!this.externalComments) {
          this.internalComments = this.internalComments.filter((c) => c.id !== comment.id);
        }
      });
    },

    editComment(comment) {
      this.editedComment = { ...comment };
      this.openActionMenus = {};
    },

    getCommentAuthor(comment) {
      if (comment.user?.personal_data?.full_name) {
        return comment.user.personal_data.full_name;
      }
      return this.$t('general.anonymous');
    },

    onEnterPress(evt) {
      if (evt.shiftKey || evt.ctrlKey) {
        return;
      }
      evt.preventDefault();
      if (this.editedComment.id) {
        this.updateComment();
      } else {
        this.createComment();
      }
    },

    resetNewComment(evt) {
      evt.target.blur();
      this.newComment = '';
      this.areNewCommentActionsDisplayed = false;
    },

    selectFiles(comment) {
      this.selectedComment = comment;
      if (!this.selectedComment.attachments) {
        this.selectedComment.attachments = [];
      }
      this.$refs.fileInput.click();
    },

    updateComment() {
      this.isCommentUpdateRequestPending = true;
      commentService
        .update(this.editedComment)
        .then((res) => {
          if (this.externalComments) {
            this.$emit('update', res.data);
          } else {
            this.internalComments = this.internalComments.map((c) =>
              c.id === res.data.id ? res.data : c
            );
          }
          this.editedComment = {};
        })
        .finally(() => {
          this.isCommentUpdateRequestPending = false;
        });
    },

    async uploadFiles(event) {
      this.areAttachmentsUploading = true;
      const response = await commentService.uploadAttachments(
        this.selectedComment,
        event.target.files
      );
      this.selectedComment.attachments.push(...response.data);
      this.areAttachmentsUploading = false;
    },

    async onAttachmentClick(attachment) {
      this.$set(this.pendingAttachmentIds, attachment.id, true);
      await openFile({
        name: attachment.name,
        url: attachment.attachment_url,
      });
      this.$delete(this.pendingAttachmentIds, attachment.id);
    },
  },
};
</script>
