import { permissionsGuard } from '@/router/guards';

export const sentInvoiceRoutes = [
  {
    path: 'documents/sent-invoices',
    name: 'sentInvoices',
    component: () =>
      import(/* webpackChunkName: "sentInvoices" */ '@/views/sent-invoices/SentInvoices'),
    beforeEnter: permissionsGuard,
    meta: { menuGroup: 'documents', requiredPermissions: ['documents.sent_invoice.view.*'] },
    children: [
      {
        path: 'create',
        name: 'createSentInvoice',
        component: () =>
          import(
            /* webpackChunkName: "createSentInvoice" */ '@/views/sent-invoices/CreateSentInvoice'
          ),
        beforeEnter: permissionsGuard,
        meta: { menuGroup: 'documents', requiredPermissions: ['documents.sent_invoice.view.*'] },
        children: [
          {
            path: 'create-contact',
            name: 'createReceivedInvoice_createInvoice',
            component: () =>
              import(/* webpackChunkName: "createInvoice" */ '@/views/invoices/CreateInvoice'),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['invoices.create'],
              menuGroup: 'documents',
              getBackRoute: () => ({
                name: 'createSentInvoice',
              }),
            },
          },
        ],
      },
      {
        path: ':id/edit',
        name: 'editSentInvoice',
        component: () =>
          import(/* webpackChunkName: "editSentInvoice" */ '@/views/sent-invoices/EditSentInvoice'),
        beforeEnter: permissionsGuard,
        meta: { menuGroup: 'documents', requiredPermissions: ['documents.sent_invoice.view.*'] },
        children: [
          {
            path: 'create-contact',
            name: 'editReceivedInvoice_createInvoice',
            component: () =>
              import(/* webpackChunkName: "createInvoice" */ '@/views/invoices/CreateInvoice'),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['invoices.create'],
              menuGroup: 'documents',
              getBackRoute: () => ({
                name: 'editSentInvoice',
              }),
            },
          },
        ],
      },
    ],
  },
];
