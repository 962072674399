<template>
  <v-slide-y-transition mode="out-in">
    <v-card v-show="items.length > 0">
      <div
        v-for="(sprints, status, index) in sprintsByStatus"
        :key="status"
        :class="{ 'mt-3': index > 0 }"
      >
        <v-subheader>
          <v-icon v-if="sprintStatusIcons[status]" class="mr-2">{{
            sprintStatusIcons[status]
          }}</v-icon>
          {{ $t(`projects.labels.${status}_sprints`) }}
        </v-subheader>

        <v-simple-table>
          <thead>
            <tr>
              <th
                v-for="header in tableMixin_displayedHeaders"
                :key="header.text"
                :class="{ 'text-center': header.align === 'center' }"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>

          <draggable
            :value="sprints"
            :id="`${status}-sprint-wrapper`"
            :animation="150"
            :disabled="!canReorder"
            :delay="150"
            :delay-on-touch-only="true"
            group="ongoingSprints"
            tag="tbody"
            @end="onDrop"
          >
            <tr v-if="!sprints.length">
              <td colspan="100%" class="text--secondary text-center">
                {{ $t('general.no_data') }}
              </td>
            </tr>
            <tr
              v-for="(sprint, sprintIndex) in sprints"
              :key="sprint.id"
              class="clickable"
              @click="goToProject(sprint)"
            >
              <td>
                {{ sprint.project_title }}
                <div v-if="$vuetify.breakpoint[headers[1].hidden]" class="d-flex align-center">
                  <v-tooltip v-if="sprintIndex === 0 && status === 'started'" bottom>
                    <template #activator="{ on }">
                      <v-icon v-on="on" class="mr-1" color="error">local_fire_department</v-icon>
                    </template>
                    <div>
                      {{ $t('projects.labels.highest_priority') }}
                    </div>
                  </v-tooltip>
                  <div>
                    {{ sprint.sprint_name }}
                  </div>
                </div>
              </td>
              <td v-if="!$vuetify.breakpoint[headers[1].hidden]">
                <div class="d-flex align-center">
                  <v-tooltip v-if="sprintIndex === 0 && status === 'started'" bottom>
                    <template #activator="{ on }">
                      <v-icon v-on="on" class="mr-1" color="error">local_fire_department</v-icon>
                    </template>
                    <div>
                      {{ $t('projects.labels.highest_priority') }}
                    </div>
                  </v-tooltip>
                  <div>
                    {{ sprint.sprint_name }}
                  </div>
                </div>
              </td>
              <td class="text-center" style="width: 100px">
                <ProjectSprintProgressBar :sprint="sprint" :user-stories="sprint.user_stories" />
              </td>
            </tr>
          </draggable>
        </v-simple-table>
      </div>
    </v-card>
  </v-slide-y-transition>
</template>

<script>
import draggable from 'vuedraggable';
import ProjectSprintProgressBar from '@/components/ProjectSprintProgressBar';
import tableMixin from '@/mixins/table-mixin';

export default {
  name: 'OngoingProjectSprints',

  components: {
    ProjectSprintProgressBar,
    draggable,
  },

  mixins: [tableMixin],

  props: {
    items: Array,
  },

  computed: {
    canReorder() {
      return this.$isAdmin();
    },

    headers() {
      return [
        { text: this.$t('general.project') },
        { text: this.$t('general.sprint'), hidden: 'smAndDown' },
        { text: this.$t('general.date_and_time.progress'), align: 'center' },
      ];
    },

    sprintsByStatus() {
      const sprintsByStatus = {
        started: [],
        planned: [],
        postponed: [],
      };

      this.items.forEach((sprint) => {
        if (sprintsByStatus[sprint.status]) {
          sprintsByStatus[sprint.status].push(sprint);
        } else {
          // in case some other statuses get added by backend at some point
          sprintsByStatus[sprint.status] = [sprint];
        }
      });

      return sprintsByStatus;
    },

    sprintStatusIcons() {
      return {
        planned: 'pending',
        started: 'play_circle_filled',
        postponed: 'next_plan',
      };
    },
  },

  methods: {
    goToProject(sprint) {
      this.$router.push({
        name: 'project',
        params: {
          projectId: sprint.project_id,
          projectTitle: sprint.project_title,
        },
      });
    },

    onDrop({ from, to, oldIndex, newIndex }) {
      const newStatus = to.id.split('-')[0];
      const oldStatus = from.id.split('-')[0];
      if (newStatus === oldStatus && oldIndex === newIndex) {
        return;
      }
      const movedSprint = this.sprintsByStatus[oldStatus][oldIndex];
      const insertBefore =
        newIndex > oldIndex
          ? this.sprintsByStatus[newStatus][newIndex + 1]
          : this.sprintsByStatus[newStatus][newIndex];
      this.$emit('move:sprint', movedSprint, insertBefore, newStatus);
    },
  },
};
</script>
