import httpClient from '@/api/http-client';

const endpoint = 'api/project-sprints';

const projectSprintService = {
  getAll: (params) => httpClient.get(endpoint, { params }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  create: (projectSprint) => httpClient.post(endpoint, projectSprint),

  update: (projectSprint) => httpClient.put(`${endpoint}/${projectSprint.id}`, projectSprint),

  updatePriorities: (sprints) => {
    const payload = {
      sprint_ids: sprints.map((s) => s.id),
    };
    return httpClient.put(`${endpoint}/update-priority`, payload);
  },

  delete: (projectSprint) => httpClient.delete(`${endpoint}/${projectSprint.id}`),
};

export default projectSprintService;
