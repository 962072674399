import shortcutService from '@/api/shortcut-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { mapErrorsToInputs } from '@/util/forms';
import clone from 'just-clone';
import { removeArrayItem, updateArrayItem } from '@/util/array';

export const getDefaultShortcutFormItem = () => ({});

const state = {
  shortcuts: [],
  editedShortcut: {},
  newShortcut: getDefaultShortcutFormItem(),
  shortcutValidationErrors: {},
};

const getters = {};

const mutations = {
  SET_SHORTCUTS(state, payload) {
    state.shortcuts = payload;
  },

  STORE_SHORTCUT(state, item) {
    state.shortcuts.push(item);
    state.newShortcut = getDefaultShortcutFormItem();
  },

  UPDATE_SHORTCUT(state, shortcut) {
    state.shortcuts = updateArrayItem(state.shortcuts, shortcut);
  },

  SET_EDITED_SHORTCUT(state, shortcut) {
    state.editedShortcut = clone(shortcut);
    state.shortcutValidationErrors = {};
  },

  SET_NEW_SHORTCUT(state, shortcut) {
    state.newShortcut = shortcut;
    state.shortcutValidationErrors = {};
  },

  DELETE_SHORTCUT(state, shortcut) {
    state.shortcuts = removeArrayItem(state.shortcuts, shortcut);
  },

  SET_SHORTCUT_VALIDATION_ERRORS(state, errors) {
    state.shortcutValidationErrors = errors;
  },

  CLEAR_SHORTCUT_VALIDATION_ERRORS(state, field) {
    delete state.shortcutValidationErrors[field];
  },
};

const actions = {
  async fetchCurrentUserShortcuts({ commit }) {
    const { data } = await shortcutService.getMy();
    commit('SET_SHORTCUTS', data);
  },

  storeShortcut({ commit }, shortcut) {
    return shortcutService
      .create(shortcut)
      .then((res) => {
        commit('STORE_SHORTCUT', res.data);
        openSnackbar(i18n.t('profile.shortcut_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_SHORTCUT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editShortcut({ state, commit }, shortcutId) {
    const shortcut = state.shortcuts?.find((s) => s.id === shortcutId);
    if (shortcut) {
      commit('SET_EDITED_SHORTCUT', shortcut);
      return Promise.resolve(shortcut);
    }
    return shortcutService.getById(shortcutId).then((res) => {
      commit('SET_EDITED_SHORTCUT', res.data);
      return res.data;
    });
  },

  updateShortcut({ commit }, shortcut) {
    return shortcutService
      .update(shortcut)
      .then((res) => {
        commit('UPDATE_SHORTCUT', res.data);
        openSnackbar(i18n.t('profile.shortcut_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_SHORTCUT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async deleteShortcut({ commit }, shortcut) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('general.confirm_entry_delete'),
    });
    if (!confirmed) {
      return;
    }

    await shortcutService.delete(shortcut);
    openSnackbar(i18n.t('profile.shortcut_deleted'));
    commit('DELETE_SHORTCUT', shortcut);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
