var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'compact-table-wrapper': _vm.compact && _vm.$vuetify.breakpoint.lgAndUp },attrs:{"data-simplebar":_vm.compact && _vm.$vuetify.breakpoint.lgAndUp}},[_c('v-data-table',{class:{ 'content-loading': _vm.loading },attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
      'items-per-page-options': [_vm.pagination.per_page],
    },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.items,"mobile-breakpoint":0,"page":_vm.pagination.current_page,"server-items-length":_vm.pagination.total,"loading":_vm.loading,"item-class":function () { return 'clickable'; },"hide-default-footer":_vm.compact,"disable-pagination":_vm.compact,"disable-sort":""},on:{"click:row":_vm.onRowClick,"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
    var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1 + (_vm.pagination.current_page - 1) * _vm.pagination.per_page)+". ")]}},{key:"item.title",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-pre-wrap pt-1"},[_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-badge',{attrs:{"value":item.issues_count > 0,"content":item.issues_count,"color":"error","inline":""}})],1)]}},{key:"item.client",fn:function(ref){
    var item = ref.item;
return [(item.client)?[_vm._v(" "+_vm._s(item.client.name || item.client.full_name)+" ")]:_vm._e()]}},{key:"item.estimated_beginning_at",fn:function(ref){
    var value = ref.value;
return [_c('div',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(value))])]}},{key:"item.deadline",fn:function(ref){
    var value = ref.value;
return [_c('div',{staticClass:"text-pre-wrap"},[_vm._v(_vm._s(value))])]}},{key:"item.status",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("projects.project_status." + value)))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.getRowActions(item),"loading":_vm.$store.getters.loading[("delete:api/documents/" + (item.id))],"item":item}})]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.status",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t(("projects.project_status." + (item.status))))+" ")]},proxy:true},{key:"item.client",fn:function(){return [(item.client)?[_vm._v(" "+_vm._s(item.client.name || item.client.full_name)+" ")]:_vm._e()]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }