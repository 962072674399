import httpClient from '@/api/http-client';
import clone from 'just-clone';
import { endOfYear, format, startOfYear } from 'date-fns';

const endpoint = 'api/project-issues-statistic';

const projectIssueStatisticsService = {
  get: (params) => httpClient.get(`${endpoint}`, { params }),

  getCreatedByStatistics: (params) => {
    const p = clone(params);
    p.type = 'created_by';
    if (p.year) {
      p.period_start = format(startOfYear(new Date().setFullYear(p.year)), 'yyyy-MM-dd');
      p.period_end = format(endOfYear(new Date().setFullYear(p.year)), 'yyyy-MM-dd');
      delete p.year;
    }
    delete p.search; // search is local
    return httpClient.get(`${endpoint}`, { params: p });
  },

  getCreatedByCounts: (params) => {
    const paramsWithType = {
      ...params,
      type: 'created_by',
    };
    return httpClient.get(`${endpoint}`, { params: paramsWithType });
  },
};

export default projectIssueStatisticsService;
