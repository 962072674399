/* eslint-disable import/prefer-default-export */
import Vue from 'vue';

export function scrollToFirstError() {
  Vue.nextTick().then(() => {
    const el = document.querySelector('.v-input.error--text');
    if (el) {
      el.scrollIntoView();
    }
  });
}

export function mapErrorsToInputs(errors) {
  const mappedErrors = {};
  if (errors.response && errors.response.status === 422) {
    Object.keys(errors.response.data.errors).forEach((key) => {
      mappedErrors[key] = errors.response.data.errors[key];
    });
  }
  if (Object.keys(mappedErrors).length) {
    scrollToFirstError();
  }
  return mappedErrors;
}
