import { permissionsGuard } from '@/router/guards';
import store from '@/store';

export const productRoutes = [
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '@/views/products/Products'),
    beforeEnter: permissionsGuard,
    meta: {
      requiredPermissions: ['projects.view.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createProduct',
        component: () =>
          import(/* webpackChunkName: "createProduct" */ '@/views/products/CreateProduct'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'products',
          getBackRoute: () => ({
            name: 'products',
            query: store.state.products.productFilterParams,
          }),
        },
      },
      {
        path: ':productId/edit',
        name: 'editProduct',
        component: () =>
          import(/* webpackChunkName: "editProduct" */ '@/views/products/EditProduct'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'products',
          getBackRoute: () => ({
            name: 'products',
            query: store.state.products.productFilterParams,
          }),
        },
      },
    ],
  },
];
