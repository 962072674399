import { permissionsGuard } from '@/router/guards';

export const receivedInvoiceRoutes = [
  {
    path: 'documents/received-invoices',
    name: 'receivedInvoices',
    component: () =>
      import(
        /* webpackChunkName: "receivedInvoices" */ '@/views/received-invoices/ReceivedInvoices'
      ),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'documents',
      requiredPermissions: ['documents.received_invoice.view.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createReceivedInvoice',
        component: () =>
          import(
            /* webpackChunkName: "createReceivedInvoice" */ '@/views/received-invoices/CreateReceivedInvoice'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.received_invoice.view.*'],
        },
        children: [
          {
            path: 'create-contact',
            name: 'createReceivedInvoice_createContact',
            component: () =>
              import(/* webpackChunkName: "createContact" */ '@/views/contacts/CreateContact'),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['clients.existing.create'],
              menuGroup: 'documents',
              getBackRoute: () => ({
                name: 'createReceivedInvoice',
              }),
            },
          },
        ],
      },
      {
        path: ':id/edit',
        name: 'editReceivedInvoice',
        component: () =>
          import(
            /* webpackChunkName: "editReceivedInvoice" */ '@/views/received-invoices/EditReceivedInvoice'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.received_invoice.view.*'],
        },
        children: [
          {
            path: 'create-contact',
            name: 'editReceivedInvoice_createContact',
            component: () =>
              import(/* webpackChunkName: "createContact" */ '@/views/contacts/CreateContact'),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['clients.existing.create'],
              menuGroup: 'documents',
              getBackRoute: () => ({
                name: 'editReceivedInvoice',
              }),
            },
          },
        ],
      },
    ],
  },
];
