import timeTrackingService from '@/api/time-tracking-service';
import projectUserStoryService from '@/api/project-user-story-service';

export const getDefaultProjectTimeSpentStatisticsFilterParams = () => ({});

const state = {
  projectTimeSpentStatistics: [],
  projectPointStatistics: [], // needed to generate efficiency data in ProjectTimeSpentStatistics
  projectTimeSpentStatisticsFilterParams: getDefaultProjectTimeSpentStatisticsFilterParams(),
};

const getters = {};

const mutations = {
  SET_PROJECT_TIME_SPENT_STATISTICS(state, data) {
    state.projectTimeSpentStatistics = data;
  },

  SET_PROJECT_POINT_STATISTICS(state, data) {
    state.projectPointStatistics = data;
  },

  SET_PROJECT_TIME_SPENT_STATISTICS_FILTER_PARAMS(state, params) {
    state.projectTimeSpentStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchProjectTimeSpentStatistics({ rootState, commit }, params) {
    commit('SET_PROJECT_TIME_SPENT_STATISTICS_FILTER_PARAMS', params);

    let timeSpentResponse = null;
    let pointsResponse = null;

    const requests = [
      timeTrackingService
        .getReport({
          ...params,
          project_id: rootState.projects.selectedProject.id,
        })
        .then((res) => {
          timeSpentResponse = res;
        }),
      projectUserStoryService
        .getPointStatistics({
          ...params,
          type: 'completed_by_sprint',
          project_id: rootState.projects.selectedProject.id,
        })
        .then((res) => {
          pointsResponse = res;
        }),
    ];

    await Promise.all(requests);

    commit('SET_PROJECT_POINT_STATISTICS', pointsResponse.data);
    commit('SET_PROJECT_TIME_SPENT_STATISTICS', timeSpentResponse.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
