<template>
  <v-progress-linear v-if="loading" indeterminate />
  <v-progress-linear
    v-else-if="currentUserPermissionInSelectedProject === 'client'"
    :value="sprintProgress"
    color="primary"
  />
  <div v-else :class="{ 'is-overdue': isSprintOverdue }" class="sprint-progress-bar">
    <v-tooltip
      v-for="progress in pointProgressInfo"
      :key="progress.status"
      transition="none"
      bottom
    >
      <template #activator="{ on }">
        <div
          v-on="on"
          :style="`width: ${progress.cssWidth}; background-color: ${progress.color}`"
          class="sprint-progress-bar-part"
        />
      </template>
      <div>
        {{ $t(`projects.user_story_point_status.${progress.status}`) }}:
        {{ pointCounts[progress.status] }} ({{ progress.actualWidth }})
      </div>
    </v-tooltip>
  </div>
</template>

<script>
import round from '@/util/numbers';
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectSprintProgressBar',

  props: {
    sprint: {
      type: Object,
      required: true,
    },

    userStories: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('projects', ['currentUserPermissionInSelectedProject']),
    ...mapGetters('projectUserStories', ['projectUserStoryPointTypes']),

    pointCounts() {
      const pointCounts = {
        not_started: 0,
        in_progress: 0,
        ready_for_test: 0,
        closed: 0,
      };
      this.userStories.forEach((story) => {
        this.projectUserStoryPointTypes.forEach((type) => {
          pointCounts[story[type.statusFieldKey] || 'not_started'] +=
            +story[type.estimatedPointsFieldKey];
        });
      });
      return pointCounts;
    },

    totalSprintPoints() {
      return Object.values(this.pointCounts).reduce((sum, pointCount) => sum + pointCount, 0);
    },

    pointProgressInfo() {
      const colorMap = {
        in_progress: 'rgb(255, 193, 7)',
        ready_for_test: 'rgb(3, 169, 244)',
        closed: 'var(--v-success-base)',
      };
      return Object.keys(this.pointCounts)
        .filter((status) => status !== 'not_started')
        .map((status) => {
          let actualWidth = 0;
          if (this.pointCounts[status] && this.totalSprintPoints) {
            actualWidth = (this.pointCounts[status] * 100) / this.totalSprintPoints;
          }
          let cssWidth = actualWidth;
          actualWidth = round(actualWidth, 1);
          if (this.pointCounts[status] && cssWidth < 2) {
            cssWidth = 2;
          } else {
            cssWidth = Math.floor(cssWidth);
          }
          return {
            status,
            color: colorMap[status],
            actualWidth: `${actualWidth}%`,
            cssWidth: `${cssWidth}%`,
          };
        });
    },

    sprintProgress() {
      if (!this.pointCounts.closed || !this.totalSprintPoints) {
        return 0;
      }
      return (this.pointCounts.closed / this.totalSprintPoints) * 100;
    },

    isSprintOverdue() {
      if (!this.sprint.starts_at || !this.sprint.deadline) {
        return false;
      }

      const now = new Date().getTime();
      const startsAt = new Date(this.sprint.starts_at).getTime();
      const endsAt = new Date(this.sprint.deadline).getTime();
      const dateProgress = ((now - startsAt) / (endsAt - startsAt)) * 100;

      return dateProgress > this.sprintProgress && this.sprintProgress < 100;
    },
  },
};
</script>

<style scoped>
.sprint-progress-bar {
  background-color: rgba(76, 175, 80, 0.3);
  height: 4px;
  display: flex;
}

.sprint-progress-bar.is-overdue {
  background-color: rgba(255, 82, 82, 0.3);
}

.sprint-progress-bar-part {
  height: 100%;
  transition: width 0.2s ease-in-out;
}
</style>
