import Vue from 'vue';
import Router from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import AppWrapper from '../components/app-layout/AppWrapper.vue';
import Login from '../components/Login.vue';
import Impersonate from '../views/Impersonate';
import { authGuard, loginGuard, roleAndPermissionsGuard } from './guards';
import store from '../store';
import { contactRoutes } from '@/router/routes/contact-routes';
import { invoiceRoutes } from '@/router/routes/invoice-routes';
import { receivedInvoiceRoutes } from '@/router/routes/received-invoice-routes';
import { sentInvoiceRoutes } from '@/router/routes/sent-invoice-routes';
import { supplierAgreementRoutes } from '@/router/routes/supplier-agreement-routes';
import { projectRoutes } from '@/router/routes/project-routes';
import { projectsStatisticsRoutes } from '@/router/routes/projects-statistics-routes';
import { userRoutes } from '@/router/routes/user-routes';
import { profileRoutes } from '@/router/routes/profile-routes';
import { productRoutes } from '@/router/routes/product-routes';
import { orderStatisticsRoutes } from '@/router/routes/order-statistics-routes';

Vue.use(Router);

const router = new Router({
  mode: process.env.VUE_APP_HTML_HISTORY_MODE ? 'history' : '',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: loginGuard,
      meta: { isPublic: true },
    },
    {
      path: '/impersonate',
      name: 'impersonate',
      component: Impersonate,
      meta: { isPublic: true },
    },
    {
      path: '/',
      component: AppWrapper,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: Dashboard,
          beforeEnter: authGuard,
        },
        {
          path: 'time-tracking',
          name: 'timeTracking',
          meta: {
            menuGroup: 'project-management',
            requiredPermissions: ['projects.view.*'],
            allowedRoles: ['admin', 'employee'],
          },
          component: () =>
            import(
              /* webpackChunkName: "timeTracking" */ '../views/time-tracking/TimeTracking.vue'
            ),
          beforeEnter: roleAndPermissionsGuard,
        },
        ...contactRoutes,
        ...invoiceRoutes,
        ...receivedInvoiceRoutes,
        ...sentInvoiceRoutes,
        ...supplierAgreementRoutes,
        ...projectRoutes,
        ...projectsStatisticsRoutes,
        ...orderStatisticsRoutes,
        ...userRoutes,
        ...profileRoutes,
        ...productRoutes,
        {
          path: '*',
          redirect: '/',
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.isPublic) {
    next();
    return;
  }
  if (!store.getters['auth/impersonatingAs']) {
    await store.dispatch('auth/tryAutoLogin');
  }
  next();
});

export default router;
