import userService from '@/api/user-service';
import { mapErrorsToInputs } from '@/util/forms';
import i18n from '@/i18n/i18n-config';
import { openSnackbar } from '@/util/event-bus';
import { updateArrayItem } from '@/util/array';

const getDefaultFormItem = () => ({
  is_active: true,
  role: 'client',
  clients: [],
});

export const getDefaultUserFilterParams = () => ({
  status: 'active',
});

const state = {
  users: [],
  userPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  newUser: getDefaultFormItem(),
  editedUser: {},
  userValidationErrors: {},
  userFilterParams: getDefaultUserFilterParams(),
};

const getters = {
  userRoles() {
    return [
      { text: i18n.t('users.role.admin'), value: 'admin' },
      { text: i18n.t('users.role.employee'), value: 'employee' },
      { text: i18n.t('users.role.client'), value: 'client' },
    ];
  },

  userStatuses() {
    return [
      { text: i18n.t('users.status.active'), value: 'active' },
      { text: i18n.t('users.status.inactive'), value: 'inactive' },
    ];
  },
};

const mutations = {
  SET_USERS(state, { data, current_page, per_page, total }) {
    state.users = data;
    state.userPagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_FILTER_PARAMS(state, params) {
    state.userFilterParams = params;
  },

  SET_EDITED_USER(state, user) {
    state.userValidationErrors = {};
    state.editedUser = JSON.parse(JSON.stringify(user));
  },

  CLEAR_USER_VALIDATION_ERRORS(state, field) {
    delete state.userValidationErrors[field];
  },

  STORE_USER(state, user) {
    state.users.push(user);
    state.newUser = getDefaultFormItem();
    state.userValidationErrors = {};
    state.userPagination.total += 1;
  },

  UPDATE_USER(state, user) {
    state.users = updateArrayItem(state.users, user);
    if (user.id === state.editedUser.id) {
      state.editedUser = user;
    }
  },

  SET_USER_VALIDATION_ERRORS(state, userValidationErrors) {
    state.userValidationErrors = userValidationErrors;
  },
};

const actions = {
  fetchUsers({ commit }, params) {
    commit('SET_FILTER_PARAMS', params);
    return userService.getPage(params).then((res) => {
      commit('SET_USERS', res.data);
    });
  },

  editUser({ state, commit }, userId) {
    const user = state.users?.find((u) => u.id === userId);
    if (user) {
      commit('SET_EDITED_USER', user);
      return Promise.resolve(user);
    }
    return userService.getById(userId).then((res) => {
      commit('SET_EDITED_USER', res.data);
    });
  },

  storeUser({ commit }, user) {
    return userService
      .create(user)
      .then((res) => {
        commit('STORE_USER', res.data);
        openSnackbar(i18n.t('users.user_created'));
      })
      .catch((err) => {
        commit('SET_USER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  updateUser({ commit }, user) {
    return userService
      .update(user)
      .then((res) => {
        commit('UPDATE_USER', res.data);
        openSnackbar(i18n.t('users.user_updated'));
      })
      .catch((err) => {
        commit('SET_USER_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async updateUserRole({ commit }, { user, newRole }) {
    const { data } = await userService.updateRole(user, newRole);
    commit('UPDATE_USER', {
      ...user,
      role: data.role,
    });
    openSnackbar(i18n.t('users.user_updated'));
    return data;
  },

  async toggleUserPermission({ commit }, { user, permission }) {
    const { data } = await userService.togglePermission(user, permission);
    commit('UPDATE_USER', {
      ...user,
      permissions: data,
    });
    return data;
  },

  async toggleUserStatus({ commit }, user) {
    try {
      const { data } = await userService.toggleStatus(user);
      commit('UPDATE_USER', {
        ...user,
        status: data.status,
      });
      const msg = i18n.t(`users.user_was_${data.status === 'active' ? 'activated' : 'suspended'}`);
      openSnackbar(msg);
    } catch (e) {
      openSnackbar(i18n.t('user_status_toggle_failed'));
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
