/* eslint-disable no-param-reassign,default-case */
import Vuetify from '@/plugins/vuetify';
import Vue from 'vue';
import router from '../../router';
import i18n from '../../i18n/i18n-config';
import notificationService from '../../api/notification-service';
import { findById } from '@/util/array';
import { isBefore, isSaturday, isSunday, subDays } from 'date-fns';
import { getLocalDateFromUTC } from '@/util/dates';
import { openSnackbar } from '@/util/event-bus';
import { getDefaultTimeTrackingFilterParams } from '@/store/modules/time-tracking-module';

function decorateNotification(notification) {
  switch (notification.model) {
    case 'project_user_story':
      notification.icon = 'comment';
      notification.route = {
        name: 'editProjectUserStory',
        params: {
          projectId: notification.related_model_id,
          projectUserStoryId: notification.model_column_id,
        },
      };
      break;
    case 'project_issue':
      notification.icon = 'bug_report';
      notification.route = {
        name: 'project_editProjectIssue',
        params: {
          projectId: notification.related_model_id,
          projectIssueId: notification.model_column_id,
        },
      };
      break;
    default:
      notification.icon = 'info';
      break;
  }
  return notification;
}

const state = {
  notifications: [],
  notificationPagination: {},
  reminders: [],
};

const getters = {
  activeNotifications(state) {
    return state.notifications.filter((n) => !n.is_seen);
  },

  notifications(state) {
    return state.notifications;
  },

  notificationPagination(state) {
    return state.notificationPagination;
  },
};

const mutations = {
  STORE_NOTIFICATIONS_PAGE(state, payload) {
    const notifications = payload.data;
    if (payload.current_page > 1) {
      // Some notifications may have made their way into state.notifications during
      // unseen notification fetching. This code block ensures that those notifications
      // do not get added a second time.
      const newNotifications = [];
      for (let i = 0; i < notifications.length; i++) {
        if (!findById(notifications[i].id, state.notifications)) {
          newNotifications.push(decorateNotification(notifications[i]));
        }
      }
      state.notifications.push(...newNotifications);
    } else {
      state.notifications = notifications.map((n) => decorateNotification(n));
    }
    state.notificationPagination = {
      page: payload.current_page,
      rowsPerPage: payload.per_page,
      total: payload.total,
    };
  },

  STORE_NEW_NOTIFICATIONS(state, notifications) {
    const newNotifications = [];
    for (let i = 0; i < notifications.length; i++) {
      if (!findById(notifications[i].id, state.notifications)) {
        newNotifications.push(decorateNotification(notifications[i]));
      }
    }
    state.notifications = [...newNotifications, ...state.notifications];
  },

  RESET_NOTIFICATIONS(state) {
    state.notifications = [];
    state.notificationPagination = {};
    state.reminders = [];
  },

  STORE_REMINDER(state, reminder) {
    state.reminders.push(reminder);
  },

  REMOVE_REMINDER(state, key) {
    state.reminders = state.reminders.filter((r) => r.key !== key);
  },

  TOGGLE_NOTIFICATION_SEEN_STATUS(state, notification) {
    for (let i = 0; i < state.notifications.length; i++) {
      const n = state.notifications[i];
      if (n.id === notification.id) {
        Vue.set(n, 'is_seen', !n.is_seen);
        break;
      }
    }
  },

  MARK_ALL_NOTIFICATIONS_AS_SEEN(state) {
    for (let i = 0; i < state.notifications.length; i++) {
      const notification = state.notifications[i];
      Vue.set(notification, 'is_seen', true);
    }
  },
};

const actions = {
  async getLastEntries({ dispatch, rootGetters }) {
    await notificationService.getLastEntries().then((response) => {
      if (rootGetters['auth/permissions']['projects.view.*']) {
        dispatch('remindAboutTimeTracking', response.data.last_time_tracking_updated_at);
      }
    });
  },

  async getNotifications({ commit }, params = {}) {
    await notificationService.get(params).then((res) => {
      if (params.date_from) {
        commit('STORE_NEW_NOTIFICATIONS', res.data);
      } else {
        commit('STORE_NOTIFICATIONS_PAGE', res.data);
      }
    });
  },

  async updateNotificationSeenStatus({ commit }, notification) {
    commit('TOGGLE_NOTIFICATION_SEEN_STATUS', notification);
    await notificationService.updateNotificationSeenStatus(notification).catch(() => {
      commit('TOGGLE_NOTIFICATION_SEEN_STATUS', notification);
    });
  },

  async updateAllNotificationsAsSeen({ commit }) {
    await notificationService.updateAllNotificationsAsSeen().then(() => {
      commit('MARK_ALL_NOTIFICATIONS_AS_SEEN');
    });
  },

  remindAboutTimeTracking({ commit }, date) {
    if (!date || Vuetify.framework.breakpoint.mdAndDown) {
      return;
    }
    let lastWorkDay = subDays(new Date().setHours(6), 1);
    if (isSunday(lastWorkDay)) {
      lastWorkDay = subDays(lastWorkDay, 2);
    } else if (isSaturday(lastWorkDay)) {
      lastWorkDay = subDays(lastWorkDay, 1);
    }
    if (isBefore(getLocalDateFromUTC(new Date(date)), lastWorkDay)) {
      const timeTrackingRoute = {
        name: 'timeTracking',
        query: getDefaultTimeTrackingFilterParams(),
      };
      commit('STORE_REMINDER', {
        key: 'fillInTimeTracking',
        icon: 'access_time',
        title: i18n.t('notifications.fill_in_time_tracking'),
        route: timeTrackingRoute,
      });
      openSnackbar(i18n.t('notifications.what_did_you_work_on_lately'));
      router.push(timeTrackingRoute);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
