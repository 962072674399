<template>
  <v-card :flat="nested">
    <v-card-title>
      {{ $t('general.invoice') }}
    </v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6">
          <ContactAutocomplete
            v-model="item.client_id"
            :error-messages="[...(errors.client_id || []), ...(errors.buyer_name || [])]"
            :initial-item="item.client"
            :label="$t('general.contact')"
            @update:initial-item="setClient"
          />
        </v-col>

        <v-col cols="12" sm="6">
<!--          <v-text-field-->
<!--            v-model="item.buyer_signing_person"-->
<!--            :error-messages="errors.buyer_signing_person"-->
<!--            :label="$t('invoices.labels.signing_person')"-->
<!--            @input="formMixin_clearErrors('buyer_signing_person')"-->
<!--          />-->
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.buyer_code"
            :error-messages="errors.buyer_code"
            :label="$t('invoices.labels.buyer_code')"
            @input="formMixin_clearErrors('buyer_code')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.buyer_vat_code"
            :error-messages="errors.buyer_vat_code"
            :label="$t('invoices.labels.buyer_vat_code')"
            @input="formMixin_clearErrors('buyer_vat_code')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.buyer_email"
            :error-messages="errors.buyer_email"
            :label="$t('invoices.labels.buyer_email')"
            @input="formMixin_clearErrors('buyer_email')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-if="!item.is_fixed"
            v-model="item.buyer_address"
            :error-messages="errors.buyer_address"
            :label="$t('invoices.labels.buyer_address')"
            @input="formMixin_clearErrors('buyer_address')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-if="!item.is_fixed"
            v-model="item.buyer_phone"
            :error-messages="errors.buyer_phone"
            :label="$t('invoices.labels.buyer_phone')"
            @input="formMixin_clearErrors('buyer_phone')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <BaseDatepickerInput
            v-model="item.signed_date"
            :error-messages="errors['signed_date']"
            :label="$t('invoices.labels.signed_date')"
            @input="formMixin_clearErrors('signed_date')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <BaseDatepickerInput
            v-model="item.due_date"
            :error-messages="errors['due_date']"
            :label="$t('invoices.labels.due_date')"
            @input="formMixin_clearErrors('due_date')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.vat_percentage"
            :error-messages="errors.vat_percentage"
            :label="$t('invoices.labels.vat_percent')"
            @input="formMixin_clearErrors('vat_percentage')"
          />
        </v-col>

        <v-col v-if="item.id" cols="12" sm="6">
          <v-text-field
            v-model="item.number"
            :error-messages="errors.number"
            :label="$t('invoices.labels.invoice_no')"
            @input="formMixin_clearErrors('number')"
          />
        </v-col>
      </v-row>

      <v-divider v-if="item.items.length" class="my-3" />

      <v-row v-for="(item, index) of item.items" :key="index" align="center">
        <v-col cols="6">
          <h3 class="text-h6">{{ $t('invoices.general.product_no') }} {{ index + 1 }}</h3>
        </v-col>

        <v-col cols="6">
          <v-btn style="float: right" icon @click.stop="removeProduct(index)">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.title"
            :error-messages="errors[`items.${index}.title`]"
            :label="$t('general.title')"
            @input="formMixin_clearErrors(`items.${index}.title`)"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.quantity"
            :error-messages="errors[`items.${index}.quantity`]"
            :label="$t('general.amount')"
            @input="formMixin_clearErrors(`items.${index}.quantity`)"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.unit"
            :error-messages="errors[`items.${index}.unit`]"
            :label="$t('invoices.labels.unit')"
            @input="formMixin_clearErrors(`items.${index}.unit`)"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.unit_price"
            :error-messages="errors[`items.${index}.unit_price`]"
            :label="`${$t('general.price')} (${$t('general.unit')})`"
            @input="formMixin_clearErrors(`items.${index}.unit_price`)"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" text @click="addProduct">
        {{ $t('invoices.controls.add_product') }}
      </v-btn>
      <v-spacer />
      <v-btn text @click="$emit('cancel', item)">
        {{ $t(cancelButtonText) }}
      </v-btn>
      <v-btn
        :disabled="disabled"
        :loading="disabled"
        color="primary"
        text
        @click="$emit('save', item)"
      >
        {{ $t('general.controls.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BaseDatepickerInput from '../base/BaseDatepickerInput';
import contactService from '@/api/contact-service';
import ContactAutocomplete from '@/components/autocompletes/ContactAutocomplete';
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import { addDays, format } from 'date-fns';

export default {
  name: 'InvoiceForm',

  mixins: [formMixin],

  components: {
    ContactAutocomplete,
    BaseDatepickerInput,
  },

  props: {
    invoice: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    nested: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    cancelButtonText: {
      type: String,
      default: 'general.controls.cancel',
    },
  },

  data() {
    return {
      item: {},
    };
  },

  created() {
    this.item = clone(this.invoice);
  },

  methods: {
    searchContacts: contactService.search,

    addProduct() {
      this.item.items.push({
        title: '',
        quantity: '1',
        unit: this.$t('general.unit'),
        unit_price: '',
      });
    },

    removeProduct(index) {
      const itemIndex = this.item.items.length - 1;
      this.item.items.splice(index, 1);
      delete this.errors[`items.${itemIndex}.title`];
      delete this.errors[`items.${itemIndex}.quantity`];
      delete this.errors[`items.${itemIndex}.unit`];
      delete this.errors[`items.${itemIndex}.unit_price`];
    },

    setClient(client) {
      if (client) {
        this.$set(this.item, 'client', client);
        if (client.invoice_due_days) {
          const dueDate = format(addDays(new Date(), client.invoice_due_days), 'yyyy-MM-dd');
          this.$set(this.item, 'due_date', dueDate);
        }
        this.item.client_id = client.id;
        this.item.buyer_code = client.code;
        if (client.type === 'individual') {
          this.item.buyer_name = client.full_name;
        } else {
          this.item.buyer_name = client.name;
        }
        this.item.buyer_vat_code = client.vat_code;
        this.item.buyer_email = client.email;
        this.item.buyer_address = client.address;
        this.item.buyer_phone = client.phone;
        this.item.buyer_signing_person = '';
        if (client.representative_title) {
          this.item.buyer_signing_person += `${client.representative_title} `;
        }
        if (client.representative_name) {
          this.item.buyer_signing_person += client.representative_name;
        }
      } else {
        this.$set(this.item, 'client', null);
        this.item.client_id = null;
        this.item.buyer_code = null;
        this.item.buyer_name = null;
        this.item.buyer_vat_code = null;
        this.item.buyer_email = null;
        this.item.buyer_address = null;
        this.item.buyer_phone = null;
        this.item.buyer_signing_person = null;
        this.item = JSON.parse(JSON.stringify(this.item));
      }
    },
  },
};
</script>
