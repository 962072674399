<template>
  <v-app>
    <router-view />
    <div v-if="displayLoader" class="full-height d-flex align-center justify-center">
      <v-progress-circular :size="70" :width="5" color="primary" indeterminate />
    </div>

    <Snackbar />

    <ConfirmDialog />

    <GlobalFileViewer />

    <NewContentNotification />
  </v-app>
</template>

<script>
import GlobalFileViewer from './components/GlobalFileViewer';
import ConfirmDialog from '@/components/ConfirmDialog';
import Snackbar from '@/components/Snackbar';
import NewContentNotification from '@/components/NewContentNotification';
import { mapMutations, mapState } from 'vuex';
import { parseLocalStorageBoolean } from '@/util/booleans';

export default {
  name: 'App',

  components: {
    NewContentNotification,
    Snackbar,
    ConfirmDialog,
    GlobalFileViewer,
  },

  computed: {
    ...mapState('auth', ['currentUser']),

    displayLoader() {
      return !this.$route.meta.isPublic && !this.currentUser;
    },
  },

  created() {
    this.addTouchListener();
    let settings = localStorage.getItem('evo_app_settings');
    if (settings) {
      settings = JSON.parse(settings);
      if (parseLocalStorageBoolean(settings.darkTheme)) {
        this.$vuetify.theme.dark = true;
      }
      if (settings.locale) {
        this.UPDATE_LOCALE(settings.locale);
      }
      this.STORE_APPLICATION_SETTINGS(settings);
    }
  },

  methods: {
    ...mapMutations('settings', [
      'UPDATE_LOCALE',
      'STORE_APPLICATION_SETTINGS',
      'SET_TOUCH_DEVICE',
    ]),

    addTouchListener() {
      window.addEventListener('touchstart', () => {
        if (!this.$store.state.settings.isTouchDevice) {
          this.SET_TOUCH_DEVICE();
        }
      });
    },
  },
};
</script>

<style>
@import 'styles.css';
@import 'vuetify-customizations.css';
</style>
