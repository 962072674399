<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" sm="6">
          <v-select
            v-model="editedContact.type"
            :error-messages="errors.type"
            :label="formMixin_getRequiredFieldLabel($t('general.type'))"
            :items="contactTypes"
            @input="formMixin_clearErrors('type')"
          />
        </v-col>

        <v-col v-if="editedContact.type === 'legal'" cols="12" sm="6">
          <v-text-field
            v-model="editedContact.name"
            :error-messages="errors.name"
            :label="formMixin_getRequiredFieldLabel($t('general.title'))"
            @input="formMixin_clearErrors('name')"
          />
        </v-col>

        <v-col v-if="editedContact.type === 'individual'" cols="12" sm="6">
          <v-text-field
            v-model="editedContact.first_name"
            :error-messages="errors.first_name"
            :label="formMixin_getRequiredFieldLabel($t('general.personal_info.first_name'))"
            @input="formMixin_clearErrors('first_name')"
          />
        </v-col>

        <v-col v-if="editedContact.type === 'individual'" cols="12" sm="6">
          <v-text-field
            v-model="editedContact.last_name"
            :error-messages="errors.last_name"
            :label="formMixin_getRequiredFieldLabel($t('general.personal_info.last_name'))"
            @input="formMixin_clearErrors('last_name')"
          />
        </v-col>

        <v-col v-if="editedContact.type === 'legal'" cols="12" sm="6">
          <v-text-field
            v-model="editedContact.code"
            :error-messages="errors.code"
            :label="$t('general.company_info.company_code')"
            @input="formMixin_clearErrors('code')"
          />
        </v-col>

        <v-col v-if="editedContact.type === 'individual'" cols="12" sm="6">
          <v-text-field
            v-model="editedContact.nin"
            :error-messages="errors.nin"
            :label="$t('general.personal_info.nin')"
            @input="formMixin_clearErrors('nin')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="editedContact.address"
            :error-messages="errors.address"
            :label="$t('general.common_contact_info.address')"
            @input="formMixin_clearErrors('address')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="editedContact.email"
            :error-messages="errors.email"
            :label="$t('general.company_info.public_email')"
            @input="formMixin_clearErrors('email')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="editedContact.email_for_invoices"
            :error-messages="errors.email_for_invoices"
            :label="$t('general.company_info.email_for_invoices')"
            @input="formMixin_clearErrors('email_for_invoices')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="editedContact.phone"
            :error-messages="errors.phone"
            :label="$t('general.company_info.public_phone')"
            @input="formMixin_clearErrors('phone')"
          />
        </v-col>

        <v-col v-if="editedContact.type === 'legal'" cols="12" sm="6">
          <v-text-field
            v-model="editedContact.representative_name"
            :error-messages="errors.representative_name"
            :label="$t('general.company_info.representative_name')"
            @input="formMixin_clearErrors('representative_name')"
          />
        </v-col>

        <v-col v-if="editedContact.type === 'legal'" cols="12" sm="6">
          <v-text-field
            v-model="editedContact.representative_title"
            :error-messages="errors.representative_title"
            :label="$t('general.company_info.representative_title')"
            @input="formMixin_clearErrors('representative_title')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="editedContact.bank"
            :error-messages="errors.bank"
            :label="$t('general.common_contact_info.bank')"
            @input="formMixin_clearErrors('bank')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="editedContact.bank_acc_no"
            :error-messages="errors.bank_acc_no"
            :label="$t('general.common_contact_info.bank_account_no')"
            @input="formMixin_clearErrors('bank_acc_no')"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <v-text-field
            v-model="editedContact.invoice_due_days"
            :error-messages="errors.invoice_due_days"
            :label="$t('clients.invoices.invoice_due_days')"
            type="number"
            lang="lt"
            @input="formMixin_clearErrors('invoice_due_days')"
          />
        </v-col>

        <v-col v-if="$isAdmin()" cols="12" sm="6">
          <v-checkbox
            v-model="editedContact.is_supplier"
            :error-messages="errors.is_supplier"
            :label="$t('general.supplier')"
            @change="toggleSupplier"
            @input="formMixin_clearErrors('is_supplier')"
          />
        </v-col>
      </v-row>

      <v-row v-if="editedContact.is_supplier">
        <v-col cols="12" sm="6" class="subheading">
          {{ $t('clients.general.quality') }}
        </v-col>

        <v-col cols="12" sm="6">
          <v-rating
            v-model="editedContact.quality"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$vuetify.icons.ratingFull"
            hover
            clearable
          />
        </v-col>
      </v-row>

      <v-divider v-if="editedContact.is_supplier"></v-divider>

      <v-row v-if="editedContact.is_supplier">
        <v-col cols="12" sm="6" class="subheading">
          {{ $t('clients.general.timeliness') }}
        </v-col>

        <v-col cols="12" sm="6">
          <v-rating
            v-model="editedContact.timeliness"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$vuetify.icons.ratingFull"
            hover
            clearable
          ></v-rating>
        </v-col>
      </v-row>

      <v-divider v-if="editedContact.is_supplier"></v-divider>

      <v-row v-if="editedContact.is_supplier">
        <v-col cols="12" sm="6" class="subheading">
          {{ $t('clients.general.maintenance') }}
        </v-col>

        <v-col cols="12" sm="6">
          <v-rating
            v-model="editedContact.support"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$vuetify.icons.ratingFull"
            hover
            clearable
          />
        </v-col>
      </v-row>

      <v-divider v-if="editedContact.is_supplier" />

      <v-row v-if="!editedContact.id">
        <v-col cols="12">
          <v-textarea
            v-model="editedContact.comment.comment"
            :error-messages="errors.comment"
            :label="$t('general.comment')"
            auto-grow
            @input="formMixin_clearErrors('comment')"
          />
        </v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="editedContact.comment.is_positive"
            :error-messages="errors.is_positive"
            :label="$t('clients.labels.positive_comment')"
            @input="formMixin_clearErrors('is_positive')"
          />
        </v-col>
      </v-row>

      <v-row class="mt-4" align="center">
        <v-col cols="9">
          <h3 class="text-h6">
            {{ $t('clients.general.vat_payer_codes') }}
          </h3>
        </v-col>

        <v-col cols="3" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class="ma-0" style="left: 6px" icon @click.stop="addVatPayerInfo">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('clients.controls.add_vat_info') }}</span>
          </v-tooltip>
        </v-col>

        <v-row v-for="(i, index) of editedContact.client_vats" :key="index" class="mx-2" dense>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-model="i.vat_no"
              :error-messages="errors[`client_vats.${index}.vat_no`]"
              :label="formMixin_getRequiredFieldLabel($t('general.company_info.vat_code'))"
              @input="formMixin_clearErrors(`client_vats.${index}.vat_no`)"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <BaseDatepickerInput
              v-model="i.from"
              :error-messages="errors[`client_vats.${index}.from`]"
              :label="$t('general.company_info.vat_payer_since')"
              @input="formMixin_clearErrors(`client_vats.${index}.from`)"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <BaseDatepickerInput
              v-model="i.to"
              :error-messages="errors[`client_vats.${index}.to`]"
              :label="$t('general.company_info.vat_payer_until')"
              append-outer-icon="clear"
              @click:append-outer="removeVatInfo(index)"
              @input="formMixin_clearErrors(`client_vats.${index}.to`)"
            />
          </v-col>

          <v-col v-if="index < editedContact.client_vats.length - 1" cols="12">
            <v-divider />
          </v-col>
        </v-row>

        <v-col v-if="editedContact.id" cols="12">
          <BaseComments :model-id="editedContact.id" class="mt-2" model-type="clients" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click.native="$emit('cancel', editedContact)">
        {{ $t('general.controls.cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        text
        :disabled="disabled"
        :loading="disabled"
        @click="$emit('save', editedContact)"
      >
        {{ $t('general.controls.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BaseDatepickerInput from '../base/BaseDatepickerInput';
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import BaseComments from '@/components/base/BaseComments';

export default {
  name: 'ContactForm',

  components: {
    BaseComments,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    contact: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editedContact: {},
    };
  },

  created() {
    this.editedContact = clone(this.contact);
  },

  computed: {
    contactTypes() {
      return [
        {
          text: this.$t('clients.contact_types.individual'),
          value: 'individual',
        },
        {
          text: this.$t('clients.contact_types.legal'),
          value: 'legal',
        },
      ];
    },

    formTitle() {
      return this.$t(`general.modal_titles.${this.contact.id ? 'edit_contact' : 'new_contact'}`);
    },
  },

  methods: {
    toggleSupplier() {
      if (!this.editedContact.is_supplier) {
        this.editedContact.quality = 0;
        this.editedContact.timeliness = 0;
        this.editedContact.support = 0;
      }
    },

    addVatPayerInfo() {
      this.editedContact.client_vats.push({
        client_id: this.editedContact.id || null,
        vat_no: '',
        from: '',
        to: '',
      });
    },

    removeVatInfo(index) {
      this.editedContact.client_vats.splice(index, 1);
    },
  },
};
</script>
