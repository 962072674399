import { render, staticRenderFns } from "./OngoingProjectSprints.vue?vue&type=template&id=3fd6205a&"
import script from "./OngoingProjectSprints.vue?vue&type=script&lang=js&"
export * from "./OngoingProjectSprints.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VIcon,VSimpleTable,VSlideYTransition,VSubheader,VTooltip})
