<template>
  <div class="base-action-menu-wrapper">
    <div
      v-if="actions.length && deviceType === 'mdAndMouse'"
      :class="{ 'always-visible': alwaysVisible }"
      class="desktop-and-mouse-actions"
    >
      <v-tooltip v-for="action in actions" :key="action.label" open-delay="500" bottom>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              :disabled="disabled || action.disabled || action.loading"
              :loading="action.loading"
              :small="smallButtons"
              :class="action.class"
              icon
              @click.stop="onActionClick(action, $event)"
            >
              <v-icon v-if="action.icon" :small="smallButtons">{{ action.icon }}</v-icon>
              <img v-else-if="action.image" :src="action.image" height="20" />
            </v-btn>
          </div>
        </template>
        <span>
          {{ action.label }}
        </span>
      </v-tooltip>
    </div>

    <div v-else-if="actions.length" class="mobile-or-touch-actions">
      <v-menu :left="left" z-index="10" offset-y>
        <template v-slot:activator="{ on }">
          <v-icon v-if="simple" v-on="on" class="ml-2" :small="smallButtons" @click.stop
            >more_vert</v-icon
          >

          <v-btn v-else v-on="on" :small="smallButtons" icon @click.stop>
            <v-icon :small="smallButtons">more_vert</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="action in actions"
            :key="action.label"
            :disabled="disabled || action.disabled || action.loading"
            :loading="action.loading"
            @click="onActionClick(action, $event)"
          >
            <v-list-item-icon>
              <v-icon v-if="action.icon">
                {{ action.icon }}
              </v-icon>
              <img v-else-if="action.image" :src="action.image" height="20" class="mx-auto" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ action.label }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BaseActionMenu',

  props: {
    actions: {
      type: Array,
      default: () => [
        // { icon: '', label: '', loading: false, disabled: false, callback: () => { ... } },
      ],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    item: {
      type: [Object, null],
      default: null,
    },

    maxButtons: {
      type: Number,
      default: 2,
    },

    simple: {
      // removes tooltips and replaces v-btns with v-icons
      // which reduces render times in large tables
      type: Boolean,
      default: false,
    },

    smallButtons: {
      type: Boolean,
      default: false,
    },

    left: {
      type: Boolean,
      default: false,
    },

    alwaysVisible: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('settings', ['isTouchDevice']),

    deviceType() {
      if (this.isTouchDevice || this.$vuetify.breakpoint.smAndDown) {
        return 'smOrTouch';
      }

      return 'mdAndMouse';
    },
  },

  methods: {
    onActionClick(action, domEvent) {
      if (!action.callback) {
        return;
      }

      const payload = this.item ? this.item : domEvent;
      action.callback(payload);
    },
  },
};
</script>
