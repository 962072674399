import i18n from '@/i18n/i18n-config';
import { lt } from 'date-fns/locale';
import { parseLocalStorageBoolean } from '@/util/booleans';

function getSettingsStructureForLocalStorage(settings) {
  const transformedSettings = {};
  Object.keys(settings).forEach((key) => {
    if (typeof settings[key] === 'boolean') {
      transformedSettings[key] = settings[key] ? 'on' : 'off';
    } else {
      transformedSettings[key] = settings[key];
    }
  });
  return JSON.stringify(transformedSettings);
}

const state = {
  locale: 'en',
  darkTheme: false,
  newIssues: true,
  ongoingSprints: true,
  urgentUserStories: true,
  sidenavOpen: true,
  iconShortcuts: false,
  isTouchDevice: false,
};

const getters = {
  defaultDateFnsConfig(state) {
    // use this in date-fns function calls where locale matters as the last argument
    // https://date-fns.org/v2.26.0/docs/format (check docs, e.g.)
    const config = {
      weekStartsOn: 1,
    };
    if (state.locale === 'lt') {
      config.locale = lt;
    }
    return config;
  },
};

const mutations = {
  STORE_APPLICATION_SETTINGS(state, payload) {
    state.locale = payload.locale || 'en';
    state.darkTheme = parseLocalStorageBoolean(payload.darkTheme);
    state.newIssues = parseLocalStorageBoolean(payload.newIssues);
    state.ongoingSprints = parseLocalStorageBoolean(payload.ongoingSprints);
    state.urgentUserStories = parseLocalStorageBoolean(payload.urgentUserStories);
    state.sidenavOpen = parseLocalStorageBoolean(payload.sidenavOpen);
    state.iconShortcuts = parseLocalStorageBoolean(payload.iconShortcuts);
    localStorage.setItem('evo_app_settings', getSettingsStructureForLocalStorage(state));
  },

  UPDATE_APPLICATION_SETTINGS(state, payload) {
    if (payload) {
      state[payload.key] = payload.value;
    }
    localStorage.setItem('evo_app_settings', getSettingsStructureForLocalStorage(state));
  },

  UPDATE_SETTING(state, { key, value }) {
    state[key] = value;
  },

  UPDATE_LOCALE(state, locale) {
    state.locale = locale;
    i18n.locale = locale;
    localStorage.setItem('evo_app_settings', getSettingsStructureForLocalStorage(state));
  },

  SET_TOUCH_DEVICE(state) {
    state.isTouchDevice = true;
  },
};

const actions = {
  updateSetting({ state, commit }, { key, value }) {
    if (key) {
      commit('UPDATE_SETTING', { key, value });
    }
    localStorage.setItem('evo_app_settings', JSON.stringify(state));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
