<template>
  <v-snackbar
    v-model="isOpen"
    :close-on-content-click="false"
    :close-delay="false"
    timeout="-1"
    bottom
    right
  >
    {{ $t('general.new_content_is_available') }}.
    <template v-slot:action="{ attrs }">
      <v-btn color="primary" text @click="hardReload">
        {{ $t('general.refresh') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'NewContentNotification',

  data() {
    return {
      isOpen: false,
    };
  },

  mounted() {
    if (window.evolco_isNewContentAvailable) {
      this.showSnackbar();
    }

    window.addEventListener('newContentAvailable', this.showSnackbar);
  },

  beforeDestroy() {
    window.removeEventListener('newContentAvailable', this.showSnackbar);
  },

  methods: {
    showSnackbar() {
      this.isOpen = true;
    },

    hardReload() {
      window.location.reload(true);
    },
  },
};
</script>

<style scoped></style>
