/* eslint-disable no-shadow,no-param-reassign,no-mixed-operators,import/no-cycle */
import router from '../../router/index';
import userService from '../../api/user-service';
import authService from '../../api/auth-service';
import i18n from '../../i18n/i18n-config';
import { openSnackbar, showTopProgressBar } from '@/util/event-bus';

let availablePermissionsPromise = null;

const state = {
  accessToken: null,
  impersonatingAs: null,
  currentUser: null,
  wasAutoLoginAttempted: false,
  permissions: null, // current user permissions
  allPermissions: null, // permission options
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },

  accessToken(state) {
    return state.accessToken;
  },

  impersonatingAs(state) {
    return state.impersonatingAs;
  },

  permissions(state) {
    return state.permissions;
  },

  currentUserClients(state) {
    return state.currentUser.clients;
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken;
  },

  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser;
  },

  SET_AUTO_LOGIN_ATTEMPT(state, status) {
    state.wasAutoLoginAttempted = status;
  },

  CLEAR_AUTH_DATA(state) {
    state.accessToken = null;
    state.currentUser = null;
    state.permissions = null;
    state.impersonatingAs = null;
  },

  SET_IMPERSONATED_USER_ID(state, id) {
    state.impersonatingAs = id;
  },

  STOP_IMPERSONATING(state, payload) {
    state.currentUser = payload.impersonator;
    state.permissions = payload.permissions;
    state.impersonatingAs = null;
  },

  SET_USER_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },

  SET_ALL_PERMISSIONS(state, permissions) {
    state.allPermissions = permissions;
  },
};

const actions = {
  async login({ dispatch }, { username, password }) {
    showTopProgressBar();
    try {
      const res = await authService.login(username, password);
      await dispatch('handleAuthData', res.data);
      await dispatch('getCurrentUser');
    } catch (e) {
      if (e.response?.status === 400) {
        openSnackbar(i18n.t('users.invalid_credentials'));
      }
      throw e;
    }
  },

  handleAuthData({ commit }, payload) {
    const now = new Date();
    // refresh access token when there's less than 1/3rd left on its expiry time
    const refreshAt = new Date(now.getTime() + payload.expires_in * 666);
    commit('SET_ACCESS_TOKEN', payload.access_token);
    localStorage.setItem('evo_access_token', payload.access_token);
    localStorage.setItem('evo_refresh_token', payload.refresh_token);
    localStorage.setItem('evo_token_refresh_at', refreshAt.toString());
  },

  async refreshToken({ dispatch }, refreshToken) {
    try {
      const response = await authService.refreshToken(refreshToken);
      dispatch('handleAuthData', response.data);
    } catch (e) {
      dispatch('logout');
    }
  },

  async tryAutoLogin({ state, commit, dispatch }) {
    if (state.wasAutoLoginAttempted) {
      return;
    }
    commit('SET_AUTO_LOGIN_ATTEMPT', true);

    const refreshToken = localStorage.getItem('evo_refresh_token');
    let refreshAt = localStorage.getItem('evo_token_refresh_at');
    if (refreshAt) {
      refreshAt = new Date(refreshAt);
    }
    const now = new Date();
    if (refreshToken && (!refreshAt || now.getTime() >= refreshAt.getTime())) {
      await dispatch('refreshToken', refreshToken);
    } else {
      const accessToken = localStorage.getItem('evo_access_token');
      if (accessToken) {
        commit('SET_ACCESS_TOKEN', accessToken);
      } else {
        return;
      }
    }

    const impersonator = JSON.parse(localStorage.getItem('evo_impersonator_id'));
    if (impersonator) {
      const impersonatingAs = JSON.parse(localStorage.getItem('evo_current_user'));
      if (impersonatingAs?.user?.id) {
        commit('SET_IMPERSONATED_USER_ID', impersonatingAs.user?.id);
      } else {
        localStorage.removeItem('evo_impersonator_id');
      }
    }
    await dispatch('getCurrentUser');
  },

  logout({ commit }) {
    commit('CLEAR_AUTH_DATA');
    commit('notifications/RESET_NOTIFICATIONS', null, { root: true });
    localStorage.removeItem('evo_access_token');
    localStorage.removeItem('evo_refresh_token');
    localStorage.removeItem('evo_token_refresh_at');
    localStorage.removeItem('evo_current_user');
    localStorage.removeItem('evo_impersonator_id');
    router.replace({ name: 'login' });
  },

  async getCurrentUser({ commit, state, dispatch }) {
    let response;
    if (!state.accessToken) {
      return response;
    }
    try {
      availablePermissionsPromise = userService.getAvailablePermissions().then((res) => {
        commit('SET_ALL_PERMISSIONS', res.data);
        return res;
      });
      response = await userService.getCurrent();
      commit('SET_CURRENT_USER', response.data.user);
      localStorage.setItem('evo_current_user', JSON.stringify(response.data));
      await dispatch('transformUserPermissions', response.data.permissions);
      if (response.data.user?.role !== 'client') {
        dispatch('shortcuts/fetchCurrentUserShortcuts', null, { root: true });
      }
    } catch (e) {
      response = e;
      dispatch('logout');
    }
    return response;
  },

  async impersonateUser({ state }, user) {
    const { accessToken } = state;
    const refreshToken = localStorage.getItem('evo_refresh_token');
    const tokenRefreshAt = localStorage.getItem('evo_token_refresh_at');
    if (accessToken && refreshToken && tokenRefreshAt) {
      let url = 'impersonate';
      url += `?user_id=${user.id}`;
      url += `&access_token=${accessToken}`;
      url += `&refresh_token=${refreshToken}`;
      url += `&token_refresh_at=${new Date(tokenRefreshAt).getTime()}`;
      window.location.href = `${process.env.VUE_APP_PUBLIC_PATH}${url}`;
    } else {
      openSnackbar(`${i18n.t('users.lacking_auth_data')} ${i18n.t('users.try_login_again')}`);
    }
  },

  setImpersonator({ commit, dispatch }, payload) {
    const tokenRefreshAt = new Date(+payload.token_refresh_at);
    commit('SET_ACCESS_TOKEN', payload.access_token);
    commit('SET_IMPERSONATED_USER_ID', +payload.user_id);
    localStorage.setItem('evo_impersonator_id', payload.user_id);
    localStorage.setItem('evo_access_token', payload.access_token);
    localStorage.setItem('evo_refresh_token', payload.refresh_token);
    localStorage.setItem('evo_token_refresh_at', tokenRefreshAt.toString());
    dispatch('getCurrentUser').then(() => {
      router.replace('/').catch();
    });
  },

  stopImpersonating({ commit }) {
    const impersonator = JSON.parse(localStorage.getItem('evo_impersonator_id'));
    commit('STOP_IMPERSONATING', {
      impersonator,
    });
    localStorage.setItem('evo_current_user', JSON.stringify(impersonator));
    localStorage.removeItem('evo_impersonator_id');
    window.location.href = `${process.env.VUE_APP_PUBLIC_PATH}users`;
  },

  async transformUserPermissions({ commit, state }, currentUserPermissions) {
    const userPermissions = {};
    const response = await availablePermissionsPromise;
    const allPermissions = response.data;
    // if a user is admin, grant him all permissions. This is done to avoid multiple checks,
    // e.g. it's enough to check in the app $can['clients.existing.*'], instead of
    // $can['clients.existing.*'] || $isAdmin()
    if (state.currentUser.role === 'admin') {
      Object.values(allPermissions).forEach((group) => {
        group.forEach((permission) => {
          userPermissions[permission] = true;
        });
      });
    } else {
      // role === 'employee'
      Object.values(allPermissions).forEach((group) => {
        group.forEach((permission) => {
          userPermissions[permission] = false;
        });
      });
      currentUserPermissions.forEach((permission) => {
        Object.values(allPermissions).forEach((group) => {
          if (group.indexOf(permission.permission) !== -1) {
            userPermissions[permission.permission] = true;
          }
        });
      });

      // ---------------------------------------------
      // These permissions don't exist on backend. They are here in order to have
      // a unified method in showing/hiding stuff with the helper function $can(['permission'])
      userPermissions['calendar.*'] = true;
      userPermissions['app.shortcuts.*'] = true;
      userPermissions['app.birthdays.*'] = true;
      userPermissions['app.global_search.*'] = true;
      userPermissions['app.settings.home_page.*'] = true;
      userPermissions['app.settings.dark_theme.*'] = true;
      // ---------------------------------------------

      if (userPermissions['calendar.company_events.*']) {
        userPermissions['calendar.company_events.create'] = true;
      }

      if (userPermissions['clients.*']) {
        userPermissions['clients.existing.*'] = true;
      }

      if (userPermissions['clients.existing.*']) {
        userPermissions['clients.existing.create'] = true;
        userPermissions['clients.existing.feedback_questions'] = true;
        userPermissions['clients.existing.make_document_actions'] = true;
        userPermissions['clients.existing.make_invoice_actions'] = true;
        userPermissions['clients.existing.make_project_actions'] = true;
        userPermissions['clients.existing.view.*'] = true;
      }

      if (userPermissions['clients.existing.view.*']) {
        userPermissions['clients.existing.view.contact_info'] = true;
        userPermissions['clients.existing.view.finance_info'] = true;
        userPermissions['clients.existing.view.name'] = true;
        userPermissions['clients.existing.view.project_info'] = true;
      }

      if (userPermissions['documents.*']) {
        userPermissions['documents.internal.*'] = true;
        userPermissions['documents.received_invoice.*'] = true;
        userPermissions['documents.sent_invoice.*'] = true;
        userPermissions['documents.company_request.*'] = true;
        userPermissions['documents.supplier_agreement.*'] = true;
        userPermissions['documents.client_agreement.*'] = true;
        userPermissions['documents.commercial_offer.*'] = true;
        userPermissions['documents.employee.*'] = true;
        userPermissions['documents.non_disclosure_agreement.*'] = true;
      }
      if (userPermissions['documents.internal.*']) {
        userPermissions['documents.internal.view.*'] = true;
        userPermissions['documents.internal.create'] = true;
      }
      if (userPermissions['documents.received_invoice.*']) {
        userPermissions['documents.received_invoice.view.*'] = true;
        userPermissions['documents.received_invoice.create'] = true;
      }
      if (userPermissions['documents.sent_invoice.*']) {
        userPermissions['documents.sent_invoice.view.*'] = true;
        userPermissions['documents.sent_invoice.create'] = true;
      }
      if (userPermissions['documents.company_request.*']) {
        userPermissions['documents.company_request.view.*'] = true;
        userPermissions['documents.company_request.create'] = true;
      }
      if (userPermissions['documents.supplier_agreement.*']) {
        userPermissions['documents.supplier_agreement.view.*'] = true;
        userPermissions['documents.supplier_agreement.create'] = true;
      }
      if (userPermissions['documents.client_agreement.*']) {
        userPermissions['documents.client_agreement.view.*'] = true;
        userPermissions['documents.client_agreement.create'] = true;
      }
      if (userPermissions['documents.commercial_offer.*']) {
        userPermissions['documents.commercial_offer.view.*'] = true;
        userPermissions['documents.commercial_offer.create'] = true;
      }
      if (userPermissions['documents.non_disclosure_agreement.*']) {
        userPermissions['documents.non_disclosure_agreement.view.*'] = true;
        userPermissions['documents.non_disclosure_agreement.create'] = true;
      }
      if (userPermissions['documents.employee.*']) {
        userPermissions['documents.employee.create'] = true;
      }
      userPermissions['documents.employee_documents.view.*'] = true;

      if (userPermissions['expenses.*']) {
        userPermissions['expenses.assignments.*'] = true;
        userPermissions['expenses.categories.*'] = true;
        userPermissions['expenses.items.*'] = true;
      }

      if (userPermissions['invoices.*']) {
        userPermissions['invoices.view.*'] = true;
        userPermissions['invoices.create'] = true;
      }

      if (userPermissions['projects.*']) {
        userPermissions['projects.create.*'] = true;
        userPermissions['projects.view.*'] = true;
      }

      if (userPermissions['project_issues.*']) {
        userPermissions['project_issues.create.*'] = true;
        userPermissions['project_issues.view.*'] = true;
      }
    }

    if (state.currentUser.role === 'client') {
      // same idea here, for now client permissions are static. The permissions customization
      // only affect users with employee role
      userPermissions['client.projects.view.*'] = true;
      // userPermissions['invoices.view.*'] = true;
      // userPermissions['documents.client_agreement.view.*'] = true;
      // userPermissions['documents.commercial_offer.view.*'] = true;
    }

    commit('SET_USER_PERMISSIONS', userPermissions);
    localStorage.setItem('evo_permissions', JSON.stringify(userPermissions));
  },
};

const authModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default authModule;
