import { permissionsGuard } from '@/router/guards';

export const supplierAgreementRoutes = [
  {
    path: 'documents/documents',
    name: 'supplierAgreements',
    component: () =>
      import(
        /* webpackChunkName: "supplierAgreements" */ '@/views/supplier-agreements/SupplierAgreements'
      ),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'documents',
      requiredPermissions: ['documents.supplier_agreement.view.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createSupplierAgreement',
        component: () =>
          import(
            /* webpackChunkName: "createSupplierAgreement" */ '@/views/supplier-agreements/CreateSupplierAgreement'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.supplier_agreement.view.*'],
        },
        children: [
          {
            path: 'create-contact',
            name: 'createSupplierAgreement_createContact',
            component: () =>
              import(/* webpackChunkName: "createContact" */ '@/views/contacts/CreateContact'),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['clients.existing.create'],
              menuGroup: 'documents',
              getBackRoute: () => ({
                name: 'createSupplierAgreement',
              }),
            },
          },
        ],
      },
      {
        path: ':id/edit',
        name: 'editSupplierAgreement',
        component: () =>
          import(
            /* webpackChunkName: "editSupplierAgreement" */ '@/views/supplier-agreements/EditSupplierAgreement'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'documents',
          requiredPermissions: ['documents.supplier_agreement.view.*'],
        },
        children: [
          {
            path: 'create-contact',
            name: 'editSupplierAgreement_createContact',
            component: () =>
              import(/* webpackChunkName: "createContact" */ '@/views/contacts/CreateContact'),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['clients.existing.create'],
              menuGroup: 'documents',
              getBackRoute: () => ({
                name: 'editSupplierAgreement',
              }),
            },
          },
        ],
      },
    ],
  },
];
