<template>
  <v-menu bottom offset-y>
    <template v-slot:activator="{ on }">
      <div v-on="on" :style="styles" class="d-flex align-center">
        <v-btn class="ma-0 pa-0" style="min-width: 48px" text>
          <img
            :src="$frontendUrl(`img/locale/${selectedLocale}.png`)"
            alt="Selected locale"
            width="26"
          />
        </v-btn>
        <span
          v-if="label"
          :class="{
            'theme--light': !$vuetify.theme.dark,
            'theme--dark': $vuetify.theme.dark,
          }"
          class="v-label ml-2"
          >{{ label }}</span
        >
      </div>
    </template>

    <v-list>
      <v-list-item
        v-for="lang in languages"
        :key="lang.locale"
        @click="$emit('change', lang.locale)"
      >
        <v-list-item-action>
          <img
            :src="$frontendUrl(`img/locale/${lang.locale}.png`)"
            :alt="`${lang} locale`"
            width="26"
          />
        </v-list-item-action>
        <v-list-item-title>
          {{ lang.text }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LanguageSelector',

  props: {
    selectedLocale: {
      type: String,
      default: 'en',
    },
    label: {
      type: String,
      default: '',
    },
    languages: {
      type: Array,
      default: () => [
        { locale: 'en', text: 'English' },
        { locale: 'lt', text: 'Lietuvių' },
      ],
    },
    styles: {
      type: [String, Array, Object],
      default: '',
    },
  },
};
</script>
