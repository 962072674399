import httpClient from '@/api/http-client';

const endpoint = 'api/notifications';

const notificationService = {
  get: (params) => httpClient.get(endpoint, { params }),

  getLastEntries: () => httpClient.get('api/last-entries'),

  updateNotificationSeenStatus: (notification) =>
    httpClient.put(`${endpoint}/${notification.id}/toggle-seen`),

  updateAllNotificationsAsSeen: () => httpClient.put(`${endpoint}/mark-all-as-seen`),
};

export default notificationService;
