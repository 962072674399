import { roleAndPermissionsGuard } from '@/router/guards';

export const projectsStatisticsRoutes = [
  {
    path: 'project-statistics',
    name: 'projectStatisticsTabs',
    component: () =>
      import(
        /* webpackChunkName: "projectStatisticsTabs" */ '@/views/project-statistics/ProjectListStatisticsTabs'
      ),
    beforeEnter: roleAndPermissionsGuard,
    meta: {
      menuGroup: 'statistics',
      requiredPermissions: ['projects.view.*', 'projects.create.*'],
      allowedRoles: ['admin', 'employee'],
    },
    children: [
      {
        path: 'issues',
        name: 'projectIssueStatistics',
        component: () =>
          import(
            /* webpackChunkName: "projectIssueStatistics" */ '@/views/project-statistics/ProjectIssueStatistics'
          ),
        beforeEnter: roleAndPermissionsGuard,
        meta: {
          menuGroup: 'statistics',
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          allowedRoles: ['admin', 'employee'],
        },
      },
      {
        path: 'employees',
        name: 'employeeIssueStatistics',
        component: () =>
          import(
            /* webpackChunkName: "employeeIssueStatistics" */ '@/views/project-statistics/EmployeeIssueStatistics'
          ),
        beforeEnter: roleAndPermissionsGuard,
        meta: {
          menuGroup: 'statistics',
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          allowedRoles: ['admin', 'employee'],
        },
      },
      {
        path: 'created-by',
        name: 'issueCreatedByStatistics',
        component: () =>
          import(
            /* webpackChunkName: "issueCreatedByStatistics" */ '@/views/project-statistics/IssueCreatedByStatistics'
          ),
        beforeEnter: roleAndPermissionsGuard,
        meta: {
          menuGroup: 'statistics',
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          allowedRoles: ['admin', 'employee'],
        },
      },
    ],
  },
];
