<template>
  <div class="full-height d-flex align-center justify-center">
    <v-progress-circular :size="70" :width="5" color="primary" indeterminate />
  </div>
</template>

<script>
export default {
  name: 'Impersonate',

  created() {
    if (
      this.$route.query.access_token &&
      this.$route.query.user_id &&
      this.$route.query.refresh_token &&
      this.$route.query.token_refresh_at
    ) {
      this.$store.dispatch('auth/setImpersonator', this.$route.query);
    } else {
      this.$store.dispatch('auth/tryAutoLogin');
    }
  },
};
</script>
