<template>
  <v-row ref="projectSearch" :class="{ 'border-t': projects.length }" no-gutters>
    <v-col v-if="projects.length" cols="12">
      <ProjectTable
        :items="projects"
        compact
        @click:row="goToProject"
        @click:link="$emit('done')"
      />
    </v-col>
  </v-row>
</template>

<script>
import projectService from '../api/project-service';
import ProjectTable from '@/components/tables/ProjectTable';

export default {
  name: 'ProjectSearch',

  components: {
    ProjectTable,
  },

  props: {
    query: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      projects: [],
    };
  },

  watch: {
    async query(value) {
      this.getProjects(value);
    },
  },

  created() {
    this.getProjects(this.query);
  },

  methods: {
    async getProjects(query) {
      if (!query || query.length < 3) {
        this.projects = [];
        this.$emit('update');
        return;
      }

      try {
        const res = await projectService.search(query);
        this.projects = res.data;
      } catch (e) {
        this.projects = [];
      } finally {
        this.$emit('update');
      }
    },

    goToProject(project) {
      this.$emit('done');

      this.$router.push({
        name: 'project',
        params: {
          projectId: project.id,
        },
      });
    },
  },
};
</script>
