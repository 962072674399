<template>
  <v-form @submit.prevent>
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col v-if="!item.id" cols="12">
            <v-file-input
              v-model="item.file"
              :error-messages="errors['file']"
              :label="formMixin_getRequiredFieldLabel($t('documents.labels.file'))"
              :accept="sentInvoiceFileFormats"
              @change="formMixin_clearErrors('file')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="item.document_date"
              :error-messages="errors['document_date']"
              :label="$t('documents.labels.document_date')"
              @input="formMixin_clearErrors('document_date')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <InvoiceAutocomplete
              v-model="item.invoice_id"
              :initial-item="item.invoice"
              :error-messages="errors.invoice_id"
              :label="formMixin_getRequiredFieldLabel($t('general.invoice'))"
              show-create-button
              @input="formMixin_clearErrors('invoice_id')"
              @update:initial-item="item.invoice = $event"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!item.id" class="text-subtitle-2 ml-3">
          * {{ $t('general.must_be_filled') }}
        </span>

        <v-spacer />

        <v-btn text @click="$emit('cancel')">
          {{ $t('general.controls.cancel') }}
        </v-btn>

        <v-btn
          :disabled="disabled"
          :loading="disabled"
          color="primary"
          text
          @click="$emit('save', item)"
        >
          {{ $t('general.controls.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import formMixin from '@/mixins/form-mixin';
import clone from 'just-clone';
import { mapGetters } from 'vuex';
import InvoiceAutocomplete from '@/components/autocompletes/InvoiceAutocomplete';

export default {
  name: 'SentInvoiceForm',

  components: {
    InvoiceAutocomplete,
    BaseDatepickerInput,
  },

  mixins: [formMixin],

  props: {
    sentInvoice: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return { item: {} };
  },

  computed: {
    ...mapGetters('sentInvoices', ['sentInvoiceFileFormats']),

    formTitle() {
      return this.$t(`documents.modal_titles.${this.sentInvoice.id ? 'edit' : 'new'}_sent_invoice`);
    },
  },

  created() {
    const item = clone(this.sentInvoice);
    if (item.id) {
      item.invoice_id = item.model.invoice_id;
      item.invoice = item.model.invoice;
    }
    this.item = item;
  },
};
</script>
