import Vue from 'vue';
import invoiceService from '@/api/invoice-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';
import clone from 'just-clone';
import { downloadFile } from '@/util/files';
import { addDays, endOfYear, format, startOfYear } from 'date-fns';

export const getDefaultInvoiceFormItem = () => ({
  signed_date: format(new Date(), 'yyyy-MM-dd'),
  due_date: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
  vat_percentage: 21,
  items: [
    {
      title: '',
      quantity: '1',
      unit: 'vnt.',
      unit_price: '',
    },
  ],
});

export const getDefaultInvoiceFilterParams = () => ({
  signed_date_from: format(startOfYear(new Date()), 'yyyy-MM-dd'),
  signed_date_to: format(endOfYear(new Date()), 'yyyy-MM-dd'),
});

const state = {
  invoices: [],
  invoiceSalesStatistics: [],
  totalInvoicesSum: 0,
  invoicePagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedInvoice: {},
  selectedInvoice: {},
  newInvoice: getDefaultInvoiceFormItem(),
  invoiceValidationErrors: {},
  invoiceFilterParams: getDefaultInvoiceFilterParams(),

  invoiceForSending: {},
};

const getters = {};

const mutations = {
  SET_INVOICES(state, { items, total_sum }) {
    const { data, current_page, per_page, total } = items;
    state.invoices = data;
    state.totalInvoicesSum = total_sum;
    state.invoicePagination = {
      current_page,
      per_page,
      total,
    };
  },

  SET_NEW_INVOICE(state, invoice) {
    state.newInvoice = invoice;
  },

  SET_INVOICE_SALE_STATISTICS(state, data) {
    state.invoiceSalesStatistics = data;
  },

  SET_INVOICE_FILTER_PARAMS(state, params) {
    state.invoiceFilterParams = params;
  },

  SET_EDITED_INVOICE(state, invoice) {
    state.invoiceValidationErrors = {};
    state.editedInvoice = JSON.parse(JSON.stringify(invoice));
  },

  SET_SELECTED_INVOICE(state, invoice) {
    state.selectedInvoice = clone(invoice);
  },

  SET_INVOICE_FOR_SENDING(state, invoiceForSending) {
    state.invoiceForSending = invoiceForSending;
  },

  STORE_INVOICE(state, invoice) {
    state.invoices.unshift(invoice);
    state.invoicePagination.total += 1;
    state.invoiceValidationErrors = {};
    state.newInvoice = getDefaultInvoiceFormItem();
  },

  UPDATE_INVOICE(state, invoice) {
    state.invoices = updateArrayItem(state.invoices, invoice);
    if (state.selectedInvoice.id === invoice.id) {
      state.selectedInvoice = {
        ...state.selectedInvoice,
        ...invoice,
      };
    }
  },

  DELETE_INVOICE(state, invoice) {
    state.invoices = removeArrayItem(state.invoices, invoice);
    state.invoicePagination.total -= 1;
  },

  SET_INVOICE_VALIDATION_ERRORS(state, invoiceValidationErrors) {
    state.invoiceValidationErrors = invoiceValidationErrors;
  },

  CLEAR_INVOICE_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.invoiceValidationErrors, field);
  },
};

const actions = {
  async fetchInvoices({ commit }, params) {
    commit('SET_INVOICE_FILTER_PARAMS', params);

    const salesRequestParams = clone(params);
    if (params.signed_date_from) {
      salesRequestParams.date_from = params.signed_date_from;
      delete salesRequestParams.signed_date_from;
    }
    if (params.signed_date_to) {
      salesRequestParams.date_to = params.period_end;
      delete salesRequestParams.signed_date_to;
    }
    salesRequestParams.type = 'issued';

    const invoiceRequestParams = clone(params);
    if (
      invoiceRequestParams.is_payment_received === true ||
      invoiceRequestParams.is_payment_received === false
    ) {
      delete invoiceRequestParams.is_payment_received;
    }

    let invoicesData = {};
    let statisticsData = [];
    const requests = [
      invoiceService.getPage(invoiceRequestParams).then((res) => {
        invoicesData = res.data;
      }),
    ];
    if (!params.client_id) {
      requests.push(
        invoiceService.getStatistics(salesRequestParams).then((res) => {
          statisticsData = res.data;
        }),
      );
    }

    await Promise.all(requests);
    commit('SET_INVOICES', invoicesData);
    commit('SET_INVOICE_SALE_STATISTICS', statisticsData);

    return invoicesData;
  },

  async fetchInvoiceStatistics({ commit }, params) {
    const salesRequestParams = clone(params);
    if (params.signed_date_from) {
      salesRequestParams.period_start = params.signed_date_from;
      delete salesRequestParams.signed_date_from;
    }
    if (params.signed_date_to) {
      salesRequestParams.signed_date_to = params.period_end;
      delete salesRequestParams.signed_date_to;
    }
    if (salesRequestParams.client_id) {
      delete salesRequestParams.client_id;
    }
    salesRequestParams.type = 'received';

    const { data } = await invoiceService.getStatistics(salesRequestParams);
    commit('SET_INVOICE_SALE_STATISTICS', data);
  },

  storeInvoice({ state, commit, dispatch }, invoice) {
    return invoiceService
      .create(invoice)
      .then((res) => {
        commit('STORE_INVOICE', res.data.item);
        dispatch('fetchInvoiceStatistics', state.invoiceFilterParams);
        openSnackbar(i18n.t('invoices.invoice_created'));
        return res.data.item;
      })
      .catch((err) => {
        commit('SET_INVOICE_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editInvoice({ state, commit }, invoiceId) {
    if (state.selectedInvoice.id === invoiceId) {
      commit('SET_EDITED_INVOICE', state.selectedInvoice);
      return Promise.resolve(state.selectedInvoice);
    }

    const invoice = state.invoices?.find((c) => c.id === invoiceId);
    if (invoice) {
      commit('SET_EDITED_INVOICE', invoice);
      return Promise.resolve(invoice);
    }
    return invoiceService.getById(invoiceId).then((res) => {
      commit('SET_EDITED_INVOICE', res.data.item);
      return res.data;
    });
  },

  selectInvoice({ state, commit }, invoiceId) {
    const invoice = state.invoices?.find((c) => c.id === invoiceId);
    if (invoice && invoice.items) {
      commit('SET_SELECTED_INVOICE', invoice);
      return Promise.resolve(invoice);
    }
    return invoiceService.getById(invoiceId).then((res) => {
      commit('SET_SELECTED_INVOICE', res.data.item);
      return res.data;
    });
  },

  updateInvoice({ state, commit, dispatch }, invoice) {
    return invoiceService
      .update(invoice)
      .then((res) => {
        commit('UPDATE_INVOICE', {
          ...invoice,
          ...res.data,
        });
        dispatch('fetchInvoiceStatistics', state.invoiceFilterParams);
        openSnackbar(i18n.t('invoices.invoice_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_INVOICE_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  async markInvoiceAsPaid({ state, commit, dispatch }, invoice) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('invoices.confirmations.mark_invoice_as_paid'),
      confirmText: i18n.t('general.controls.yes'),
      cancelText: i18n.t('general.controls.no'),
    });
    if (!confirmed) {
      return;
    }

    const { data } = await invoiceService.markAsPaid(invoice);
    commit('UPDATE_INVOICE', {
      ...invoice,
      is_payment_received: data.is_payment_received,
      payment_received_at: data.payment_received_at,
    });
    dispatch('fetchInvoiceStatistics', state.invoiceFilterParams);
    openSnackbar(i18n.t('invoices.invoice_updated'));
  },

  async invalidateInvoice({ commit }, invoice) {
    const confirmed = await openConfirmDialog({
      title: i18n.t('invoices.confirmations.invalidate_invoice'),
      confirmText: i18n.t('general.controls.yes'),
      cancelText: i18n.t('general.controls.no'),
    });
    if (!confirmed) {
      return;
    }

    const { data } = await invoiceService.invalidate(invoice);
    commit('UPDATE_INVOICE', {
      ...invoice,
      is_invalidated: data.is_invalidated,
    });
    openSnackbar(i18n.t('invoices.invoice_updated'));
  },

  async downloadInvoice(context, { invoice, lang }) {
    const { data } = await invoiceService.download(invoice.id, { lang });
    downloadFile(data, `${invoice.series}${invoice.number}.pdf`);
  },

  deleteInvoice({ state, commit, dispatch }, invoice) {
    openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      invoiceService.delete(invoice).then(() => {
        commit('DELETE_INVOICE', invoice);
        dispatch('fetchInvoiceStatistics', state.invoiceFilterParams);
        openSnackbar(i18n.t('invoices.invoice_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
