<template>
  <div>
    <v-chip
      v-for="points in filledPointTypes"
      :color="statusColors[userStory[points.statusFieldKey]]"
      :key="points.type"
      text-color="white"
      class="clickable point-chip"
      small
      label
      @mouseenter="onPointChipMouseEnter($event, userStory[points.statusFieldKey], points.type)"
      @mouseleave="onPointChipMouseLeave"
      @click="onPointsClick($event, userStory, points)"
    >
      {{ points.prefix }}{{ userStory[points.estimatedPointsFieldKey] }}
    </v-chip>

    <v-tooltip
      :value="isTooltipOpen && hoveredPointsClosedBy"
      :position-x="tooltipPositionX"
      :position-y="tooltipPositionY"
      bottom
      left
    >
      <div>{{ hoveredPointsClosedBy }}</div>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import projectUserStoryService from '@/api/project-user-story-service';

export default {
  name: 'ProjectUserStoryPoints',

  props: {
    userStory: {
      type: Object,
      required: true,
    },
    canChangeStatuses: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      statusColors: {
        closed: 'success',
        in_progress: '#FFC107',
        ready_for_test: '#03a9f4',
        null: 'error',
      },
      hoveredPointsClosedBy: null,
      pointHoverTimerId: null,
      isTooltipOpen: false,
      tooltipPositionX: null,
      tooltipPositionY: null,
    };
  },

  computed: {
    ...mapGetters('projectUserStories', ['projectUserStoryPointTypes']),
    ...mapGetters(['loading']),

    filledPointTypes() {
      return this.projectUserStoryPointTypes.filter(
        (points) => +this.userStory[points.estimatedPointsFieldKey]
      );
    },
  },

  methods: {
    ...mapMutations('projectUserStories', ['UPDATE_PROJECT_USER_STORY']),
    ...mapActions('projectUserStories', ['updateProjectUserStoryPointsStatus']),

    onPointsClick(event, userStory, points) {
      if (!this.canChangeStatuses) {
        return;
      }

      event.stopPropagation();
      this.updateProjectUserStoryPointsStatus({
        projectUserStory: userStory,
        pointsType: points.statusFieldKey,
      });
    },

    onPointChipMouseEnter(event, status, type) {
      clearTimeout(this.pointHoverTimerId);
      if (!status) {
        return;
      }
      this.pointHoverTimerId = setTimeout(async () => {
        const { data } = await projectUserStoryService.getClosedBy(this.userStory.id, type);
        if (!this.pointHoverTimerId) {
          // user moved mouse away from chip while the request was going
          return;
        }
        this.hoveredPointsClosedBy = data.full_name || this.$t('general.no_data');

        // to avoid tooltip overflow to the right of the screen
        this.tooltipPositionX = event.x - +data.full_name?.length * 2;
        this.tooltipPositionY = event.y + 10;
        this.isTooltipOpen = true;
      }, 500);
    },

    onPointChipMouseLeave() {
      this.isTooltipOpen = false;
      clearTimeout(this.pointHoverTimerId);
      this.pointHoverTimerId = null;
    },
  },
};
</script>

<style scoped>
.point-chip {
  margin: 2px 1px;
  font-weight: 500;
}
</style>
