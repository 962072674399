import orderStatisticsService from '@/api/order-statistics-service';
import { format } from 'date-fns';

export const getDefaultOrderStatisticsByDateFilterParams = () => ({
  year: format(new Date(), 'yyyy'),
});

const state = {
  orderStatisticsByDate: [],
  orderStatisticsByDateFilterParams: getDefaultOrderStatisticsByDateFilterParams(),
};

const mutations = {
  SET_ORDER_STATISTICS_BY_DATE(state, data) {
    state.orderStatisticsByDate = data;
  },
  SET_ORDER_STATISTICS_BY_DATE_FILTER_PARAMS(state, params) {
    state.orderStatisticsByDateFilterParams = params;
  },
};

const actions = {
  async fetchOrderStatisticsByDate({ commit }, params) {
    commit('SET_ORDER_STATISTICS_BY_DATE_FILTER_PARAMS', params);
    const { data } = await orderStatisticsService.getStatisticsByDate(params);
    commit('SET_ORDER_STATISTICS_BY_DATE', data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
