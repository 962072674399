var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('draggable',{staticStyle:{"height":"100%","z-index":"30"},attrs:{"value":_vm.userStories,"disabled":!_vm.canReorder,"delay":500,"delay-on-touch-only":true,"data-sprint-id":_vm.sprintId,"animation":150,"scroll-sensitivity":100,"scroll-speed":20,"group":"userStories"},on:{"end":function($event){return _vm.moveProjectUserStory($event)}}},_vm._l((_vm.userStories),function(item,index){return _c('div',{key:item.id,staticClass:"story-row clickable flex-wrap flex-sm-nowrap",class:{
        'highlight-background': _vm.highlightedUserStoryId === item.id,
      },attrs:{"data-user-story-id":item.id,"data-sprint-id":_vm.sprintId},on:{"click":function($event){return _vm.onUserStoryClick(item)}}},[(_vm.enableProjectUserStoryCheckboxes)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.selectedProjectUserStoriesMap[item.id] ? 'primary' : ''},on:{"click":function($event){$event.stopPropagation();return _vm.TOGGLE_SELECTED_PROJECT_USER_STORY(item.id)}}},[_vm._v(_vm._s(_vm.selectedProjectUserStoriesMap[item.id] ? 'check_box' : 'check_box_outline_blank'))]):_vm._e(),_c('span',{staticClass:"text-body-2 mr-1",class:{ completed: item.status === 'done' }},[(!_vm.enableProjectUserStoryCheckboxes)?[_vm._v(_vm._s(index + 1)+". ")]:_vm._e(),_vm._v(_vm._s(item.title)+" ")],2),(_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"break"}):_vm._e(),(item.is_urgent && _vm.currentUserPermissionInSelectedProject !== 'client')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":_vm.isUserStoryOverdue(item) ? 'error' : 'warning',"small":""}},on),[_vm._v("offline_bolt")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(("projects.labels.is_" + (_vm.isUserStoryOverdue(item) ? 'overdue' : 'urgent'))))+"! "),(item.due_date)?[_vm._v(" "+_vm._s(_vm.$t('projects.labels.deadline'))+": "+_vm._s(item.due_date)+" ")]:_vm._e()],2)]):_vm._e(),(item.info)?_c('v-icon',{staticClass:"mt-1 mt-sm-0 mr-1",attrs:{"small":""}},[_vm._v(" description ")]):_vm._e(),(item.attachments && item.attachments.length)?_c('v-icon',{staticClass:"mt-1 mt-sm-0 mr-1",attrs:{"small":""}},[_vm._v(" attach_file ")]):_vm._e(),_c('CommentCount',{staticClass:"mr-1",attrs:{"count":item.comments_count}}),(_vm.currentUserPermissionInSelectedProject !== 'client')?_c('TaskCount',{staticClass:"mr-1",attrs:{"total":item.tasks_count,"completed":item.completed_tasks_count}}):_vm._e(),(!item.sprint_id && item.status === 'client_approved')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1 mt-1 mt-sm-0",attrs:{"color":"success","small":""},on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v("check_circle")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('projects.user_story_status.client_approved'))+" ")])]):_vm._e(),_c('v-spacer'),(
          _vm.currentUserPermissionInSelectedProject === 'client' ||
          (_vm.$isClient() && _vm.currentUserPermissionInSelectedProject !== 'supplier')
        )?_c('div',{staticClass:"mt-1 mt-sm-0"},[_c('v-chip',{staticClass:"font-weight-medium black--text full-opacity",attrs:{"color":_vm.userStoryStatusColors[item.status],"disabled":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t( ("projects.user_story_status." + (item.status === 'client_approved' ? 'new' : item.status)) ))+" ")])],1):_c('ProjectUserStoryPoints',{staticClass:"text-right ml-auto mt-1 mt-sm-0",attrs:{"user-story":item,"can-change-statuses":_vm.canChangePointStatuses,"sprint-id":_vm.sprintId}})],1)}),0),(!_vm.userStories.length)?_c('div',{staticClass:"text-center pa-3"},[(_vm.sprintId)?_c('span',[_vm._v(_vm._s(_vm.$t('projects.general.no_assigned_stories'))+".")]):_c('span',[_vm._v(_vm._s(_vm.$t('projects.general.no_unassigned_stories'))+".")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }