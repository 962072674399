import { permissionsGuard, roleAndPermissionsGuard } from '@/router/guards';
import store from '@/store';

export const projectRoutes = [
  {
    path: 'projects',
    redirect: { name: 'projects' },
  },
  {
    path: 'client-projects',
    name: 'client-projects',
    meta: { requiredPermissions: ['client.projects.view.*'] },
    component: () =>
      import(/* webpackChunkName: "client-projects" */ '../../views/projects/ClientProjects.vue'),
    beforeEnter: permissionsGuard,
  },
  {
    path: 'projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "projects" */ '@/views/projects/Projects'),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'project-management',
      requiredPermissions: ['projects.view.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createProject',
        component: () =>
          import(/* webpackChunkName: "createProject" */ '@/views/projects/CreateProject'),
        beforeEnter: roleAndPermissionsGuard,

        meta: {
          requiredPermissions: ['projects.create.*'],
          allowedRoles: ['admin', 'employee'],
          menuGroup: 'project-management',
          getBackRoute: () => ({
            name: 'projects',
            query: store.state.projects.projectFilterParams,
          }),
        },
      },
      {
        path: ':projectId/edit',
        name: 'editProject',
        component: () =>
          import(/* webpackChunkName: "editProject" */ '@/views/projects/EditProject'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.create.*'],
          menuGroup: 'project-management',
          getBackRoute: () => ({
            name: 'projects',
            query: store.state.projects.projectFilterParams,
          }),
        },
      },
    ],
  },
  {
    path: 'issues',
    name: 'projectIssues',
    component: () =>
      import(/* webpackChunkName: "projectIssues" */ '@/views/project-issues/ProjectIssues'),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'project-management',
      requiredPermissions: ['projects.view.*', 'projects.create.*'],
    },
    children: [
      {
        path: 'create',
        name: 'createProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "createProjectIssue" */ '@/views/project-issues/CreateProjectIssue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          menuGroup: 'project-management',
          getBackRoute: () => ({
            name: 'projectIssues',
            query: store.state.projectIssues.projectIssueFilterParams,
          }),
        },
      },
      {
        path: ':projectIssueId/edit',
        name: 'editProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "editProjectIssue" */ '@/views/project-issues/EditProjectIssue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          menuGroup: 'project-management',
          getBackRoute: () => ({
            name: 'projectIssues',
            query: store.state.projectIssues.projectIssueFilterParams,
          }),
        },
      },
      {
        path: ':projectIssueId/conclude',
        name: 'concludeProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "concludeProjectIssue" */ '@/views/project-issues/ConcludeProjectIssue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*', 'projects.create.*'],
          menuGroup: 'project-management',
          getBackRoute: () => ({
            name: 'projectIssues',
            query: store.state.projectIssues.projectIssueFilterParams,
          }),
        },
      },
    ],
  },
  {
    path: 'project/:projectId',
    name: 'project',
    component: () => import(/* webpackChunkName: "project" */ '@/views/projects/Project'),
    beforeEnter: permissionsGuard,
    meta: {
      menuGroup: 'project-management',
      requiredPermissions: ['projects.view.*', 'client.projects.view.*'],
      getBackRoute: () => ({
        name: 'projects',
        query: store.state.projects.projectFilterParams,
      }),
    },
    children: [
      {
        path: 'edit',
        name: 'project_editProject',
        component: () =>
          import(/* webpackChunkName: "editProject" */ '@/views/projects/EditProject'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.create.*'],
          menuGroup: 'project-management',
          getBackRoute: (to) => ({
            name: 'project',
            params: { projectId: to.params.projectId },
          }),
        },
      },
      {
        path: 'issues/create',
        name: 'project_createProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "createProjectIssue" */ '@/views/project-issues/CreateProjectIssue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*', 'client.projects.view.*'],
          menuGroup: 'project-management',
          getBackRoute: (projectId) => ({
            name: 'project',
            params: { projectId },
          }),
        },
      },
      {
        path: 'issues/:projectIssueId/edit',
        name: 'project_editProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "editProjectIssue" */ '@/views/project-issues/EditProjectIssue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*', 'client.projects.view.*'],
          menuGroup: 'project-management',
          getBackRoute: (projectId) => ({
            name: 'project',
            params: { projectId },
          }),
        },
      },
      {
        path: 'issues/:projectIssueId/conclude',
        name: 'project_concludeProjectIssue',
        component: () =>
          import(
            /* webpackChunkName: "concludeProjectIssue" */ '@/views/project-issues/ConcludeProjectIssue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
          getBackRoute: (projectId) => ({
            name: 'project',
            params: { projectId },
          }),
        },
      },
      {
        path: 'issues/:projectIssueId/convert-to-user-story',
        name: 'createProjectUserStoryFromIssue',
        component: () =>
          import(
            /* webpackChunkName: "createProjectUserStoryFromIssue" */ '@/views/project-user-stories/CreateProjectUserStoryFromIssue'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
          getBackRoute: (projectId) => ({
            name: 'project',
            params: { projectId },
          }),
        },
      },
      {
        path: 'configurations',
        name: 'projectConfigurations',
        component: () =>
          import(
            /* webpackChunkName: "projectConfigurations" */ '@/views/projects/ProjectConfigurations'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'statistics',
        name: 'projectStatistics',
        component: () =>
          import(
            /* webpackChunkName: "projectStatistics" */ '@/views/project-statistics/ProjectPageStatistics'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
        children: [
          {
            path: 'points',
            name: 'projectPointStatistics',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "projectPointStatistics" */ '@/views/project-statistics/ProjectPointStatistics'
              ),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.view.*'],
              menuGroup: 'project-management',
            },
          },
          {
            path: 'time-spent',
            name: 'projectTimeSpentStatistics',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "projectTimeSpentStatistics" */ '@/views/project-statistics/ProjectTimeSpentStatistics'
              ),
            beforeEnter: permissionsGuard,
            meta: {
              requiredPermissions: ['projects.view.*'],
              menuGroup: 'project-management',
            },
          },
        ],
      },
      {
        path: 'my-settings',
        name: 'projectSettings',
        component: () =>
          import(/* webpackChunkName: "projectSettings" */ '@/views/projects/ProjectSettings'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*', 'client.projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'team',
        name: 'projectTeam',
        component: () =>
          import(/* webpackChunkName: "projectTeam" */ '@/views/projects/ProjectTeam'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'move-selected-user-stories',
        name: 'moveSelectedUserStories',
        component: () =>
          import(
            /* webpackChunkName: "moveSelectedUserStories" */ '@/views/project-user-stories/MoveProjectUserStories'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['projects.view.*'],
          menuGroup: 'project-management',
        },
      },
      {
        path: 'sprints/create',
        name: 'createProjectSprint',
        component: () =>
          import(
            /* webpackChunkName: "createProjectSprint" */ '@/views/project-sprints/CreateProjectSprint'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'sprints/:projectSprintId',
        name: 'editProjectSprint',
        component: () =>
          import(
            /* webpackChunkName: "editProjectSprint" */ '@/views/project-sprints/EditProjectSprint'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'user-stories/create',
        name: 'createProjectUserStory',
        component: () =>
          import(
            /* webpackChunkName: "createProjectUserStory" */ '@/views/project-user-stories/CreateProjectUserStory'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*', 'client.projects.view.*'],
          getBackRoute: () => ({
            name: 'project',
          }),
        },
      },
      {
        path: 'user-stories/create-bulk',
        name: 'createProjectUserStoryBulk',
        component: () =>
          import(
            /* webpackChunkName: "createProjectUserStoryBulk" */ '@/views/project-user-stories/CreateProjectUserStoriesInBulk'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*', 'client.projects.view.*'],
        },
      },
      {
        path: 'user-stories/:projectUserStoryId',
        name: 'editProjectUserStory',
        component: () =>
          import(
            /* webpackChunkName: "editProjectUserStory" */ '@/views/project-user-stories/EditProjectUserStory'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*', 'client.projects.view.*'],
          getBackRoute: () => ({
            name: 'project',
          }),
        },
      },
      {
        path: 'sprints/:sprintId/summary',
        name: 'projectSprintUserStoriesSummary',
        component: () =>
          import(
            /* webpackChunkName: "projectSprintUserStoriesSummary" */ '@/views/project-sprints/ProjectSprintUserStoriesSummary'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },
      {
        path: 'unassigned-user-stories-summary',
        name: 'unassignedUserStoriesSummary',
        component: () =>
          import(
            /* webpackChunkName: "projectSprintUserStoriesSummary" */ '@/views/project-sprints/ProjectSprintUserStoriesSummary'
          ),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
        },
      },

      // steps after closing a sprint
      // --------------------------------
      {
        path: 'create-invoice',
        name: 'project_createInvoice',
        component: () =>
          import(/* webpackChunkName: "createInvoice" */ '@/views/invoices/CreateInvoice'),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
          // if invoice is created in this route, offer to send it
          // otherwise just navigate back to project page
          getBackRoute: (invoiceId) =>
            invoiceId
              ? {
                  name: 'project_sendInvoice',
                  params: { invoiceId },
                }
              : { name: 'project' },
        },
      },
      {
        path: 'invoices/:invoiceId/send',
        name: 'project_sendInvoice',
        component: () =>
          import(/* webpackChunkName: "sendInvoice" */ '@/views/invoices/SendInvoice'),
        beforeEnter: permissionsGuard,
        meta: {
          menuGroup: 'project-management',
          requiredPermissions: ['projects.view.*'],
          getBackRoute: () => ({
            name: 'project',
          }),
        },
      },
      // --------------------------------
    ],
  },
];
