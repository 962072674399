<template>
  <div
    :class="{ 'compact-table-wrapper': compact && $vuetify.breakpoint.lgAndUp }"
    :data-simplebar="compact && $vuetify.breakpoint.lgAndUp"
  >
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.per_page],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="items"
      :mobile-breakpoint="0"
      :page="pagination.current_page"
      :server-items-length="pagination.total"
      :class="{ 'content-loading': loading }"
      :loading="loading"
      :item-class="() => 'clickable'"
      :hide-default-footer="compact"
      :disable-pagination="compact"
      disable-sort
      @click:row="onRowClick"
      @update:page="tableMixin_changePage"
    >
      <template v-slot:item.no="{ index }">
        {{ index + 1 + (pagination.current_page - 1) * pagination.per_page }}.
      </template>

      <template v-slot:item.title="{ item }">
        <div class="text-pre-wrap pt-1">
          <span>
            {{ item.title }}
          </span>
          <v-badge
            :value="item.issues_count > 0"
            :content="item.issues_count"
            color="error"
            inline
          />
        </div>
      </template>

      <template v-slot:item.client="{ item }">
        <template v-if="item.client">
          {{ item.client.name || item.client.full_name }}
        </template>
      </template>

      <template v-slot:item.estimated_beginning_at="{ value }">
        <div class="text-pre-wrap">{{ value }}</div>
      </template>

      <template v-slot:item.deadline="{ value }">
        <div class="text-pre-wrap">{{ value }}</div>
      </template>

      <template v-slot:item.status="{ value }">
        {{ $t(`projects.project_status.${value}`) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu
          :actions="getRowActions(item)"
          :loading="$store.getters.loading[`delete:api/documents/${item.id}`]"
          :item="item"
        />
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.status>
            {{ $t(`projects.project_status.${item.status}`) }}
          </template>

          <template v-slot:item.client>
            <template v-if="item.client">
              {{ item.client.name || item.client.full_name }}
            </template>
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import BaseActionMenu from '@/components/base/BaseActionMenu';
import BaseExpandedTableRow from '@/components/base/BaseExpandedTableRow';
import tableMixin from '@/mixins/table-mixin';

export default {
  name: 'ProjectTable',

  components: {
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({
        per_page: 1000,
        total: 999,
        current_page: 1,
      }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headers() {
      const headers = [
        {
          text: this.$t('general.no'),
          value: 'no',
          hidden: 'smAndDown',
          hideInExpandedRow: true,
        },
        { text: this.$t('general.project'), value: 'title' },
        { text: this.$t('projects.labels.client'), value: 'client', hidden: 'smAndDown' },
        {
          text: this.$t('projects.labels.starts'),
          value: 'estimated_beginning_at',
          hidden: 'smAndDown',
        },
        { text: this.$t('projects.labels.deadline'), value: 'deadline', hidden: 'xsOnly' },
        { text: this.$t('general.status'), value: 'status', hidden: 'smAndDown' },
        {
          text: this.$t('projects.general.hours_passed'),
          value: 'total_time_spent',
          hidden: 'smAndDown',
          align: 'end',
        },
        {
          value: 'actions',
        },
      ];

      if (this.compact) {
        headers[0].hidden = 'lgAndDown';
      }

      return headers;
    },
  },

  methods: {
    onRowClick(item) {
      if (this.compact) {
        this.$emit('click:row', item);
        return;
      }

      this.tableMixin_onRowClick(item);
    },

    getRowActions() {
      if (!this.$can(['projects.create.*']) || this.compact) {
        return [];
      }

      const actions = [];

      if (this.$vuetify.breakpoint.smAndDown) {
        actions.push({
          callback: (p) => this.$emit('click:row', p),
          label: this.$t('general.controls.preview'),
          icon: 'visibility',
        });
      }

      if (!this.$isClient()) {
        actions.push({
          callback: (p) => this.$emit('edit', p),
          label: this.$t('general.controls.edit'),
          icon: 'edit',
        });
      }

      if (this.$isAdmin()) {
        actions.push({
          callback: (p) => this.$emit('delete', p),
          label: this.$t('general.controls.delete'),
          icon: 'delete',
        });
      }

      return actions;
    },
  },
};
</script>
