<template>
  <span v-if="count > 0" class="text-no-wrap">
    <v-icon class="comment-count-icon" small>chat_bubble_outline</v-icon>
    <span class="text--secondary text-body-2">{{ count }}</span>
  </span>
</template>

<script>
export default {
  name: 'CommentCount',

  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped>
.comment-count-icon {
  padding-right: 2px;
}
</style>
