import orderStatisticsService from '@/api/order-statistics-service';

export const getDefaultProjectOrderStatisticsFilterParams = () => ({});

const state = {
  projectOrderStatistics: [],
  projectOrderStatisticsFilterParams: getDefaultProjectOrderStatisticsFilterParams(),
};

const mutations = {
  SET_PROJECT_ORDER_STATISTICS(state, data) {
    state.projectOrderStatistics = data;
  },
  SET_PROJECT_ORDER_STATISTICS_FILTER_PARAMS(state, params) {
    state.projectOrderStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchProjectOrderStatistics({ commit }, params) {
    commit('SET_PROJECT_ORDER_STATISTICS_FILTER_PARAMS', params);
    const { data } = await orderStatisticsService.get({ ...params, type: 'projects' });
    commit('SET_PROJECT_ORDER_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
