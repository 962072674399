var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading && !_vm.items.length)?_c('v-progress-circular',{staticClass:"ma-4",attrs:{"color":"primary","indeterminate":""}}):_c('v-card',{class:{ 'loading-overlay': _vm.loading },attrs:{"flat":""}},[_vm._l((_vm.items),function(item){return _c('div',{key:item.id,staticClass:"issue-row d-flex justify-space-between align-center clickable text-body-2",on:{"click":function($event){return _vm.$emit('click:row', item)}}},[_c('div',[_c('div',{staticClass:"mr-1 text-break",class:{ completed: item.status === 'closed' }},[_vm._v(" "+_vm._s(item.title)+" "),(item.external_issues_count > 1)?[_vm._v(" ("+_vm._s(item.external_issues_count)+") ")]:_vm._e()],2),_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-1 grey--text"},[_vm._v(" "+_vm._s(_vm.$timeFromDate(item.created_at))+" ")]),((!_vm.$isClient() && item.priority === 'important') || item.priority === 'critical')?_c('v-icon',{staticClass:"mr-1",attrs:{"color":item.priority === 'important' ? 'warning' : 'error',"small":""}},[_vm._v("offline_bolt")]):_vm._e(),(
            item.planned_implementation_at &&
            item.planned_implementation_at < _vm.today &&
            !['closed', 'rejected', 'deployed_to_production'].includes(item.status)
          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":"error","small":""}},on),[_vm._v("error")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('company_management.general.late'))+" "+_vm._s(_vm.$t('projects.labels.planned_implementation'))+": "+_vm._s(item.planned_implementation_at)+" ")])]):_vm._e(),(item.description)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("description")]):_vm._e(),(item.attachments && item.attachments.length)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("attach_file")]):_vm._e(),_c('CommentCount',{staticClass:"mr-1",attrs:{"count":item.comments_count}})],1)]),_c('div',{staticClass:"d-flex"},[(item.is_resolved)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"color":"success"}},on),[_vm._v("check_circle")])]}}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('projects.labels.is_resolved'))+" ")])]):_vm._e(),_c('div',{staticClass:"mr-1"},[_c('BaseChipSelect',{attrs:{"value":item.status,"disabled":_vm.$isClient() || !_vm.canChangeStatus,"items":_vm.projectIssueStatuses,"max-height":"400"},on:{"change":function($event){return _vm.$emit('update:status', $event, item)}}})],1),(!_vm.$isClient())?_c('UserAvatar',{staticClass:"mr-1",attrs:{"full-name":_vm.getIssueAuthorFullName(item),"tooltip-prefix":((_vm.$t('general.created_by')) + ": ")}}):_vm._e(),(!_vm.$isClient())?_c('UserAvatar',{attrs:{"full-name":_vm.getIssueAssigneeFullName(item),"tooltip":_vm.getIssueAssigneeFullName(item)
            ? ((_vm.$t('projects.labels.assigned_employee')) + ": " + (_vm.getIssueAssigneeFullName(item)))
            : _vm.$t('projects.general.no_assigned_employee')}}):_vm._e()],1)])}),(!_vm.items.length)?_c('div',{staticClass:"text-center pa-3"},[_vm._v(" "+_vm._s(_vm.$t('general.no_data'))+" ")]):_vm._e(),(_vm.pagination)?[(
        _vm.pagination.current_page < Math.ceil(_vm.pagination.total / _vm.pagination.per_page) ||
        _vm.pagination.current_page > 1
      )?_c('v-pagination',{staticClass:"my-2",attrs:{"value":_vm.pagination.current_page,"length":Math.ceil(_vm.pagination.total / _vm.pagination.per_page),"circle":""},on:{"input":function($event){return _vm.$emit('update:page', $event)}}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }