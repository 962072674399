import projectIssueStatisticsService from '@/api/project-issue-statistics-service';

export const getDefaultProjectIssueStatisticsFilterParams = () => ({});

const state = {
  projectIssueStatistics: [],
  projectIssueStatisticsFilterParams: getDefaultProjectIssueStatisticsFilterParams(),
};

const getters = {};

const mutations = {
  SET_PROJECT_ISSUE_STATISTICS(state, data) {
    state.projectIssueStatistics = data;
  },

  SET_PROJECT_ISSUE_STATISTICS_FILTER_PARAMS(state, params) {
    state.projectIssueStatisticsFilterParams = params;
  },
};

const actions = {
  async fetchProjectIssueStatistics({ commit }, params) {
    commit('SET_PROJECT_ISSUE_STATISTICS_FILTER_PARAMS', params);
    const { data } = await projectIssueStatisticsService.get({ ...params, type: 'projects' });
    commit('SET_PROJECT_ISSUE_STATISTICS', data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
