<template>
  <v-menu v-model="isOpen" :close-on-content-click="false" min-width="290" offset-y>
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :value="value"
        :label="label"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :append-outer-icon="appendOuterIcon"
        prepend-icon="event"
        ref="textField"
        clearable
        @blur="onBlur"
        @keyup.enter="onEnterClick"
        @click:append-outer="$emit('click:append-outer')"
      />
    </template>
    <v-date-picker
      :value="value"
      :locale="locale"
      first-day-of-week="1"
      no-title
      scrollable
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import i18n from '../../i18n/i18n-config';
import { mapState } from 'vuex';
import { format, isValid } from 'date-fns';

export default {
  name: 'BaseDatepickerInput',

  props: {
    clearable: Boolean,
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: i18n.t('general.date_and_time.date'),
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
    hideDetails: Boolean,
  },

  data() {
    return {
      isOpen: false,
      timerID: null,
    };
  },

  computed: {
    ...mapState('settings', ['locale']),
  },

  methods: {
    onBlur(event) {
      if (event.target.value === this.value || this.value === null) {
        return;
      }
      const date = new Date(event.target.value);
      if (isValid(date)) {
        this.onInput(format(date, 'yyyy-MM-dd'));
      } else {
        this.onInput('');
      }
    },

    onEnterClick(event) {
      event.target.blur();
    },

    onInput(val) {
      this.$emit('input', val);
      this.isOpen = false;
    },
  },
};
</script>

<style scoped></style>
