<template>
  <v-container fluid>
    <v-row
      v-if="
        $store.getters['auth/currentUser'] && $store.getters['auth/currentUser'].role === 'client'
      "
      dense
    >
      <h1 class="text-h6 pa-3">
        {{ $t('general.welcome_to_evolco_client_portal') }}
      </h1>
    </v-row>
    <v-row v-else dense>
      <v-col cols="12" sm="6" lg="4">
        <v-row dense>
          <v-col v-if="$store.state.settings.urgentUserStories" cols="12">
            <v-slide-y-transition mode="out-in">
              <v-card v-if="urgentUserStories.length">
                <div
                  v-for="(userStories, projectName, index) in userStoriesByProject"
                  :key="projectName"
                  :class="{ 'mt-3': index > 0 }"
                >
                  <v-subheader>
                    <v-icon class="mr-2">offline_bolt</v-icon>
                    {{ projectName }} {{ $t('general.urgent_user_stories').toLowerCase() }}
                  </v-subheader>
                  <ProjectUserStoriesList
                    :user-stories="userStories"
                    @click:row="SET_HIGHLIGHTED_USER_STORY_ID($event.id)"
                  />
                </div>
              </v-card>
            </v-slide-y-transition>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        v-if="$store.state.settings.ongoingSprints || $store.state.settings.newIssues"
        cols="12"
        sm="6"
        lg="4"
      >
        <v-row dense>
          <v-col id="ongoing-sprints" v-if="$store.state.settings.ongoingSprints" cols="12">
            <OngoingProjectSprints :items="ongoingSprints" @move:sprint="moveSprint" />
          </v-col>
          <v-col id="new-issues" v-if="$store.state.settings.newIssues" cols="12">
            <v-slide-y-transition mode="out-in">
              <v-card v-if="newIssues.length > 0">
                <v-subheader>
                  <v-icon class="mr-2">bug_report</v-icon>
                  {{ $t('home_page.issues.new_issues') }}
                </v-subheader>
                <ProjectIssueList
                  :loading="$store.getters.loading['get:api/various-events']"
                  :items="newIssues"
                  @click:row="openProjectIssue"
                />
              </v-card>
            </v-slide-y-transition>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OngoingProjectSprints from '../components/OngoingProjectSprints';
import ProjectIssueList from '@/components/ProjectIssueList';
import ProjectUserStoriesList from '@/components/ProjectUserStoriesList';
import { mapMutations } from 'vuex';
import { hideTopProgressBar } from '@/util/event-bus';
import httpClient from '@/api/http-client';
import projectSprintService from '@/api/project-sprint-service';

export default {
  name: 'Dashboard',

  components: {
    ProjectUserStoriesList,
    ProjectIssueList,
    OngoingProjectSprints,
  },

  data() {
    return {
      newIssues: [],
      ongoingSprints: [],
      upcomingEvents: [],
      urgentUserStories: [],
    };
  },

  computed: {
    userStoriesByProject() {
      const userStoriesByProject = {};
      this.urgentUserStories.forEach((story) => {
        if (userStoriesByProject[story.project.title]) {
          userStoriesByProject[story.project.title].push(story);
        } else {
          userStoriesByProject[story.project.title] = [story];
        }
      });
      return userStoriesByProject;
    },
  },

  created() {
    this.getVariousEvents();
  },

  mounted() {
    this.$nextTick(() => {
      hideTopProgressBar();
    });
  },

  methods: {
    ...mapMutations('projectUserStories', ['SET_HIGHLIGHTED_USER_STORY_ID']),

    getVariousEvents() {
      httpClient.get('api/various-events').then((response) => {
        this.newIssues = response.data.issues;
        this.urgentUserStories = response.data.project_user_stories_urgent;
        this.ongoingSprints = response.data.sprints;
      });
    },

    async moveSprint(movedSprint, insertBefore, newStatus) {
      const sprintsBeforeUpdate = [...this.ongoingSprints];

      for (let i = 0; i < this.ongoingSprints.length; i++) {
        if (this.ongoingSprints[i].id === movedSprint.id) {
          this.ongoingSprints.splice(i, 1);
          i--;
        } else if (this.ongoingSprints[i].id === insertBefore?.id) {
          this.ongoingSprints = [
            ...this.ongoingSprints.slice(0, i),
            { ...movedSprint, status: newStatus },
            ...this.ongoingSprints.slice(i),
          ];
          i++;
        }
      }

      if (!insertBefore) {
        // inserted as last element
        this.ongoingSprints.push({ ...movedSprint, status: newStatus });
      }

      try {
        await projectSprintService.updatePriorities(this.ongoingSprints);
      } catch (e) {
        this.ongoingSprints = sprintsBeforeUpdate;
        return;
      }

      if (movedSprint.status === newStatus) {
        return;
      }

      try {
        await projectSprintService.update({
          ...movedSprint,
          status: newStatus,
        });
      } catch (e) {
        this.ongoingSprints = sprintsBeforeUpdate;
      }
    },

    openProjectIssue(issue) {
      this.$router.push({
        name: 'project_editProjectIssue',
        params: {
          projectId: issue.project_id,
          projectIssueId: issue.id,
        },
      });
    },
  },
};
</script>
