var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"border-b",attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"color":_vm.$vuetify.theme.isDark ? 'grey darken-4' : 'white',"app":"","flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.toggleMenu.apply(null, arguments)}}}),(_vm.logoUrl)?_c('img',{staticClass:"clickable ml-5 mr-3",attrs:{"src":_vm.logoUrl,"alt":"logo","height":"30"},on:{"click":_vm.onLogoClick}}):_vm._e(),(_vm.$can(['app.global_search.*']))?[(_vm.$vuetify.breakpoint.mdAndDown)?_c('GlobalSearchDialog',{on:{"searchToggle":_vm.handleSearchToggle}}):_c('GlobalSearchMenu',{on:{"searchToggle":_vm.handleSearchToggle}})]:_vm._e(),(_vm.$can(['app.shortcuts.*']))?[(_vm.$vuetify.breakpoint.mdAndUp && !_vm.isSearchFieldDisplayed)?[(!_vm.iconShortcuts)?_c('v-toolbar-items',_vm._l((_vm.shortcuts),function(shortcut){return _c('v-btn',{key:shortcut.id,attrs:{"to":_vm.goToShortcut(shortcut.link),"text":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(shortcut.icon)+" ")]),_vm._v(" "+_vm._s(shortcut.title)+" ")],1)}),1):_c('v-toolbar-items',_vm._l((_vm.shortcuts),function(shortcut){return _c('v-tooltip',{key:shortcut.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0 min-width-unset",attrs:{"to":_vm.goToShortcut(shortcut.link),"text":""}},on),[_c('v-icon',[_vm._v(" "+_vm._s(shortcut.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(shortcut.title)+" ")])])}),1)]:(!_vm.isSearchFieldDisplayed)?_c('v-toolbar-items',[_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("location_on")])],1)]}}],null,false,4257321193)},[_c('v-list',_vm._l((_vm.shortcuts),function(shortcut){return _c('v-list-item',{key:shortcut.id,attrs:{"to":_vm.goToShortcut(shortcut.link)}},[_c('v-list-item-action',{staticClass:"list-action-icon"},[_c('v-icon',[_vm._v(" "+_vm._s(shortcut.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(shortcut.title)+" ")])],1)}),1)],1)],1):_vm._e()]:_vm._e(),_c('v-spacer'),(_vm.$can(['projects.view.*']) && _vm.totalProjectIssuesCount > 0 && _vm.$vuetify.breakpoint.smAndUp)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.viewNewIssues}},on),[_c('v-badge',{attrs:{"value":_vm.totalProjectIssuesCount > 0,"content":_vm.issueCountLabel,"color":_vm.newProjectIssuesCount > 0 ? 'error' : 'grey darken-1',"overlap":""}},[_c('v-icon',[_vm._v("bug_report")])],1)],1)]}}],null,false,3650960906)},[_c('table',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('home_page.issues.new_issues'))+":")]),_c('td',{staticClass:"text-right pl-1"},[_vm._v(_vm._s(_vm.newProjectIssuesCount))])]),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t('projects.labels.open_issues'))+":")]),_c('td',{staticClass:"text-right pl-1"},[_vm._v(_vm._s(_vm.totalProjectIssuesCount))])])])]):_vm._e(),_c('Notifications'),_c('v-toolbar-items',[(_vm.currentUser)?_c('v-menu',{attrs:{"bottom":"","offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.currentUser.username)+" ")]):_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,false,2617332881)},[_c('v-list',{class:{ 'pt-2': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"subheader":""}},[(_vm.$vuetify.breakpoint.smAndDown)?[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.currentUser ? _vm.currentUser.username : '')+" ")]),_c('v-divider')]:_vm._e(),(_vm.$store.state.projects.totalIssues > 0 && _vm.$vuetify.breakpoint.xsOnly)?[_c('v-list-item',{on:{"click":_vm.viewNewIssues}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("bug_report")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('profile.labels.new_issues'))+" ("+_vm._s(_vm.issueCountLabel)+") ")])],1)]:_vm._e(),_c('v-list-item',{on:{"click":_vm.viewProfile}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("settings")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('general.my_account'))+" ")])],1),(_vm.impersonatingAs)?_c('v-list-item',{on:{"click":_vm.stopImpersonating}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("stop")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('general.controls.stop_impersonating'))+" ")])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("exit_to_app")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('general.controls.logout'))+" ")])],1)],2)],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }