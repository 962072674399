import Vue from 'vue';
import contactService from '@/api/contact-service';
import { openConfirmDialog, openSnackbar } from '@/util/event-bus';
import i18n from '@/i18n/i18n-config';
import { removeArrayItem, updateArrayItem } from '@/util/array';
import { mapErrorsToInputs } from '@/util/forms';

export const getDefaultClientContactFormItem = () => ({
  is_supplier: false,
  is_erp_company: false,
  client_vats: [],
  invoice_due_days: 14,
  comment: { model_type: 'clients' },
});

export const getDefaultSupplierContactFormItem = () => ({
  is_supplier: true,
  is_erp_company: false,
  client_vats: [],
  quality: 0,
  timeliness: 0,
  support: 0,
  invoice_due_days: 14,
  comment: { model_type: 'clients' },
});

export const getDefaultContactFilterParams = () => ({});

const state = {
  contacts: [],
  contactPagination: {
    current_page: 1,
    total: -1,
    per_page: 50,
  },
  editedContact: {},
  newContact: getDefaultClientContactFormItem(),
  contactValidationErrors: {},
  contactFilterParams: getDefaultContactFilterParams(),
};

const getters = {};

const mutations = {
  SET_CONTACTS(state, { data, meta }) {
    state.contacts = data;
    state.contactPagination = {
      current_page: meta.current_page,
      per_page: meta.per_page,
      total: meta.total,
    };
  },

  SET_CONTACT_FILTER_PARAMS(state, params) {
    state.contactFilterParams = params;
  },

  SET_EDITED_CONTACT(state, contact) {
    state.contactValidationErrors = {};
    state.editedContact = JSON.parse(JSON.stringify(contact));
  },

  SET_NEW_CONTACT(state, contact) {
    state.newContact = contact;
  },

  STORE_CONTACT(state, contact) {
    state.contacts.push(contact);
    state.contactPagination.total += 1;
    state.contactValidationErrors = {};
    state.newContact = getDefaultClientContactFormItem();
  },

  UPDATE_CONTACT(state, contact) {
    state.contacts = updateArrayItem(state.contacts, contact);
  },

  DELETE_CONTACT(state, contact) {
    state.contacts = removeArrayItem(state.contacts, contact);
    state.contactPagination.total -= 1;
  },

  SET_CONTACT_VALIDATION_ERRORS(state, contactValidationErrors) {
    state.contactValidationErrors = contactValidationErrors;
  },

  CLEAR_CONTACT_VALIDATION_ERRORS(state, field) {
    Vue.delete(state.contactValidationErrors, field);
  },
};

const actions = {
  fetchContacts({ commit }, params) {
    commit('SET_CONTACT_FILTER_PARAMS', params);
    return contactService.getPage(params).then((res) => {
      commit('SET_CONTACTS', res.data);
      return res.data;
    });
  },

  storeContact({ commit }, contact) {
    return contactService
      .create(contact)
      .then((res) => {
        commit('STORE_CONTACT', res.data);
        openSnackbar(i18n.t('clients.contact_created'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_CONTACT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  editContact({ state, commit }, contactId) {
    const contact = state.contacts?.find((c) => c.id === contactId);
    if (contact && contact.comments) {
      commit('SET_EDITED_CONTACT', contact);
      return Promise.resolve(contact);
    }
    return contactService.getById(contactId).then((res) => {
      commit('SET_EDITED_CONTACT', res.data);
      commit('UPDATE_CONTACT', res.data);
      return res.data;
    });
  },

  viewContact({ state, commit }, contactId) {
    const contact = state.contacts?.find((c) => c.id === contactId);
    if (contact) {
      commit('SET_EDITED_CONTACT', contact);
      return Promise.resolve(contact);
    }
    return contactService.getById(contactId).then((res) => {
      commit('SET_EDITED_CONTACT', res.data);
      return res.data;
    });
  },

  updateContact({ commit }, contact) {
    return contactService
      .update(contact)
      .then((res) => {
        commit('UPDATE_CONTACT', {
          ...contact,
          ...res.data,
        });
        openSnackbar(i18n.t('clients.contact_updated'));
        return res.data;
      })
      .catch((err) => {
        commit('SET_CONTACT_VALIDATION_ERRORS', mapErrorsToInputs(err));
        throw err;
      });
  },

  deleteContact({ commit }, contact) {
    openConfirmDialog({
      title: i18n.t('general.confirmations.remove_entry'),
    }).then((confirmed) => {
      if (!confirmed) {
        return;
      }
      contactService.delete(contact).then(() => {
        commit('DELETE_CONTACT', contact);
        openSnackbar(i18n.t('clients.contact_deleted'));
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
