import { permissionsGuard } from '@/router/guards';
import store from '@/store';

export const contactRoutes = [
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/contacts/Contacts'),
    beforeEnter: permissionsGuard,
    meta: {
      requiredPermissions: [
        'clients.existing.view.contact_info',
        'clients.existing.view.finance_info',
        'clients.existing.view.name',
        'clients.existing.view.project_info',
      ],
      menuGroup: 'contacts',
    },
    children: [
      {
        path: 'create',
        name: 'createContact',
        component: () =>
          import(/* webpackChunkName: "createContact" */ '@/views/contacts/CreateContact'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['clients.existing.create'],
          menuGroup: 'contacts',
          getBackRoute: () => ({
            name: 'contacts',
            query: store.state.contacts.contactFilterParams,
          }),
        },
      },
      {
        path: ':contactId/edit',
        name: 'editContact',
        component: () =>
          import(/* webpackChunkName: "editContact" */ '@/views/contacts/EditContact'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['clients.existing.create'],
          menuGroup: 'contacts',
          getBackRoute: () => ({
            name: 'contacts',
            query: store.state.contacts.contactFilterParams,
          }),
        },
      },
      {
        path: ':contactId/history',
        name: 'contactHistory',
        component: () =>
          import(/* webpackChunkName: "editContact" */ '@/views/contacts/ContactHistory'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['clients.existing.create'],
          menuGroup: 'contacts',
          getBackRoute: () => ({
            name: 'contacts',
            query: store.state.contacts.contactFilterParams,
          }),
        },
      },
      {
        path: 'create-invoice',
        name: 'createContactInvoice',
        component: () =>
          import(/* webpackChunkName: "createInvoice" */ '@/views/invoices/CreateInvoice'),
        beforeEnter: permissionsGuard,
        meta: {
          requiredPermissions: ['invoices.view.*'],
          menuGroup: 'contacts',
          getBackRoute: () => ({
            name: 'contacts',
            query: store.state.contacts.contactFilterParams,
          }),
        },
      },
    ],
  },
];
