/* eslint-disable no-param-reassign,import/no-cycle */
import axios from 'axios';
import authService from '@/api/auth-service';
import { scrollToFirstError } from '@/util/forms';
import store from '@/store';
import i18n from '@/i18n/i18n-config';
import { openSnackbar } from '@/util/event-bus';
import { adjustViewForPagination } from '@/util/scrolling';
import Qs from 'qs';

export function getRequestKey(config) {
  return `${config.method}:${config.url}`;
}

// TODO unify token refreshing solutions during runtime and app startup
//      (auth-module.js tryAutoLogin method)
function refreshToken() {
  if (store.state.auth.refreshTokenPromise) {
    return store.state.auth.refreshTokenPromise;
  }

  const token = localStorage.getItem('refreshToken');
  if (!token) {
    return Promise.reject(new Error('Refresh token not found'));
  }

  const tokenRefreshPromise = authService
    .refreshToken(token)
    .then((res) => {
      store.dispatch('auth/handleAuthData', res.data);
      return Promise.resolve(true);
    })
    .finally(() => {
      store.commit('auth/SET_REFRESH_TOKEN_PROMISE', null);
    });
  store.commit('auth/SET_REFRESH_TOKEN_PROMISE', tokenRefreshPromise);
  return tokenRefreshPromise;
}

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'indices' }),
});

httpClient.interceptors.request.use((config) => {
  if (store.getters['auth/accessToken']) {
    config.headers.common.Authorization = `Bearer ${store.getters['auth/accessToken']}`;
  }
  if (store.getters['auth/impersonatingAs']) {
    config.headers.common['Impersonating-As'] = `${store.getters['auth/impersonatingAs']}`;
  }
  config.headers.common.Locale = store.state.settings.locale;

  store.dispatch('setPendingRequest', getRequestKey(config));
  return config;
});

httpClient.interceptors.response.use(
  (res) => {
    adjustViewForPagination(res);
    store.dispatch('removePendingRequest', getRequestKey(res.config));
    return res;
  },
  (error) => {
    if (!error?.response?.config) {
      openSnackbar(i18n.t('errors.network_error'));
      return Promise.reject(error);
    }

    store.dispatch('removePendingRequest', getRequestKey(error.response.config));
    if (!error.response?.status || getRequestKey(error.response.config) === 'post:oauth/token') {
      return Promise.reject(error);
    }
    switch (error.response.status) {
      case 401:
        return refreshToken()
          .then(() => {
            error.config.headers.Authorization = `Bearer ${store.getters['auth/accessToken']}`;
            return axios.request(error.config);
          })
          .catch(() => {
            store.dispatch('auth/logout');
            openSnackbar({
              text: i18n.t('session_expired'),
              timeout: 10000,
            });
            return Promise.reject(error);
          });
      case 422:
        setTimeout(() => {
          scrollToFirstError();
        });
        openSnackbar(i18n.t(`errors.http.${error.response.status}`));
        return Promise.reject(error);
      case 403:
      case 429:
        openSnackbar(i18n.t(`errors.http.${error.response.status}`));
        return Promise.reject(error);
      case 500: {
        // if (error.response.data.message && error.response.data.message.indexOf('login') > -1) {
        //   store.dispatch('auth/logout').catch(() => {});
        // } else {
        openSnackbar(i18n.t('errors.http.500'));
        // }
        return Promise.reject(error);
      }
      default: {
        return Promise.reject(error);
      }
    }
  },
);

export default httpClient;
