import httpClient from '@/api/http-client';
import getFormData from '@/util/form-data';
import clone from 'just-clone';

const endpoint = 'api/documents';

const documentService = {
  getPage: (params, documentType) =>
    httpClient.get(endpoint, { params: { ...params, document_type: documentType } }),

  downloadAll: (params, documentType) =>
    httpClient({
      url: endpoint,
      method: 'GET',
      responseType: 'blob',
      params: { ...params, document_type: documentType, download: true },
    }),

  getById: (id) => httpClient.get(`${endpoint}/${id}`),

  search: (query) => httpClient.get(`${endpoint}/find/${query}`),

  create: (document) => httpClient.post(`${endpoint}`, getFormData(document)),

  toggleSeen: (document) => httpClient.post(`${endpoint}/set-seen`, { document_id: document.id }),

  update: (document) => {
    const params = {};
    const payload = clone(document);
    if (document.is_pdf) {
      params.is_pdf = true;

      payload.pdf_architecture_and_technologies = payload.pdf_architecture_and_technologies
        ?.trim()
        .split('\n');
      payload.pdf_third_party_web_services = payload.pdf_third_party_web_services
        ?.trim()
        .split('\n');
      payload.pdf_planed_tasks = payload.pdf_planed_tasks?.trim().split('\n');
    }
    return httpClient.put(`${endpoint}/${document.id}`, payload, { params });
  },

  uploadNewFile: (document, newFile) => {
    const requestBody = new FormData();
    requestBody.append('file', newFile, newFile.name);
    return httpClient.post(`api/document-sign/${document.id}`, requestBody);
  },

  delete: (document) => httpClient.delete(`${endpoint}/${document.id}`),
};

export default documentService;
