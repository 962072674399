<template>
  <BaseAutocomplete
    :value="value"
    :search-function="searchUsers"
    :get-by-id-function="getUserById"
    :label="label"
    :initial-item="initialItem"
    :error-messages="errorMessages"
    :append-icon="appendIcon"
    :disabled="disabled"
    :hint="$t('general.search_by_name')"
    item-text="personal_data.full_name"
    clearable
    use-item-slot
    use-selection-slot
    @input="$emit('input', $event)"
    @update:initial-item="$emit('update:initial-item', $event)"
    @click:append="$emit('click:append')"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content v-if="item && item.personal_data">
        <v-list-item-title>
          <span>{{ item.personal_data.full_name }}</span>
          <template v-if="item.client"> ({{ item.client.name }}) </template>
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-slot:selection="{ item }">
      <span>{{ item.personal_data.full_name }}</span>
      <template v-if="item.client"> &nbsp;({{ item.client.name }}) </template>
    </template>
  </BaseAutocomplete>
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import i18n from '@/i18n/i18n-config';
import userService from '@/api/user-service';

export default {
  name: 'UserAutocomplete',

  components: { BaseAutocomplete },

  props: {
    value: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    initialItem: {
      type: Object,
      default: () => null,
    },
    label: {
      type: String,
      default: () => i18n.t('general.user'),
    },
    appendIcon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    getUserById: userService.getById,
    searchUsers: userService.search,
  },
};
</script>
